import { useState, useEffect, React, useReducer } from 'react';
import { initialstate, myreducer, fetchbygetmethod, fetchbypostmethod } from '../../Commonfiles/Myreducer';
function Itemcategory()
{

    const [mydata,setmydata]=useReducer(myreducer, initialstate);
    
    
         async function loaddata()
      {
      
       fetchbygetmethod(setmydata, 'AdminItemcategory',{});

        console.log("mydata",mydata);
      }
     
      useEffect(()=>{loaddata();},[]);
    
    
    
    
      return(
            <div class="content-page">
                <div class="content">
                    <div class="">
                        <div class="page-header-title">
                            <h4 class="page-title">Item Category</h4>
                            
                        </div>
                    </div>
                    
                    <div class="page-content-wrapper ">
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="panel panel-primary">
                                        <div class="panel-body">
                                            <h4 class="m-t-0 m-b-30">Item Category</h4>
                                            <hr width="100%" />
                                            
                                            <div class="row">
                                                <div class="form-group">
                                                                <div>
                                                                
                                                                <label class="form-group" style={{marginLeft:29}}>
                                                                         Status
                                                                </label>
                                                                <select id='ddlitem' class="btn mdi-border-color" style={{marginLeft:5}} >
                                                                <option style={{textAlign:'justify'}}>Show All</option>
                                                                    <option style={{textAlign:'justify'}}>Unblock</option>
                                                                    <option  style={{textAlign:'justify'}}>Block</option>
                                                                    </select>
                                                                 
                                                                   <a href='AdminAddEditCategorypage ' type="submit" class="btn btn-primary waves-effect waves-light" style={{marginLeft:5}}>
                                                                        Add New Item category</a> 
    
                                                                </div>
                                                            </div> 
                                                
                                                           
                                                                
                                                              
                                                        
                                                    </div>
    
                                                 <div class="row">
                                <div class="col-md-12">
                                    <div class="panel panel-primary">
                                        <div class="panel-body">
                                            {/* <h4 class="m-b-30 m-t-0">Pannel Comission</h4> */}
                                            <table id="datatable-buttons"
                                                class="table table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Sr No.</th>
                                                        <th>Category Name</th>
                                                       
                                                        <th>Description</th>
                                                        <th>Status</th>
                                                        <th>Edit</th>
                                                        
                                                        
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {mydata.mypost.length > 0 ? (
                                                                mydata.mypost.map((row, index) => (
                                                
                                                  <tr>
                                                        <td>{index+1}</td>
                                                          <td>{row.CategoryName}</td>
                                                        <td>{row.Description}</td>
                                                        <td>{row.Status=='Y'?'UnBlock':'Block'}</td>
                                                        <td><input type="button" value="Edit" /> </td>
                                                       
                                                        
                                        
                                                    </tr>
                                                
                                            ))
												
                                        ) : null}
                                                </tbody>
                                            
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                            
                                            
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                
                                
                                
                            
                                
                                </div>
                            </div>
                        </div>
    
    
                    </div>
        )
    
    
      
    }

export default Itemcategory;