import { useState, useEffect, React, useReducer } from 'react';
import { initialstate, myreducer, fetchbygetmethod, fetchbypostmethod } from '../../Commonfiles/Myreducer';
import Modal from 'react-modal';
import axios from 'axios';


function DSpersonaldetails() {


    const [mydata, setmydata] = useReducer(myreducer, initialstate);
    const [mykyc, setmykyc] = useReducer(myreducer, initialstate); 
    const [KycApproved, setKycApproved] = useReducer(myreducer, initialstate);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [rejectReason, setRejectReason] = useState("");
    const [currentImageUrl, setCurrentImageUrl] = useState("");
    const [isChecked, setIsChecked] = useState(false);


    const fetchIpAddress = async () => {
        try {
            const response = await axios.get('https://api.ipify.org?format=json');
            return response.data.ip; // Return the fetched IP address
        } catch (error) {
            console.error('Error fetching IP address:', error);
            return ''; // Return an empty string if there's an error
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        console.log(file);
    };

    const handleViewClick = (imageUrl) => {
        setCurrentImageUrl(imageUrl);
        setIsModalOpen(true);
    };
    const handleRejectReasonChange = (event) => {
        setRejectReason(event.target.value);
    };

    const getAadharImageUrl = (type) => {
        const searchValue = document.getElementById('search').value;
        switch(type) {
            case 'adhaarfront':
                return `http://jmvdnet.jmvdbusiness.com/aadhar/${searchValue}_adhaarfront.jpg`;
            case 'adhaarback':
                return `http://jmvdnet.jmvdbusiness.com/aadhar/${searchValue}_adhaarback.jpg`;
            case 'cheque':
                return `http://jmvdnet.jmvdbusiness.com/aadhar/${searchValue}_cheque.jpg`;
            case 'pan':
                return `http://jmvdnet.jmvdbusiness.com/aadhar/${searchValue}_pan.jpg`;
                case 'Selfie':
                    return `https://jmvdnet.jmvdbusiness.com/aadhar/${searchValue}_Selfie.jpg`;

                    case 'signature':
                        return `https://jmvdnet.jmvdbusiness.com/aadhar/${searchValue}_signature.jpg`;
            // Add cases for other images if needed

            default:
                return "";
        }
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
     

    async function loaddata() {
        var search = document.getElementById('search').value;
        console.log(mydata); 
        // Ensure fetchbygetmethod is awaited if it's asynchronous
        await fetchbygetmethod(setmydata, 'AdminDSpersonaldetails', { tempid: search });
       // This will log after the data is fetched
    }
    
    async function loadkycdata() {
        var tempid = document.getElementById('search');
 
        console.log(mykyc);
        // Ensure fetchbygetmethod is awaited if it's asynchronous
        await fetchbygetmethod(setmykyc, 'AdminKYC', { tempid: tempid.value});
         // Log after data is fetched

    }

    async function InsertKycApproved() {
        const jason ={tempid: document.getElementById('search').value,
         Approvedby :document.getElementById('Authority').value,
         ipAddress :await fetchIpAddress(),
         checkbox : document.getElementById('approved').value}
             
        console.log(jason);
        
        // if (checkbox.checked) {
        //     console.log(KycApproved);

        //     await fetchbypostmethod(setKycApproved, 'Adminkycapproved', {
        //         TEMPID: tempid.value,
        //         APPROVEDBY: Approvedby.value,
        //         USERID: localStorage.getItem("tempID"),
        //         IPADDRESS: ipAddress,
        //     });

        //     console.log(KycApproved);
        // } else {
        //     console.log('Checkbox is not checked. No data inserted.');
        // }
    }
         

    
   
    
    function display() {
        loaddata();
        
        var data = document.getElementById("kycdata");
       // data.style.display = 'block';
        
        var kycis = document.getElementById("kycdatacomplete");
        kycis.style.display = 'block';
        
        // Check if mykyc.mypost is a valid array and has at least one element
        if (mykyc.mypost && mykyc.mypost.length > 0) {
            var lastPost = mykyc.mypost[mykyc.mypost.length - 1];
            
            if (lastPost.ISAPPROVED === 'Y') {
                data.style.display = 'none';
            } 
           
            else {
                data.style.display = 'block';
            }
        } else {
            // Handle the case where mykyc.mypost is empty or undefined
            console.error("No posts available in mykyc.mypost");
            data.style.display = 'block'; // Show or hide as needed
        }
    }

    useEffect(() => {
        // Set the initial checked state based on mykyc data when the component mounts or updates
        if (mykyc.mypost && mykyc.mypost.length > 0) {
            setIsChecked(mykyc.mypost[mykyc.mypost.length - 1].ISAPPROVED === 'Y');
        }
        
    }, [mykyc]); // Re-run this effect when mykyc changes

    // Handle checkbox change
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    //useEffect(()=>{console.log("jasondata",jason)},[jason]);


    

    return (<div className="content-page">
        <div className="content">
            <div className="">
                <div className="page-header-title">
                    <h4 className="page-title">Update Profile  </h4>

                </div>
            </div>

            <div className="page-content-wrapper ">
                <div className="container">
                   
                 <><div className="row">
                                    <div className="col-sm-12">
                                        <div className="panel panel-primary">
                                            <div className="panel-body">
                                                <h4 className="m-t-0 m-b-30">Search Direct Seller</h4>
                                                <hr width="100%" />
                                                <div className="row">


                                                    <div className="col-sm-6 col-xs-12">

                                                        <div className="m-t-20">
                                                            <form className="" action="#">

                                                                <div className="form-group">
                                                                    <label>Direct Seller ID</label>
                                                                    <input type="text" placeholder="Enter Ds Id" class="form-control" id="search"  />

                                                                    <label 
    style={{ color: 'red', marginTop: 8, fontSize: 18, display: mykyc.mypost.length > 0 ? 'block' : 'none' }} 
    id="kycdatacomplete"
>
    {mykyc.mypost.length > 0 ? (
        mykyc.mypost[mykyc.mypost.length - 1].ISAPPROVED === 'Y' 
            ? 'Your KYC is completed' 
            : 'Your KYC is not completed'
    ) : 'Your KYC is not completed'}
</label>



                                                                </div>



                                                            </form>
                                                        </div>
                                                    </div>
                                                               

                                                               

                                                </div>

                                                <div className="form-group">
                                        <div>

                                        <input type="submit"
                                                            className="btn btn-primary waves-effect waves-light" value='Submit' style={{marginLeft:5}} onClick={() => { display(); loadkycdata(); }}/>
                                                             
                                        </div>
                                    </div>



                                            </div>

                                        </div>
                                    </div>
                                </div>
                               
                                     {mydata.mypost.length > 0 &&
                                    mydata.mypost.map((row, index) => {
                            return (
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="panel panel-primary">
                                                <div className="panel-body">

                                                    <h4 className="m-t-0 m-b-30 form-control btn btn-primary waves-effect waves-light font-monospace" style={{ backgroundColor:'blue', fontWeight: 700, fontSize: 'large' }}>Joining Details</h4>
                                                    <hr width="100%" />
                                                     
                        
                                                    <div className="row">

                                                        <div className="col-sm-6 col-xs-12">

                                                            <div className="m-t-20">
                                                                <form className="" action="#">
                                                                    <div className="form-group">
                                                                        <label>Awareness No</label>
                                                                        <label className="form-control">{row.AwarenessID
                                                                        }</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Sponsor No</label>
                                                                        <label className="form-control">{row.SponsorID}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Position</label>
                                                                        <label className="form-control">{row.Position}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Status</label>
                                                                        <label className="form-control">{row.status}</label>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-xs-12">

                                                            <div className="m-t-20">
                                                                <form className="" action="#">
                                                                    <div className="form-group">
                                                                        <label>Awareness Name</label>
                                                                        <label className="form-control">{row.AwarenessName}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Sponsor Name</label>
                                                                        <label className="form-control">{row.SponsorName}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Joining Date</label>
                                                                        <label className="form-control">{row.date}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>ID Card</label>
                                                                        <label className="form-control">Put ID Card here</label>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                 


                                                    <h4 className="m-t-0 m-b-30 form-control btn btn-primary waves-effect waves-light font-monospace" style={{ fontWeight: 700, fontSize: 'large' }}>Personal Details</h4>
                                                    <hr width="100%" />
                                                   
                                                    <div className="row">
                                                        <div className="col-sm-6 col-xs-12">

                                                            <div className="m-t-20">
                                                                <form className="" action="#">
                                                                    <div className="form-group">
                                                                        <label>Applicant Name</label>
                                                                        <label className="form-control">{row.ApplicantName}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Applicant DOB</label>
                                                                        <label className="form-control">{row.ApplicantDob}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Nominee DOB</label>
                                                                        <label className="form-control">{row.NomineeDob}</label>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-xs-12">

                                                            <div className="m-t-20">
                                                                <form className="" action="#">
                                                                    <div className="form-group">
                                                                        <label>Guardian Name</label>
                                                                        <label className="form-control">{row.ApplicantGuardionName}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Nominee Name</label>
                                                                        <label className="form-control">{row.NomineeName}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Gender</label>
                                                                        <label className="form-control">{row.applicantgender}</label>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                 
                                                    <h4 className="m-t-0 m-b-30 form-control btn btn-primary waves-effect waves-light font-monospace" style={{ fontWeight: 700, fontSize: 'large' }}>Communication Details</h4>
                                                    <hr width="100%" />
                                                  
                                                    <div className="row">
                                                        <div className="col-sm-6 col-xs-12">

                                                            <div className="m-t-20">
                                                                <form className="" action="#">
                                                                    <div className="form-group">
                                                                        <label>Country</label>
                                                                        <label className="form-control">{row.CountryName}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>City</label>
                                                                        <label className="form-control">{row.Cityname}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>House No</label>
                                                                        <label className="form-control">{row.HouseNo}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Mobile No</label>
                                                                        <label className="form-control">{row.MobileNo}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Address Proof Type</label>
                                                                        <label className="form-control">{row.AddPType}</label>
                                                                    </div>
                                                                    <div className="form-group">
                <label>Address Proof</label>
                <input type="file" className="form-control" />
                <input
                    type="button"
                    className="btn btn-primary waves-effect waves-light form-control"
                    style={{ width: 80, marginTop: 8 }}
                    onClick={() => handleViewClick(getAadharImageUrl('adhaarfront'))}
                    value='View'
                />
                    
               
            </div>

       

           {/* Modal for Image Preview */}
           <Modal
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                 contentLabel="Image Preview"
                style={{
                    content: {
                        maxWidth: '600px',
                        maxHeight: '56vh',
                        margin: 'auto',
                        padding: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        zIndex: 1000, // Set a high z-index for the modal content
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        zIndex: 999, // Set a high z-index for the overlay as well
                    },
                }}
            >
                <h2 style={{ fontSize: '24px', marginBottom: '20px' }}>Address Proof</h2>
                <img
                   src={currentImageUrl} // Replace with dynamic URL
                    alt="Aadhar Front"
                    style={{
                        width: '100%',
                        maxWidth: '500px',
                        height: 'auto',
                        marginBottom: '20px',
                        borderRadius: '8px',
                    }}
                />
                <div style={{ width: '100%', textAlign: 'center', marginBottom: '20px' }}>
       
                </div>
                <div style={{ width: '100%', marginBottom: '20px' }}>
                    <label>Reject Reason</label>
                    <input
                        type="text"
                        placeholder="Enter reason for rejection"
                        className="form-control"
                        style={{ width: '100%' }}
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <button
                        className="btn btn-danger"
                        onClick={() => alert('Rejected')}
                        style={{ padding: '8px 20px' }}
                    >
                        Reject
                    </button>
                    <button
                        className="btn btn-secondary"
                        onClick={handleCloseModal}
                        style={{ padding: '8px 20px' }}
                    >
                        Close
                    </button>
                </div>
            </Modal> 

                                                                </form>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-xs-12">

                                                            <div className="m-t-20">
                                                                <form className="" action="#">
                                                                    <div className="form-group">
                                                                        <label>State</label>
                                                                        <label className="form-control">{row.StateName}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Area</label>
                                                                        <label className="form-control">{row.areaname}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Email</label>
                                                                        <label className="form-control">{row.Email}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Phone No. Phone no with STD code.</label>
                                                                        <label className="form-control">Put Phone no with STD code. here</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>ID Proof Type</label>
                                                                        <label className="form-control">{row.IDPType}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>ID Proof</label>
                                                                        <input type="file" className="form-control" />
                                                                        <input type="submit"  onClick={() => handleViewClick(getAadharImageUrl('adhaarback'))} value='View'
                                                                            className="btn btn-primary waves-effect waves-light form-control" style={{width:80,marginTop:8}} />
                                                                           
                                                                    </div>
                                                                                                                           </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                             
                                                    <h4 className="m-t-0 m-b-30 form-control btn btn-primary waves-effect waves-light font-monospace" style={{ fontWeight: 700, fontSize: 'large' }}>Bank Details</h4>

                                                    <hr width="100%" />

                                                    <div className="row">
                                                        <div className="col-sm-6 col-xs-12">

                                                            <div className="m-t-20">
                                                                <form className="" action="#">
                                                                    <div className="form-group">
                                                                        <label>Bank Name</label>
                                                                        <label className="form-control">{row.BankName}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Branch Name</label>
                                                                        <label className="form-control">{row.BranchName}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Account No</label>
                                                                        <label className="form-control">{row.AccNo}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Pan No</label>
                                                                        <label className="form-control">{row.PanNo}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Cheque Label Image</label>
                                                                        <input type="file" className="form-control" />
                                                                        <input type="submit" onClick={() => handleViewClick(getAadharImageUrl('cheque'))} value='View'
                                                                            className="btn btn-primary waves-effect waves-light form-control" style={{width:80,marginTop:8}} />
                                                                          
                                                                    </div>
                                                                    <div className="form-group">
                                                                        {/* <label>DS Image</label> */}

                                                                        <input type="submit" onClick={() => handleViewClick(getAadharImageUrl('Selfie'))} value='View DS Image'
                                                                            className="btn btn-primary waves-effect waves-light form-control" style={{width:200}} />
                                                                            
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-xs-12">

                                                            <div className="m-t-20">
                                                                <form className="" action="#">
                                                                    <div className="form-group">
                                                                        <label>Bank Brnach State</label>
                                                                        <label className="form-control">Put Awareness Name here</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>IFSC Code</label>
                                                                        <label className="form-control">{row.IFSCNo}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label style={{ color: 'white' }}>branch</label>
                                                                        <button className="form-control btn btn-primary waves-effect waves-light">Bank Branch details Button</button>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Cheque No.</label>
                                                                        <label className="form-control">{row.ChequeNo}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Pan Card Image</label>
                                                                        {/* <label className="form-control">{row.PanPhoto}</label> */}

                                                                        <input type="file" className="form-control"  />
                                                                        <input type="submit" onClick={() => handleViewClick(getAadharImageUrl('pan'))} value='View'
                                                                            className="btn btn-primary waves-effect waves-light form-control" style={{width:80,marginTop:8}}/>
                                                                            
                                                                    </div>
                                                                    <div className="form-group">
                                                                        {/* <label>DS Signature</label> */}
                                                                        {/* <label className="form-control">{row.signature}</label> */}

                                                                        <input type="submit" onClick={() => handleViewClick(getAadharImageUrl('signature'))} value=' View DS Signature'
                                                                            className="btn btn-primary waves-effect waves-light form-control" {...row.signature}  style={{width:200}}/>
                                                                           
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group">


<div>

    <button type="submit"
        className="btn btn-primary waves-effect waves-light" style={{ marginLeft: 1105 }}>
        Submit</button>

</div>




</div>
                                   
                                                 


                                                </div>
                                                {/* <label>Submit Button</label> */}
                                            </div>
                                        </div>
                                        
                                        
                                  
                                  
                                    </div>
                                    
  )

                        })
                    }
                                     
                                    
                                    
                                       
                                     <div className="row" id='kycdata' style={{display:'none'}}>
                                        <div className="col-sm-12">
                                            <div className="panel panel-primary">
                                                <div className="panel-body">
                                                    <h4 className="m-t-0 m-b-30">Complete KYC</h4>
                                                    <hr width="100%" />
                                                   
                                                    <div className="row">

                                                        <div className="col-sm-6 col-xs-12">

                                                            <div className="m-t-20">
                                                                <form className="" action="#">
                                                                    <div className="form-group">
                                                                        <label>YES</label>
                                                                        <input type="Checkbox" style={{ marginLeft: 5 }} id="approved"
                                                                          checked={isChecked} // Controlled state
            onChange={handleCheckboxChange} // Update sta
             />
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-xs-12">

                                                            <div className="m-t-20">
                                                                <form className="" action="#">
                                                                    <div className="form-group">
                                                                        <label>Approved Authority:</label>
                                                                        <input type="text" className="form-control"  id='Authority'  />
                                                                    </div>

                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                     
                                                    <div className="form-group">
                                        <div>

                                            <button type="submit"
                                                className="btn btn-primary waves-effect waves-light" onClick={InsertKycApproved} style={{ marginLeft: 1000
                                                    
                                                 }}>
                                                Approve</button>

                                        </div>
                                    </div>
                                          
                                          

                                                </div>

                                            </div>
                                        </div>
                                    </div>




                                     
                                   </>
                                    

                </div>
            </div>
        </div>
        <footer className="footer"> © 2016 WebAdmin - All Rights
            Reserved. </footer>
    </div>)
}

export default DSpersonaldetails;