import React, { useState, useEffect, useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
import { Gotohome } from '../Commonfiles/checkfortoken';
function Storedreamclubbusiness() {

	var [mydata, setmydata] = useReducer(myreducer, initialstate);
	//var [filtereddata, setfiltereddata] = useState([]);

	async function loaddata() {
		//    var x=await fetch(process.env.REACT_APP_URL+'businesstransaction?option=1');
		//    var y=await x.json();
		//    var z=JSON.stringify(y.recordset);
		//    var a=JSON.parse(z);
		//    setmydata(a);
		//     console.log("mydata",mydata);
		fetchbygetmethod(setmydata, 'businesstransaction', { tempid: localStorage.getItem("tempID"), month: document.getElementById('ddlmonth').value, year: document.getElementById('ddlyear').value });
	}

	useEffect(() => { loaddata(); }, []);




	return (
		<div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Monthly Dream Club Discount</h4>

					</div>
				</div>

				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Dream Club Discount Invoice</h4>
										<hr width="100%" />

										<div class="row">


											<div class="col-sm-6 col-xs-12">

												<div class="m-t-20">
													<form class="" action="#">
														<div class="form-group">
															<label>Month</label>
															<select class="form-control" id='ddlmonth'>
																<option value="01">January</option>
																<option value="02">February</option>
																<option value="03">March</option>
																<option value="04">April</option>
																<option value="05">May</option>
																<option value="06">June</option>
																<option value="07">July</option>
																<option value="08">August</option>
																<option value="09">September</option>
																<option value="10">October</option>
																<option value="11">November</option>
																<option value="12">December</option>
															</select>
														</div>






													</form>
												</div>
											</div>
											<div class="col-sm-6 col-xs-12">

												<div class="m-t-20">
													<form action="#">
														<div class="form-group">
															<label>Year</label>
															<select class="form-control" id='ddlyear'><option>2020</option>
																<option>2021</option>
																<option>2022</option>
																<option>2023</option>
																<option>2024</option>
															</select>

														</div>


													</form>




												</div>

											</div>



										</div>
										<div class="form-group">
											<div>
												<button type="submit" onClick={loaddata}
													class="btn btn-primary waves-effect waves-light">
													Submit</button>
												<button type="reset"
													class="btn btn-default waves-effect m-l-5">
													Download</button>
											</div>
										</div>
										<div class="container">
											<div class="row justify-content-center mt-5">
												<div class="col-md-6">
													<form>
														<div class="input-group col-lg-12 ">
															<input type="text" class="form-control " placeholder="Search" aria-label="Search" />

														</div>
													</form>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{
							mydata.initialstage == true ? <></>
								: mydata.myloading == true ? <Spinner />
									: mydata.myerror == true ? <Nodatafound />
										: mydata.mypost.length > 0 &&
										mydata.mypost.map((row, index) => {
											return (
												<div class="row">
													<div class="col-md-12">
														<div class="panel panel-primary">
															<div class="panel-body">
																<h4 class="m-b-30 m-t-0">Dream Club Discount Invoice</h4>
																<table id="datatable-buttons"
																	class="table table-striped table-bordered">
																	<thead>
																		<tr>
																			<th>Sr No</th>
																			<th>DS ID</th>
																			<th>DS NAME</th>
																			<th>INVOICE NO.</th>
																			<th>INVOICE DATE</th>
																			<th>Discount Amount</th>




																		</tr>
																	</thead>
																	<tbody>


																		<tr>
																			<td>{index + 1}</td>
																			<td>{row.tempid}</td>
																			<td>{row.applicantname}</td>
																			<td>{row.invoiceno}</td>
																			<td>{row.date}</td>
																			<td>{row.discountamt}</td>


																		</tr>


																	</tbody>
																	<tfoot>
																		<tr>
																			<td></td>
																			<td></td>
																			<td></td>
																			<td></td>

																			<td><strong>Total Discount</strong></td>
																			<td>{mydata.mypost.reduce((sum, row) => sum + (row.discountamt), 0).toFixed(2)}</td>

																		</tr>

																	</tfoot>
																</table>
															</div>
														</div>
													</div>
												</div>
											)
										})

						}

					</div>
				</div>


			</div>

		</div>
	)



}

export default Storedreamclubbusiness;