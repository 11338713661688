import {useState,useEffect,React} from 'react'

function Brpoductorderform()
{

	let bankdetails=[{BankName:"hdfc",IFSCCode:"hdfc123456",
                     BranchName:'jankipuram',ChequeNo:'1233445566778899',
					 AccountNo:'123456789', BankBranchState:'lucknow' }]

	const [data,setdata]=useState([]);

	function loaddata()
	{
		setdata(bankdetails);
	}	

	useEffect(()=>{loaddata()})



    return(
        <div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">User Profile</h4>
						
					</div>
				</div>
				
				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Bank Details</h4>
										<hr width="100%" />
										{ data.length>0 &&
										  data.map((mydata,index)=>{
											return(
										<div class="row">
											<div class="col-sm-6 col-xs-12">
											
												<div class="m-t-20">
													<form class="" action="#">
														<div class="form-group">
															<label>Bank Name</label>
															 <label 
															 class="form-control"
																 >{mydata.BankName}</label>
														</div>
														<div class="form-group">
															<label>Branch Name</label>
															 <label 
															 class="form-control"
																 >{mydata.BranchName}</label>
															
																
															
														</div>
														<div class="form-group">
															<label>Account No.</label>
															 <label 
															 class="form-control"
																 >{mydata.AccountNo}</label>
														</div>
														<div class="form-group">
															<label>Cheque Label Image</label>
															
                                                            <div>
																<input type="file" class="form-control" required
																	 />
															</div>
															<div>
																<button type="submit"
																	class="btn btn-primary waves-effect waves-light">
																	View Image</button>
																
															</div>
														</div>
														
														
													
														
														
													</form>
												</div>
											</div>
											<div class="col-sm-6 col-xs-12">
												
												<div class="m-t-20">
													<form action="#">
														<div class="form-group">
															<label>Bank Branch State</label>
															 <label 
															 class="form-control"
																 >{mydata.BankBranchState}</label>
														</div>
														<div class="form-group">
															<label>IFSC Code</label>
															 <label 
															 class="form-control"
																 >{mydata.IFSCCode}</label>
                                                            	<div class="form-group">
															<div>
																<button type="submit"
																	class="btn btn-primary waves-effect waves-light">
																	Bank Branch Details</button>
																
															</div>
														</div>
                                                           
														</div>
														<div class="form-group">
															<label>Cheque No.</label>
															 <label 
															 class="form-control"
																 >{mydata.ChequeNo}</label>
														</div>
														<div class="form-group">
															<label>Pan Card Image</label>
															 <div>
																<input type="file" class="form-control"  required/>
															</div>
															<div>
																<button type="submit"
																	class="btn btn-primary waves-effect waves-light">
																	View Image</button>
																
															</div>
															
														</div>

                                                        	<div class="form-group">
															<div>
																<button type="submit"
																	class="btn btn-primary waves-effect waves-light">
																	Submit</button>
																<button type="reset"
																	class="btn btn-default waves-effect m-l-5">
																	Cancel</button>
															</div>
														</div>
                                                        </form>
														
														
														
														
														</div>
														
														</div>
														
													
												</div>
													)
												})
										}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
    )
}

export default Brpoductorderform;