import { useState, useEffect, React, useReducer } from 'react';
import { initialstate, myreducer, fetchbygetmethod, fetchbypostmethod } from '../../Commonfiles/Myreducer';
function Adminstockafterturnover()
{
  
     
      const [mydata,setmydata]=useReducer(myreducer, initialstate);
    
    
      async function loaddata()
   {
    var tempid=document.getElementById('txttempid').value;
    fetchbygetmethod(setmydata, 'Adminstockafterturnover',{TempID:tempid});

     console.log("mydata",mydata);
   }
  
   //useEffect(()=>{loaddata();},[]);


function display(){
         loaddata();
   var data=document.getElementById("tabledata");
	data.style.display='block'

  }

    return(<div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Check Stock</h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">Check Stock</h4>
                                {/* <hr width="100%"/> */}
                                <div className="row">
                                
                                
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                <div className="form-group">
                                                    <label>Enter Branch/Depot/Store Id</label>
                                                    <input type="text" id="txttempid" class="form-control"/>
															    
																
                                                </div>
                                                
                                                
                                                
                                            </form>
                                        </div>
                                    </div>
                                     
                                    
                                     
                                    <div className="form-group">
                                                    <div>
                                                        
                                                        <input type="button" value="Submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:730}} onClick={display} />
                                                              
                                                             <input type="button" value="Download"
                                                            className="btn btn-default waves-effect waves-light" style={{marginLeft:5}} />
                                                            
                                                        
                                                    </div>
                                                </div> 
                                    
                                </div>

                               



                                <div class="row"  style={{display:'none'}} id='tabledata'>
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
                                    <div class="form-group">
															{/* <label>IP OR Login ID</label> */}
															<div>
																<div>
																 <input type="text" class="form-control" placeholder="Search Item......" aria-label="Search"  />
															</div>
															</div>
														</div>
                                                      
										{/* <h4 class="m-b-30 m-t-0">Pannel Comission</h4> */}
										 <table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
                                                    <th>Sr No</th>
												    <th>Product name</th>
												    <th>Quantity</th>
                                                    
													
                                                    
                                                
													
													
													
												</tr>
											</thead>
											<tbody>
                                            {mydata.mypost.length > 0 ? (
                                                                mydata.mypost.map((row, index) => (	
                                              <tr>
													
													
                                                    <td>{index+1}</td>
                                                    <td>{row.ItemName}</td>
                                                    <td>{row.qty}</td>
                                                   
                                                  
                                                   
                                                   
													
									
												</tr>
											
                                        ))
												
                                    ) : null}
											</tbody>
										
										</table> 
									</div>
								</div>
							</div>
						</div>
                                
                               
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footer"> © 2016 WebAdmin - All Rights
        Reserved. </footer>
</div>)
}

export default Adminstockafterturnover;