import { useState, useEffect, React, useReducer } from 'react';
import { initialstate, myreducer, fetchbygetmethod, fetchbypostmethod } from '../../Commonfiles/Myreducer';


function Adminstock()
{
   
   
   
      const [mydata,setmydata]=useReducer(myreducer, initialstate);
    
    
      async function loaddata()
   {
   
    fetchbygetmethod(setmydata, 'Adminstock',{});

     console.log("mydata",mydata);
   }
  
   useEffect(()=>{loaddata();},[]);



    return(<div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Stock Reports</h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">Stock Reports</h4>
                                <hr width="100%"/>
                                <div className="row">
                               <div className="form-group">
                                                    <div>
                                                        <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:30}}>
                                                            Download</button>
                                                        
                                                    </div>
                                                </div> 
                                </div>

                               



                                <div class="row">
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
                                    <div class="form-group">
															{/* <label>IP OR Login ID</label> */}
															<div>
																<div>
																 <input type="text" class="form-control" placeholder="Search Item......" aria-label="Search" id="Applicantid" />
															</div>
															</div>
														</div>
										{/* <h4 class="m-b-30 m-t-0">Pannel Comission</h4> */}
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
												    <th>S.No.</th>
												    <th>Applicant Id</th>
													<th>Batch No</th>
													<th>Product Name</th>
													<th>Quantity</th>
                                                    <th>Rate</th>
                                                    <th>Amount</th>
                                                    <th>BV</th>
                                                    
                                                
													
													
													
												</tr>
											</thead>
											<tbody>
                                            {mydata.mypost.length > 0 ? (
                                                                mydata.mypost.map((row, index) => (		
                                                
                                              <tr>
													
													<td>{index+1}</td>
                                                    {/* <td><input type="checkbox"/></td> */}
                                                    <td>{row.ApplicantId}</td>
                                                    <td>{row.batchno}</td>
                                                    <td>{row.itemname}</td>
                                                    <td>{row.quantity}</td>
                                                    <td>{row.Amount}</td>
                                                    <td>{row.total_amount}</td>
                                                    <td>{row.BusinessVolume}</td>
                                                   
                                                   
                                                   
													
									
												</tr>
											  ))
												
                                            ) : null}
											</tbody>
										
										</table>
									</div>
								</div>
							</div>
						</div>
                                
                               
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footer"> © 2016 WebAdmin - All Rights
        Reserved. </footer>
</div>)
}

export default Adminstock;