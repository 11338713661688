import React, { useEffect, useState, useReducer } from 'react';
import App from './App';
import { Link, useNavigate } from 'react-router-dom';
import bg from './trial.jpeg';
import logo from './logo.png';
import { fetchbygetmethod, myreducer, initialstate } from './Commonfiles/Myreducer';
import ImageSlider from './ImageSlider';
export default function Login() {
	const images = [
		'/sliderimages/slider1.jpg'
		//'/sliderimages/slider2.svg'
	  ];
	  var [isfreshlogin,setisfreshlogin]=useState(true);
	var [errstatus, seterrstatus] = useState(0);
	const navigate = useNavigate();
	const [mydata, setmydata] = useReducer(myreducer, initialstate);// useReducer(initialstate,myreducer);
	const [myadmindata, setmyadmindata] = useReducer(myreducer, initialstate);// useReducer(initialstate,myreducer);
	//let [ispanellogin,setispanellogin]=useState(0);
	let ispanellogin = 0, loginpanelprocess = 0;
	//alert('display'+ispanellogin);
	function checklogin() {
		//alert('2');
		setisfreshlogin(false);
        clearlocalstorage();
		checkpanellogin();
		//alert('ispanellogin==',ispanellogin);
		//if(ispanellogin==0 && loginpanelprocess==1)




	}
	function clearlocalstorage()
    {
        localStorage.clear();
      
    }
	
	function checkpanellogin() {
		clearlocalstorage();
		var uname = document.getElementById("uname").value;
		var pwd = document.getElementById("pwd").value;

		console.log("uname", uname);
		console.log("pwd", pwd);
	
		fetchbygetmethod(setmydata, 'login', { uname: uname, passd: pwd });
			


	}
	function setpanellogin()
	{
		//alert('3');
		
			if (mydata.token && mydata.mypost.length>0) {

				localStorage.setItem("tempID", mydata.mypost[0].TempID);
				//console.log("1");
				localStorage.setItem("applicantID", mydata.mypost[0].ApplicantId);
				localStorage.setItem("applicantType", mydata.mypost[0].ApplicantType.trim());
				//localStorage.setItem("LoginUser",d[0].ApplicantType.trim());
				localStorage.setItem("token", mydata.token);

				console.log(localStorage.getItem("tempID"));
				console.log(localStorage.getItem("applicantID"));
				console.log(localStorage.getItem("applicantType"));
				//ispanellogin=1;
				//alert('1ispanellogin=='+ispanellogin);//+"temp="+temp);
				ispanellogin = 1;

			   window.location.reload();
			}
			
		
	
	}
	
	useEffect(()=>{
		                  console.log("useffect mydata", mydata);
							if(isfreshlogin==false)setpanellogin();},[mydata]);
	
	return (<div className='LoginPage'>
		<div className='LoginSpotlight'>
			<ImageSlider images={images}/>
			{/* <div className='LoginFooter'>
				<div className='LoginText'>
					<div className='LoginTextTitle'>
						Lorem Ispum Dolor
					</div>
					<div className='LoginTextBody'>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
					</div>
				</div>
				<div className='LoginNavigation'>
					<i class="fa fa-chevron-circle-left"></i>
					<i class="fa fa-chevron-circle-right"></i>
				</div>
			</div> */}
		</div>
		<div className='LoginForm'>
			<div className="jmvdlogo">
				<img src={logo}></img>


			</div>
			<div className='LoginFormContents'>
				<div className='LoginFormTitle'>
					<h2>Welcome To JMVD Family</h2>
					<span>  Please SignIn</span>
				</div>
				<form class="form-horizontal m-t-20">
					<div class="form-group">
						<div class="col-xs-12">
							<input id="uname" class="form-control" type="text" required=""
								placeholder="Username" />
						</div>
					</div>
					<div class="form-group">
						<div class="col-xs-12">
							<input id="pwd" class="form-control" type="password" required=""
								placeholder="Password" />
						</div>
					</div>
					<div class="form-group">
						<div class="col-xs-12">
							<div class="checkbox checkbox-primary">
								<input id="checkbox-signup" type="checkbox" /> <label
									for="checkbox-signup"> Remember me </label>
							</div>
						</div>
					</div>
					<div class="form-group text-center m-t-40">
						<div class="col-xs-12">
							<input type='button' value='Log In' onClick={checklogin}
								class="btn btn-primary btn-block btn-lg waves-effect waves-light"
							/>

							{errstatus == 1 && <label>Invalid Username or Password</label>}
						</div>
					</div>
					<div class="form-group m-t-20 m-b-0">
						{/* <div class="col-m-6">
							<a href="pages-recoverpw.html" class="text-muted"><i
								class="fa fa-lock m-r-5"></i> Forgot your password?</a>
						</div> */}

					</div>
					{/* <div class="form-group m-t-20 m-b-0">

						<div class="col-m-7">
							<Link to="/Registrationform" class="text-muted"><i class="fa fa-user m-r-5"></i>Create An Account</Link>
						</div>
					</div> */}
				</form>
			</div>
		</div>
	</div>
	)
}