import React from 'react';

import ReactDOM from 'react-dom/client';
import './index.css';


import App from './App';
import Depotleftmenu from './Depot/Depotleftmenu';
// import 'mdb-react-ui-kit/dist/css/mdb.min.css';
// import "@fortawesome/fontawesome-free/css/all.min.css";

const root = ReactDOM.createRoot(document.getElementById('wrapper'));


root.render(
    <React.StrictMode>
    <App/>
    </React.StrictMode>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
