import { useState, useEffect, React, useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod, fetchbypostmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
import { Gotohome } from '../Commonfiles/checkfortoken';

import InvoiceGST from "../invoicebill_gst";
// import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

function BranchAddEditSaleinvoice() {
    //from database
    const [showbill, setshowbill] = useReducer(myreducer, initialstate);
    const [item, setitem] = useReducer(myreducer, initialstate);
    const [panelchild, setpanelchild] = useReducer(myreducer, initialstate);
    const [myinvoiceno, setinvoiceno] = useReducer(myreducer, initialstate);

    //to database
    const [jsonsaleitems, setjsonsaleitems] = useState(false);
    const [jsonsaleinvoice, setjsonsaleinvoice] = useState(false);

    const [totalamount, settotalamount] = useState(0);
    const [totalbv, settotalbv] = useState(0);


    function search() {
        // Declare variables
        var input, filter, table, tr, td, i, txtValue;
        input = document.getElementById("txtsearch");
        filter = input.value.toUpperCase();
        table = document.getElementById("datatable-responsive");
        tr = table.getElementsByTagName("tr");

        // Loop through all table rows, and hide those who don't match the search query
        for (i = 0; i < tr.length; i++) {
            for (var ci = 2; ci < 4; ci++) {
                td = tr[i].getElementsByTagName("td")[ci];
                if (td) {
                    txtValue = td.textContent || td.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        tr[i].style.display = "";
                        break;
                    } else {
                        tr[i].style.display = "none";
                    }
                }//end if
            }//end loop column
        }//end for lopp row
    }

    function setcurrentdate() {
        const now = new Date();

        // Format the date as YYYY-MM-DD
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // January is 0
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const currentDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;

        // Set the value of the input element
        document.getElementById('txtinvoicedate').value = currentDateTime;
        document.getElementById('txtinvoicedate').readOnly = true;

    }

    async function loaditemdata() {
        //    var x = await fetch(process.env.REACT_APP_URL+"getitemsbyapplicantid?applicanttype=" + localStorage.getItem("applicantType") + "&applicantid=" + localStorage.getItem("applicantID"));
        //    var y = await x.json();
        //    var z = await y.recordset;
        //    console.log('data10', z);
        //    setitem(z);
        //    console.log('item', item);
        fetchbygetmethod(setitem, 'getitemsbyapplicantid', { applicanttype: localStorage.getItem("applicantType"), applicantid: localStorage.getItem("applicantID") });

    }

    async function loadpanelchilddata() {

        fetchbygetmethod(setpanelchild, 'getpanelchildbyparentid', { parentapplicantid: localStorage.getItem("applicantID") });

    }
    function createdatafordatabase() {
        savedata();
       
    }
    async function savedatatodatabase() {
        //savedata();
        console.log("jsonitemdata", jsonsaleitems);
        console.log("jsoninvoicedata", jsonsaleinvoice);
        try {
            //    const response = await fetch(process.env.REACT_APP_URL+"saveinvoice", {
            //        method: "POST",
            //        headers: {
            //            "Content-Type": "application/json"
            //        },
            //        body:JSON.stringify({'jsondata':jsonsaleinvoice})
            //    });

            //    if (!response.ok) {
            //        throw new Error(`HTTP error! Status: ${response.status}`);
            //    }

            //    const responseData = await response.json();
            //    const z = responseData.recordset;
            //    console.log("z=", z);
            fetchbypostmethod(setshowbill, 'saveinvoice', { 'jsondata': jsonsaleinvoice });


        } catch (error) {
            console.log("show error", error);
        }
    }

    function Displaydisable(whois) {
        // alert('hi');
        var Branch = document.getElementById('ddlpanelchildbranch');
        var Depot = document.getElementById('ddlpanelchilddepot');

        if (whois == 'branch') {
            Branch.disabled = false;
            Depot.disabled = true;
        }
        else {
            Branch.disabled = true;
            Depot.disabled = false;
        }
    }

    function additem(currentitem, e, index) {
        var currentsaleqty = parseInt(document.getElementById("txtsaleqty-" + index).value);
        //alert("currentsaleqty--"+currentsaleqty);
        currentsaleqty = isNaN(currentsaleqty) ? 0 : currentsaleqty;
        settotalamount(isNaN(totalamount) ? 0 : totalamount);
        settotalbv(isNaN(totalbv) ? 0 : totalbv);

        if (jsonsaleitems && jsonsaleitems.includes(currentitem)) //true or false(uncheck) i.e. remove item from json
        {
           // alert("old");
            // alert("qty"+saleqty+"avl qty"+currentitem.Quantity);
            if (currentsaleqty > currentitem.Quantity)//stock is less then demand
            {
                alert("Please check Available Quantity of " + currentitem.itemname);

                e.target.value = 0; // Unselect the checkbox
                currentsaleqty = 0;

            }
            //alert(currentsaleqty);
            var rowindex = jsonsaleitems.findIndex((row) => (row.ItemId === currentitem.ItemId && row.batchno === currentitem.batchno));
                 
            var previoustotalbv = totalbv - (jsonsaleitems[rowindex].businessvolume * jsonsaleitems[rowindex].Qty)
            var previoustotalamount = totalamount - (jsonsaleitems[rowindex].SellingPrice * jsonsaleitems[rowindex].Qty)
                

// alert("previoustotalamount"+totalamount+"-"+(jsonsaleitems[rowindex].businessvolume ));//* jsonsaleitems[rowindex].Qty ));

            previoustotalamount = isNaN(previoustotalamount) ? 0 : previoustotalamount;
            previoustotalbv = isNaN(previoustotalbv) ? 0 : previoustotalbv;

            //alert("previoustotalamount"+previoustotalamount);
            jsonsaleitems[rowindex].Qty = currentsaleqty;
           
            settotalbv(previoustotalbv + (jsonsaleitems[rowindex].businessvolume * jsonsaleitems[rowindex].Qty));
            settotalamount(previoustotalamount + (jsonsaleitems[rowindex].SellingPrice * jsonsaleitems[rowindex].Qty));

            if (currentsaleqty == 0 || isNaN(currentsaleqty)) {
            setjsonsaleitems(jsonsaleitems.filter((row) =>  !(row.ItemId === currentitem.ItemId && row.batchno === currentitem.batchno)));
            
            }




        }
        else if (isNaN(currentsaleqty)) //if alphabet then error...only number needed 
        {
            alert("Please enter a valid quantity.");
            e.target.checked = false;//checkbox
            e.target.value = 0;
            currentsaleqty = 0;
            return; // Exit function if saleqty is not a number
        }
        else if (currentsaleqty > currentitem.Quantity)//stock is less then demand
        {
            alert("Please check Available Quantity of " + currentitem.itemname);

            e.target.checked = false; // Unselect the checkbox
            e.target.value = 0;
            currentsaleqty = 0;

        }
        else {
            currentitem.ItemName = currentitem.itemname;
            currentitem.Qty = currentsaleqty;
            currentitem.InvoiceType = "S";
            currentitem.FromApplicantId = parseInt(localStorage.getItem("applicantID"));
            currentitem.ApplicantId = document.getElementById("ddlpanelchilddepot").value;
            currentitem.InvoiceNo = document.getElementById("txtinvoiceno").value;
            currentitem.Rate = currentitem.SellingPrice;
            currentitem.BV = currentitem.businessvolume;
            currentitem.Unit = null;
            currentitem.VAT = currentitem.Tax1;
            currentitem.SAT = currentitem.Tax1;
            currentitem.Status = 'Y';
            currentitem.expirydate = null;
            currentitem.surcharge = null;
            currentitem.invoicereturn = null;
            currentitem.IsFullValue = null;
            // currentitem.SellingPrice1='';
            currentitem.RelativeInvoiceDate = new Date(document.getElementById("txtinvoicedate").value);
            currentitem.FinancialYear = parseInt(process.env.REACT_APP_FINANCIAL_YEAR);
            currentitem.fromitemdetailid = null;
            currentitem.toitemdetailid = null;
            currentitem.InvoiceDate = null;
            currentitem.GST = 'Y';
            currentitem.batchno=currentitem.batchno;
            // currentitem.batchno='';
            // currentitem.BV='';
            // currentitem.ItemId='';
            // currentitem.ItemName='';
            // currentitem.Quantity='';


            jsonsaleitems?
            setjsonsaleitems([...jsonsaleitems, currentitem])
            : setjsonsaleitems([currentitem]);
            settotalamount(totalamount + (currentitem.SellingPrice * currentsaleqty));
            settotalbv(totalbv + (currentitem.businessvolume * currentsaleqty));
            //totamt=totamt+amt;
            //settotamt(totamt+amt);//100+200=300
        }
        //  
        //alert('currentid'+currentitem.ApplicantId);
        //  alert("additem" + JSON.stringify(jsonsaleitems));
    }
    function savedata() {

        //var appid= document.getElementById("ddlpanelchild").value;
        //alert("id"+appid);
        //  alert("ji"+ document.getElementById("ddlpanelchild").value);
        // var jsonreplace=JSON.stringify(jsonsaleitems);
        // var     saleitemdata = jsonreplace.replace(/\\/g, "");
        // console.log("replavce data",saleitemdata);
        var saleinvoice = {
            "StorageLocationId": 1,
            "FromApplicantId": parseInt(localStorage.getItem("applicantID")),
            "ApplicantId": document.getElementById("ddlpanelchilddepot").value,

            //(document.getElementById("txtdstempid").value.ApplicantId),
            //   document.getElementById("ddlpanelchildbranch").value:
            //   document.getElementById("ddlpanelchilddepot").value),
            "InvoiceType": document.getElementById("txtinvoicetype").value,
            "InvoiceNo": document.getElementById("txtinvoiceno").value,
            "InvoiceDate": new Date(document.getElementById("txtinvoicedate").value),
            "Reference": document.getElementById("txtreference").value,
            "FormNo": null,
            "PanNo": null,
            "VatNo": null,
            "VehicleNo": null,
            "Weight": null,
            "IsDelivered": "N",
            "Remark": null,
            "status": "Y",
            "packets": 1,
            "transportname": null,
            "DeliverAt": null,
            "invoiceamt": totalamount,
            "invoicebv": totalbv,
            "ip": null,
            "FinancialYear": parseInt(process.env.REACT_APP_FINANCIAL_YEAR),
            "GST": "Y",
            "FrieghtCharges": null,
            "LPCharges": null,
            "InsuranceCharges": null,
            "OtherCharges": null,
            "ReverseCharge": null,
            "grno": null,
            "Dos": null,

            "SaleItems": jsonsaleitems
        }
        //console.log(saleinvoice);

        setjsonsaleinvoice(saleinvoice)
        //console.log("myjsondata",jsonsaleinvoice);
        //  savedatatodatabase();
    }
    function filldataafterusestateupdates() {
        if (myinvoiceno.mypost != null && myinvoiceno.mypost.length > 0) {
            var Reference = document.getElementById("txtreference");
            var invoiceno = document.getElementById('txtinvoiceno');
            // console.log('getinvoicecount',dstempid);//.mypost!=null ? dstempid.mypost:'NA');
            invoiceno.value = "Branch" + localStorage.getItem("applicantID") + "-TI" + myinvoiceno.mypost[0].invoiceno;
            invoiceno.readOnly = true;
            Reference.value = myinvoiceno.mypost[0].invoiceno;
            Reference.readOnly = true;
        }

       
    }
    async function Createinvoiceno() {

        //alert("hiii");
        var ddlinvoicetype = document.getElementById('txtinvoicetype').value;
        var invoiceno = document.getElementById('txtinvoiceno');
        var FinancialYear = parseInt(process.env.REACT_APP_FINANCIAL_YEAR);
        var FromApplicantId = localStorage.getItem('applicantID');
        var Reference = document.getElementById("txtreference");


        fetchbygetmethod(setinvoiceno, 'getinvoicecount', { InvoiceType: ddlinvoicetype, FromApplicantId: FromApplicantId, FinancialYear: FinancialYear })



    }
    useEffect(() => {

        loadpanelchilddata();
        setcurrentdate();

    }, [])

    useEffect(()=>{ if(jsonsaleinvoice)savedatatodatabase()},[jsonsaleinvoice])

    useEffect(() => {

        filldataafterusestateupdates();


    }, [myinvoiceno])
 
    useEffect(()=>{settotalamount(0);settotalbv(0);setjsonsaleitems(null)},[item])
    return (<>{
        showbill.myerror == false && showbill.myloading == false   ?

            <InvoiceGST invoiceno={document.getElementById('txtinvoiceno').value} financialyear={parseInt(process.env.REACT_APP_FINANCIAL_YEAR)} option={99} />
            :
            <div className="content-page">
                <div className="content">
                    <div className="">
                        <div className="page-header-title">
                            <h4 className="page-title">Sale Invoice</h4>

                        </div>
                    </div>

                    <div className="page-content-wrapper ">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="panel panel-primary">
                                        <div className="panel-body">
                                            <h4 className="m-t-0 m-b-30">Add /Edit Sale Invoice</h4>
                                            {/* <hr width="100%"/> */}
                                            <div className="row">
                                                <div className="form-group">
                                                    <div>


                                                        <button onClick={() => window.history.back()} type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{ marginLeft: 800 }}>
                                                            Go Back</button>

                                                    </div>
                                                </div>


                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Invoice Date</label>
                                                                <input type="datetime-local" id="txtinvoicedate" class='form-control' />
                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>


                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Invoice Number</label>
                                                                <input type="text" id="txtinvoiceno" class='form-control' />
                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>



                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Sale Invoice Type</label>
                                                                <select class='form-control' id="txtinvoicetype" onChange={Createinvoiceno}>
                                                                    <option value="0">---Select---</option>
                                                                    <option value="S" >Sale Invoice</option>
                                                                    <option value="T">Transfer Invoice</option>

                                                                </select>
                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>

                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Reference</label>
                                                                <input type="text" id="txtreference" class='form-control' />
                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>


                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Depot Id</label>
                                                                <select type="date" id="ddlpanelchilddepot" class='form-control' onChange={() => { Displaydisable('depot') }}>
                                                                    <option>---Select---</option>
                                                                    {
                                                                        panelchild.mypost.length > 0 &&
                                                                        panelchild.mypost.map((row, index) => {

                                                                            return (
                                                                                <option value={row.ApplicantId}>{row.name}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>
                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Return Invoice No.</label>
                                                                <input type="text" id="txtreturninvoiceno" class='form-control' placeholder="Return Invoice No." />
                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>


                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Branch Name</label>
                                                                <select type="date" id="ddlpanelchildbranch" class='form-control' onChange={() => { Displaydisable('branch') }}>
                                                                    <option>---Select---</option>
                                                                    {
                                                                        panelchild.mypost.length > 0 &&
                                                                        panelchild.mypost.map((row, index) => {
                                                                            if (row.ApplicantType === 'Branch')
                                                                                return (
                                                                                    <option value={row.ApplicantId}>{row.name}--({row.Tempid})</option>
                                                                                )
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>





                                                        </form>
                                                    </div>


                                                </div>

                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Vehicle Number</label>
                                                                <input type="text" id="txtvehicleno" class='form-control' />

                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>
                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Remark</label>
                                                                <input type="text" id="txtremark" class='form-control' />

                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>
                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Total Packet</label>
                                                                <input type="text" id="txtpacket" class='form-control' />

                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>



                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Transport Name</label>
                                                                <input type="text" id="txtTransport" class='form-control' />
                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>
                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Is Block</label>
                                                                <select class='form-control'>
                                                                    <option>Unblock</option>
                                                                    <option>Block</option>
                                                                </select>
                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>

                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Unit</label>
                                                                <select class='form-control'>
                                                                    <option>---Select Unit---</option>
                                                                    <option>PCS</option>
                                                                </select>
                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>

                                                <div className="form-group">
                                                    <div>

                                                        <input type="button" class="btn btn-primary waves-effect waves-light" value="Add Items" data-toggle="modal"
                                                            data-target=".bs-example-modal-lg" onClick={loaditemdata} style={{ marginLeft: 700, marginTop: 49 }} />


                                                        <div class="modal fade bs-example-modal-lg" tabindex="-1"
                                                            role="dialog" aria-labelledby="myLargeModalLabel"
                                                            aria-hidden="true">
                                                            <div class="modal-dialog modal-lg">
                                                                <div class="modal-content">
                                                                    <div class="modal-header">
                                                                        <button type="button" class="close"
                                                                            data-dismiss="modal" aria-hidden="true">×</button>
                                                                        <h4 class="modal-title" id="myLargeModalLabel">Select Item</h4>
                                                                    </div>
                                                                    <div class="modal-body">
                                                                        <div className='Records'>
                                                                            <div class="row">
                                                                                <div class="col-md-12">
                                                                                    <div class="panel panel-primary">
                                                                                        <div class="container">
                                                                                            <div class="row justify-content-center mt-5">
                                                                                                <div class="col-md-6">
                                                                                                    <form>
                                                                                                        <div class="input-group col-lg-12 ">
                                                                                                            <input type="text" class="form-control " id='txtsearch' onChange={search} placeholder="Search" aria-label="Search" />

                                                                                                        </div>
                                                                                                    </form>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="panel-body">



                                                                                            <label style={{ color: 'red' }}>Total Amount =</label>
                                                                                            <label style={{ color: 'red', marginLeft: 5 }}>{totalamount}</label>
                                                                                            <label style={{ color: 'red', marginLeft: 5 }}>Total Bv =</label>
                                                                                            <label style={{ color: 'red', marginLeft: 5 }}>{totalbv}</label>
                                                                                            <div class="table-container-Table">
                                                                                                <table id="datatable-responsive"
                                                                                                    class="table table-striped table-bordered dt-responsive nowrap"
                                                                                                    cellspacing="0" width="100%">
                                                                                                    <thead>
                                                                                                        <tr>
                                                                                                            
                                                                                                            <th>Sale Quantity</th>
                                                                                                            <th>Item ID</th>
                                                                                                            <th>Item Name</th>
                                                                                                            <th>Item Rate</th>
                                                                                                            <th>BV</th>

                                                                                                            <th>Available Quantity</th>

                                                                                                            <th>Batch No</th>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>

                                                                                                        {item.myloading == true ? <Spinner />
                                                                                                            : item.myerror == true ? <Nodatafound />
                                                                                                                :
                                                                                                                item.mypost.length > 0 &&
                                                                                                                item.mypost.map((row, index) => {
                                                                                                                    return (<tr key={index}>
                                                                                                                        
                                                                                                                        <td><input type="number" style={{ width: 75 }} id={"txtsaleqty-" + index} onChange={(e) => additem(row, e, index)} /></td>
                                                                                                                        <td>{row.ItemId}</td>
                                                                                                                        <td>{row.itemname}</td>
                                                                                                                        <td>{row.SellingPrice}</td>
                                                                                                                        <td>{row.businessvolume}</td>
                                                                                                                        <td>{row.Quantity}</td>
                                                                                                                        <td>{row.batchno}</td>

                                                                                                                    </tr>
                                                                                                                    );
                                                                                                                })}
                                                                                                    </tbody>

                                                                                                </table>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <button type="submit" onClick={createdatafordatabase}
                                                            className="btn btn-primary waves-effect waves-light" style={{ marginLeft: 5, marginTop: 49 }} >
                                                            Submit</button>

                                                    </div>
                                                </div>



                                            </div>








                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Gotohome showbill={showbill} /> */}
            </div>
    }
    </>
    )

}



export default BranchAddEditSaleinvoice;