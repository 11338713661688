import { act } from "react";



export const actiontypes={
    Fetch_Start:'start',
    Fetch_Success:'success',
    Fetch_Error:'error',
    Get:'get',
    Post:'post',
   


};

export const initialstate = {
    initialstage:true,token:'',myloading: true, myerror: false, mypost: []
}
export const myreducer = (initialstate, action) => {
    
    switch (action.type) {
        case actiontypes.Fetch_Start: return { initialstage:false,myloading: true, myerror: false, mypost: [] };
        case actiontypes.Fetch_Success:{
           
            return { initialstage:false,myloading: false, myerror: false, mypost: action.payload.recordset?action.payload.recordset:action.payload,token:action.payload.token };    
        } 
        case actiontypes.Fetch_Error: return { initialstage:false,myloading: false, myerror: true, mypost: [] };
       
        default: return initialstate
    }

}
export async function fetchbygetmethod(setmydata,endpoint, querydata) {
   
    setmydata({type:actiontypes.Fetch_Start});
    console.log("start",endpoint);
    // Constructing the query string from the query object
    const queryString = Object.keys(querydata)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(querydata[key])}`)
        .join('&');

    // Constructing the URL with the query string
    const url = `${process.env.REACT_APP_URL}${endpoint}?${queryString}`;
   
try{
    await fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" ,
                 'authorization':`Bearer ${localStorage.getItem("token")}`}
        // body: JSON.stringify({
        // 	applicantid: localStorage.getItem("applicantID")

        // })
    }
    )
    .then((response) => { return  response.json() })
      
    .then((data) => {//console.log("llllll",data); 
                     setmydata( { type:actiontypes.Fetch_Success, payload: data } )})
    .catch((err) => { //console.log("fffff",err);
                      setmydata( { type:actiontypes.Fetch_Error }) })
}
catch (err) {
    //console.log("fffnnf", err);
    setmydata({ type: actiontypes.Fetch_Error });
}
    
}
export async function fetchbypostmethod(setmydata,endpoint, bodydata) {

    setmydata({type:actiontypes.Fetch_Start});
    // Constructing the URL with the query string
    const url = `${process.env.REACT_APP_URL}${endpoint}`;
    const token = localStorage.getItem("token");

    // Log the token to ensure it is being retrieved correctly
    console.log("Token from localStorage:", token);
    await fetch(url, {
        method: "Post",
        headers: { "Content-Type": "application/json",
                    'authorization':`Bearer ${token}`},
        body: JSON.stringify(bodydata)
    }
    )  .then((response) => { return  response.json() })
      
    .then((data) => { setmydata( { type:actiontypes.Fetch_Success, payload: data } )})
    .catch((err) => { setmydata( { type:actiontypes.Fetch_Error }) })
}
export async function fetchbyputmethod(setmydata,endpoint, bodydata) {

    setmydata({type:actiontypes.Fetch_Start});
    // Constructing the URL with the query string
    const url = `${process.env.REACT_APP_URL}${endpoint}`;
    const token = localStorage.getItem("token");
    await fetch(url, {
        method: "PUT",
        headers: { "Content-Type": "application/json",
            'authorization':`Bearer ${token}`},
        body: JSON.stringify(bodydata)
    }
    )  .then((response) => { return  response.json() })
      
    .then((data) => { setmydata( { type:actiontypes.Fetch_Success, payload: data } )})
    .catch((err) => { setmydata( { type:actiontypes.Fetch_Error }) })
}