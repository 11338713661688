import { useState, useEffect, React, useReducer } from 'react';
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod, fetchbypostmethod } from '../../Commonfiles/Myreducer';


// import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

function AdminItemSubCategory()
{
    const [mydata, setmydata] = useReducer(myreducer, initialstate);

    const [AdminItemcategory,setAdminItemcategory]=useReducer(myreducer, initialstate);
    
    
    async function loaddata()
  {
  
   fetchbygetmethod(setAdminItemcategory, 'AdminItemcategory',{});

    console.log("mydata",mydata);
  }
 
  useEffect(()=>{loaddata();},[]);

    async function Insertdata() {
		var SubCategoryName = document.getElementById('SubCategoryName');
		var SubCategoryDescription = document.getElementById('SubCategoryDescription');
        var Category = document.getElementById('Category');
		var status = document.getElementById('selectstatus');
	
		var jsonobj = { SubCategoryName: SubCategoryName.value, Description: SubCategoryDescription.value,CategoryId:Category.value,Status: status.value };
		
		try {
			await fetchbypostmethod(setmydata, 'AdmininsertSubCategory', jsonobj);
			alert("SubCategory Added Successfully");
			window.location.reload(); // Reloads the page after successful registration
		} catch (error) {
			console.error("Error during registration:", error);
		}
	}



    return(<div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Sub Category </h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">Add / Edit Sub Category</h4>
                                {/* <hr width="100%"/> */}
                                <div className="row">
                                <div className="form-group">
                                                    <div>
                                                        
                                                         
                                                         <button onClick={() => window.history.back()} type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:5}}>
                                                           Sub Category data </button>       
                                                        
                                                    </div>
                                                </div> 
                                
                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>Sub Category Name</label>
                                                    <input type="text" class='form-control' id='SubCategoryName'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Sub Category Description</label>
                                                   <input type="text" class='form-control' id='SubCategoryDescription'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Category</label>
                                                
                                                    <select class="form-control" id='Category'>
                                                    {AdminItemcategory.mypost.length > 0 ? (
                                                                AdminItemcategory.mypost.map((row, index) => (
															   <option value={row.CategoryId}>{row.CategoryName}</option>
                                                            ))
												
                                                        ) : null}
																</select>
                                                               
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Status</label>
                                                    <select class="form-control" id='selectstatus'>
                                                                <option value='Y'>Unblock</option>
															     <option value='N'>Block</option>
																
																

																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    
                                 <div className="form-group"> 
                                                    <div>
                                                        
                                                        <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:1300}} onClick={Insertdata}>
                                                            Submit</button>    
                                                        
                                                    </div>
                                                </div> 
                                    
                                     
                                    
                                </div>

                               



                                
                               
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footer"> © 2016 WebAdmin - All Rights
        Reserved. </footer>
</div>)
}



export default AdminItemSubCategory;