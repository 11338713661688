import React from 'react';
import { Route, Routes } from 'react-router-dom';


import Storepersonaldetails from './Store/StorePersonalDetail';
import StorecommunicationDetails from './Store/Storecommunicationdetail';
import Storebankdetails from './Store/Storebankdetail';
import Storepaneldetails from './Store/StorePanelDetail';
import Storepurchaseapprove from './Store/StorePurchaseApprove';
import Storesalevoucher from './Store/StoreSaleVoucher';
import Storestock from './Store/StoreStock';
import StoreConsolidateBusiness from './Store/StoreConsolidateBusiness';
import Storesale from './Store/StoreSale';
import Storedreamclubbusiness from './Store/StoreDreamClubBusiness';
import Storebbcopenchoice from './Store/StoreBBCOpenChoiceSaleVoucher';
import Storepaymentdetail from './Store/Storepaymentdetail';
import Storecompanybankdetail from './Store/Storecompanybankdetail';
import StoreAddEditSaleinvoice from './Store/StoreAddEditSaleinvoice';
import StoreAddEditBCC from './Store/StoreAddEditBCC';
import ProductList from './Store/ProductList';
import Storedashboard from './Store/storedashboard';

export default function Storeroutes() {

    return (
        <Routes>
            <Route path='/' element={<Storepersonaldetails />} />
            <Route path='Storepersonaldata' element={<Storepersonaldetails />} />
            <Route path='StorecommunicationDetails' element={<StorecommunicationDetails />} />
            <Route path='Storebankdetails' element={<Storebankdetails />} />
            <Route path='Storepaneldetail' element={<Storepaneldetails />} />
            <Route path='Storepurchaseapprove' element={<Storepurchaseapprove />} />
            <Route path='Storesalevoucher' element={<Storesalevoucher />} />
            <Route path='Storebbcopenchoice' element={<Storebbcopenchoice />} />
            <Route path='Storestock' element={<Storestock />} />
            <Route path='Storesale' element={<Storesale />} />
            <Route path='StoreConsolidateBusiness' element={<StoreConsolidateBusiness />} />
            <Route path='Storedreamclubbusiness' element={<Storedreamclubbusiness />} />
            <Route path='Storepaymentdetail' element={<Storepaymentdetail />} />
            <Route path='Storecompanybankdetail' element={<Storecompanybankdetail />} />
            <Route path='StoreAddEditSaleinvoice' element={<StoreAddEditSaleinvoice/>}/>
            <Route path='StoreAddEditBCC' element={<StoreAddEditBCC/>}/>
            <Route path='ProductList' element={<ProductList/>}/>
            <Route path='storedasboard' element={<Storedashboard/>}/>
            
        </Routes>
    )
}
