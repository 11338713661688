import React, { useState, useEffect, useReducer } from 'react';
import { initialstate, myreducer, fetchbygetmethod, fetchbypostmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';

import { Gotohome } from '../Commonfiles/checkfortoken';

function Storebbcopenchoice() {
	var [mydata, setmydata] = useReducer(myreducer, initialstate);
	const [totalBV, setTotalBV] = useState(0);

	async function loaddata() {
		try {
			
			fetchbygetmethod(setmydata, 'Storebbcsalevoucher', { fromapplicantid: localStorage.getItem('applicantID') });
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	}

	useEffect(() => { loaddata(); }, []);


	useEffect(() => {

		let bvTotal = 0;

		mydata.mypost.forEach((row) => {

			bvTotal += row.invoicebv;
		});


		setTotalBV(Number(bvTotal.toFixed(2)));
	}, [mydata]);




	return (
		<div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Sale Invoice</h4>

					</div>
				</div>

				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Sale Invoice</h4>
										<hr width="100%" />

										<div class="row">
											<div class="form-group">
												<div>
													<a href='StoreAddEditBCC' type="submit"
														class="btn btn-primary waves-effect waves-light">
														Add New Sale Invoice</a>
												</div>
											</div>


										</div>




									</div>

								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-b-30 m-t-0">Sale Invoice</h4>
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
													<th>Sr No.</th>
													<th>Invoice Type</th>
													<th>Invoice Number</th>
													<th>Invoice Date</th>
													<th>Invoice BV</th>
													<th>Status</th>
													<th>Status</th>


												</tr>
											</thead>

											<tbody>

												{mydata.myloading == true ? <Spinner />
													: mydata.myerror == true ? <Nodatafound />
														: mydata.mypost.length > 0 &&
														mydata.mypost.map((row, index) => {
															return (<tr key={index}>
																<td>{index + 1}</td>
																<td>{row.InvoiceType}</td>
																<td>{row.InvoiceNo}</td>
																<td>{row.InvoiceDate}</td>
																<td>{row.invoicebv}</td>
																<td>{row.status}</td>
																<td> <button className="btn btn-primary waves-effect waves-light">Unblock</button>  </td>
															</tr>
															);
														})}
											</tbody>

											<tfoot>
												<tr>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
													<td><strong>Total={totalBV}</strong></td>
													<td></td>

												</tr>

											</tfoot>
										</table>
									</div>
								</div>
							</div>
						</div>


					</div>
				</div>
			</div>
			<Gotohome mydata={mydata} />

		</div>
	)



}

export default Storebbcopenchoice;