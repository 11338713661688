import { useState, useEffect, React , useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod, fetchbypostmethod } from '../../Commonfiles/Myreducer';
import Spinner from '../../Commonfiles/spinner';
import Nodatafound from '../../Commonfiles/nodatafound';
import { Gotohome } from '../../Commonfiles/checkfortoken';

function Adminpurchasevoucher()
{


  var [mydata,setmydata]=useReducer(myreducer, initialstate);


 


	async function loadinvoicedata()
  {
    try {
	
	  fetchbygetmethod(setmydata, 'AdminpurchaseVoucher', {});
   
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}
 
  useEffect(()=>{loadinvoicedata();
	            
  },[]);




  return(
		<div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Pannel Comission</h4>
						
					</div>
				</div>
				
				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Pannel Comission</h4>
										<hr width="100%" />
										
										<div class="row">
                                        	<div class="form-group">
															<div>
                                                           
																<a href='AdminAddEditPurchaseVoucher' type="submit"
																	class="btn btn-primary waves-effect waves-light" style={{marginLeft:5}}>
																	Add New Purchase Voucher</a>

															</div>
														</div> 
                                            
                                                       
                                                        	
                                                          
													
												</div>

                                             <div class="row">
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										{/* <h4 class="m-b-30 m-t-0">Pannel Comission</h4> */}
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
												    <th>Sr No.</th>
												    <th>Purchase Voucher Code</th>
													<th>Invoice Number</th>
													<th>Vendor</th>
													<th>Status</th>
													
													
													
													
												</tr>
											</thead>
											<tbody>
											{mydata.myloading == true ? <Spinner />
											: mydata.myerror == true ? <Nodatafound />
												:
												mydata.mypost.length > 0 &&
												mydata.mypost.map((row,index)=> {
                                             return ( <tr key={index}>
												<td>{index+1}</td>
                                                     <td>{row.PVCode}</td>
													<td>{row.InvoiceNo}</td>
                                                    <td>{row.VendorName}</td>
													<td>{row.Status=='Y'?'UnBlock':'Block'}</td>
													
                                                   
													
									
												</tr>
											
										);
									})} 
											</tbody>
										
										</table>
									</div>
								</div>
							</div>
						</div>
										
										
											</div>
                                            
										</div>
									</div>
								</div>
							
							
							
						
							
							</div>
						</div>
					</div>


				</div>
	)


  
}

export default Adminpurchasevoucher;