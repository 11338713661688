import { useState, useEffect, React, useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbypostmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
import axios from 'axios';
import { Gotohome } from '../Commonfiles/checkfortoken';
function Dsbankdetail() {
	const imagedefault = "/assets/images/users/avatar-1.jpg";
	
	var [mydata, setmydata] = useReducer(myreducer, initialstate);
	const [myimgupload, setmyimgupload] = useReducer(myreducer, initialstate);

	//photo section

	const [filecheque, setfilecheque] = useState(null);
	const [filechequeimage, setfilechequeimage] = useState(null);

    const handleFileChangeforcheque = (event) => {
		const imgfile = event.target.files[0];
		
		if (imgfile) {


			const reader = new FileReader();
			reader.onloadend = () => {

				setfilechequeimage(reader.result);
				setfilecheque(imgfile);

			};
			reader.readAsDataURL(imgfile);

		}
	};

	const onSubmitcheque = async (e) => {

		e.preventDefault();

		if (!filecheque) {

			console.error('No file selected.');
			return;
		}
	const imagename=localStorage.getItem('tempID')+'_cheque';
	// Create a new file with the new name
    const newFileName = `${imagename}.${filecheque.name.split('.').pop()}`; // e.g., dsid.jpg
    const newFile = new File([filecheque], newFileName, { type: filecheque.type });

	const formData = new FormData();
  
	formData.append('file', newFile);

		
		try {
			const res = await axios.post(process.env.REACT_APP_URL + 'upload', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});

			const { fileName } = res.data;

			console.log('fileName', fileName);

			fetchbypostmethod(setmyimgupload, 'dsuploadcheque', { fileName: fileName, applicantid: localStorage.getItem('applicantID') });

			alert("Image Uploaded!!");
		} catch (err) {
			console.error('ret', err);
		}
	};

	const [filepan, setfilepan] = useState(null);
	const [filepanimage, setfilepanimage] = useState(null);
    const handleFileChangeforpan = (event) => {
		const imgfile = event.target.files[0];
		
		if (imgfile) {


			const reader = new FileReader();
			reader.onloadend = () => {

				setfilepanimage(reader.result);
				setfilepan(imgfile);

			};
			reader.readAsDataURL(imgfile);

		}
	};

	const onSubmitpan = async (e) => {
		
		e.preventDefault();

		if (!filepan) {
          
			console.error('No file selected.');
			return;
		}
		const imagename=localStorage.getItem('tempID')+'_pan';
		// Create a new file with the new name
		const newFileName = `${imagename}.${filepan.name.split('.').pop()}`; // e.g., dsid.jpg
		const newFile = new File([filepan], newFileName, { type: filepan.type });
	
		const formData = new FormData();
	  
		formData.append('file', newFile);
	
		try {
			const res = await axios.post(process.env.REACT_APP_URL + 'upload', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});

			const { fileName } = res.data;

			console.log('fileName', fileName);

			fetchbypostmethod(setmyimgupload, 'dsuploadpan', { fileName: fileName, applicantid: localStorage.getItem('applicantID') });
			alert("Image Uploaded!!");
			
		} catch (err) {
			alert("err"+err);
			console.error('ret', err);
		}
	};


	
	
	function getdata(tempid) {
		fetchbygetmethod(setmydata, 'bankdetails', { tempid: tempid })
		
	}
	useEffect(() => { getdata(localStorage.getItem('tempID')); }, []);
	useEffect(() => {
		// if (isFirstRender.current) {
		// 	isFirstRender.current = false;
		//   } else {
		//console.log("mydata",myimgupload);
		if (myimgupload.myerror == true)
			alert("Please Upload Your Image Again!!");
		else if (myimgupload.initialstage==false && myimgupload.myloading == false)
		{
			alert('File uploaded Successfully');
			getdata(localStorage.getItem('tempID'));
			
		}
			
		// }
	
	}, [myimgupload])



	return (
		<div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">User Profile</h4>

					</div>
				</div>

				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Bank Details</h4>
										<hr width="100%" />
										{mydata.initialstage==true?<></>:
										  mydata.myloading ?<Spinner/>
										  :mydata.myerror ?<Nodatafound/>
										  :
										    
											
												
													<div class="row">
														<div class="col-sm-6 col-xs-12">

															<div class="m-t-20">
																<form class="" action="#">
																	<div class="form-group">
																		<label>Bank Name</label>
																		<label
																			class="form-control"
																		>{mydata.mypost[0].bankname}</label>
																	</div>
																	<div class="form-group">
																		<label>Branch Name</label>
																		<label
																			class="form-control"
																		>{mydata.mypost[0].branchname}</label>



																	</div>
																	<div class="form-group">
																		<label>Account No.</label>
																		<label
																			class="form-control"
																		>{mydata.mypost[0].accno}</label>
																	</div>
																	<div class="form-group">
																		<label>Cheque Label Image</label>

																		<div>
																			<input type="file" class="form-control" required  onChange={handleFileChangeforcheque}
																			/>
																		</div>
																		<div>
																		<img style={{ height: 300, width: 300 }} 
																		src={filechequeimage ? filechequeimage : Array.isArray(mydata.mypost) && mydata.mypost.length > 0 && mydata.mypost[0].chequephoto ? './uploads/' + mydata.mypost[0].chequephoto : process.env.PUBLIC_URL + imagedefault} alt=""
														className="img-circle" />

																		</div>
																	</div>

																	<div class="form-group">
																		<div>
																			<button type="submit" onClick={(e)=>onSubmitcheque(e)}
																				class="btn btn-primary waves-effect waves-light">
																				Submit Cheque</button>
																			
																			
																		</div>
																	</div>



																</form>
															</div>
														</div>
														<div class="col-sm-6 col-xs-12">

															<div class="m-t-20">
																<form action="#">
																	<div class="form-group">
																		<label>Bank Branch State</label>
																		<label
																			class="form-control"
																		>{mydata.mypost[0].statename}</label>
																	</div>
																	<div class="form-group">
																		<label>IFSC Code</label>
																		<label
																			class="form-control"
																		>{mydata.mypost[0].ifscno}</label>
																		<div class="form-group">
																			<div>
																				<button type="submit"
																					class="btn btn-primary waves-effect waves-light">
																					Bank Branch Details</button>

																			</div>
																		</div>

																	</div>
																	<div class="form-group">
																		<label>Cheque No.</label>
																		<label
																			class="form-control"
																		>{mydata.mypost[0].chequeno}</label>
																	</div>
																	<div class="form-group">
																		<label>Pan Card Image</label>
																		<div>
																			<input type="file" class="form-control" onChange={handleFileChangeforpan} required />
																		</div>
																		<div>
																		<img style={{ height: 300, width: 300 }} src={filepanimage?filepanimage:Array.isArray(mydata.mypost) && mydata.mypost.length > 0 && mydata.mypost[0].panphoto ? './uploads/' + mydata.mypost[0].panphoto : process.env.PUBLIC_URL + imagedefault} alt=""
														className="img-circle" />

																		</div>

																	</div>

																	<div class="form-group">
																		<div>
																			
																			<button type="reset" onClick={(e)=>onSubmitpan(e)}
																				class="btn btn-primary waves-effect m-l-5">
																				Submit PAN</button>
																		</div>
																	</div>
																</form>




															</div>

														</div>


													</div>
												
											
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <Gotohome mydata={mydata} /> */}
		</div>
	)
}

export default Dsbankdetail;