import { useState, useEffect, React, useReducer } from 'react';
import { initialstate, myreducer, fetchbygetmethod, fetchbypostmethod } from '../../Commonfiles/Myreducer';
// import Spinner from '../../Commonfiles/spinner';
// import Nodatafound from '../../Commonfiles/nodatafound';
function Adminupdateuserlogin()
{

const [mydata,setmydata]=useReducer(myreducer, initialstate);


	async function loaddata()
  {

   fetchbygetmethod(setmydata, 'Adminupdateuserlogin',{});
    console.log("mydata",mydata);
  }
  
  useEffect(()=>{loaddata();},[]);




  return(
		<div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Update User Login</h4>
						
					</div>
				</div>
				
				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Update User Login</h4>
										<hr width="100%" />
										
										<div class="row">
                                        	<div class="form-group">
															<div>
																<button type="submit" class="btn btn-primary waves-effect waves-light" style={{marginLeft:30}}>
																	Submit</button>
															</div>
														</div> 
                                                       
                                                        	
                                                          
													
												</div>

                                                <div class="row">
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										{/* <h4 class="m-b-30 m-t-0">Update User Login</h4> */}
                                        <div class="form-group">
															<label>IP OR Login ID</label>
															<div>
																<div>
																 <input type="text" class="form-control" placeholder="Search By IP OR Login ID......" aria-label="Search"  />
															</div>
															</div>
														</div>
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
												    <th>Approved</th>
												    <th>ID</th>
													<th>IP</th>
													<th>Browser</th>
													<th>Pc_Name</th>
													<th>Login Date</th>
													<th>Login Id</th>
													<th>Password</th>
                                                    <th>Logout Time</th>
                                                    <th>Is Auto Logout</th>
													
													
												</tr>
											</thead>
											<tbody>
												{mydata.mypost.length > 0 ? (
                                                                mydata.mypost.map((row, index) => (
											
                                              <tr>
													
													<td><input type="checkbox"  /> </td>
                                                    <td>{row.id}</td>
													<td>{row.IP}</td>
                                                    <td>{row.Browser}</td>
													<td>{row.PC_NAME}</td>
													<td>{row.LoginDate}</td>
													<td>{row.LoginID}</td>
                                                    <td>{row.Pass}</td>
                                                    <td>{row.LogoutTime}</td>
                                                    <td>{row.IsAutoLogout}</td>
									
												</tr>
											
                                            		
										))
												
									) : null}
												
                                                
											</tbody>
										
										</table>
									</div>
								</div>
							</div>
						</div>

       
										
										
											</div>
                                            
										</div>
									</div>
								</div>
							
							
						
							</div>
						</div>
					</div>


				</div>
	)


  
}

export default Adminupdateuserlogin;