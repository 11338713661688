import { useState, useEffect, React, useReducer } from 'react';
import { initialstate, myreducer, fetchbygetmethod, fetchbypostmethod } from '../../Commonfiles/Myreducer';
function Admincompanysalebd()
{
     
    const [mydata,setmydata]=useReducer(myreducer, initialstate);
    const totalSumBV = mydata.mypost.reduce((sum, row) => sum + (row.bv || 0), 0).toFixed(2);
      async function loaddata()
   {
    var invoicetype=document.getElementById('selectinvoicetype').value;
    var month=document.getElementById('selectmonth').value;
     var year=document.getElementById('selectyear').value;
    

    fetchbygetmethod(setmydata, 'Admincompanysalebd',{invoicetype:invoicetype,month:month,year:year});

     console.log("mydata",mydata);
   }

   function display(){
    loaddata();
var data=document.getElementById("tabledata");
data.style.display='block'

}

    return(<div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Company Monthwise Sale with BV</h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">Company Monthwise Sale with BV</h4>
                                {/* <hr width="100%"/> */}
                                <div className="row">
                                
                                
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>Month</label>
                                                    <select class="form-control" id='selectmonth'>
															    <option value="01">January</option>
                                                                <option value="02">February</option>
                                                                <option value="03">March</option>
                                                                <option value="04">April</option>
                                                                <option value="05">May</option>
                                                                <option value="06">June</option>
                                                                <option value="07">July</option>
                                                                <option value="08">August</option>
                                                                <option value="09">September</option>
                                                                <option value="10">October</option>
                                                                <option value="11">November</option>
                                                                <option value="12">December</option>

																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Year</label>
                                                    <select class="form-control" id='selectyear'>
															     <option>2016</option>
																<option>2017</option>
																<option>2018</option>
																<option>2019</option>
																<option>2020</option>
                                                                <option>2021</option>
                                                                <option>2022</option>
                                                                <option>2023</option>

																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                <div className="form-group">
                                                        <label>Sale/Sale Return</label>
                                                       <select  class="form-control" id='selectinvoicetype'>
                                                       <option value='S'>Sale</option>
                                                       <option value='SR'> Sale Return</option>
        

                                                       </select>
                                                    </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                 <div className="form-group">
                                                    <div>
                                                        
                                                        <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:735}} onClick={() => { display(); loaddata(); }}>
                                                            Submit</button>    
                                                         <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:5}}>
                                                            Download</button>       
                                                        
                                                    </div>
                                                </div> 
                                    
                                     
                                    
                                </div>

                               



                                <div class="row" style={{display:'none'}} id='tabledata'>
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
                                    <div class="form-group">
															{/* <label>IP OR Login ID</label> */}
															<div>
																<div>
																 <input type="text" class="form-control" placeholder="Search......" aria-label="Search"  />
															</div>
															</div>
														</div>
										{/* <h4 class="m-b-30 m-t-0">Pannel Comission</h4> */}
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
                                                    <th>Sr No</th>
												    <th>Invoice No.</th>
												    <th>ID</th>
													<th>Invoice Date</th>
                                                    <th>BV</th>
                                              
													
                                                    
                                                
													
													
													
												</tr>
											</thead>
											<tbody>
												{mydata.mypost.length>0 &&
											  mydata.mypost.map((row,index)=>{
                                              
                                              return(	
											
                                              <tr>
													
													
                                                    <td>{index+1}</td>
                                                    <td>{row.invoiceno}</td>
                                                    <td>{row.tempid}</td>
                                                    <td>{row.invoicedate}</td>
                                                    <td>{row.bv}</td>
                                                    
                                                  
                                                  
                                                   
                                                   
													
									
												</tr>
											
                                             ) 
                                              })
												
                                                }
											</tbody>

                                            <tfoot>

<tr>
         
         
         <td></td>
         <td></td>
         <td></td>
       
         <td></td>
         <td><strong >Total = {totalSumBV}</strong> </td>
         
        
        
         

     </tr>

</tfoot>
										
										</table>
									</div>
								</div>
							</div>
						</div>
                                
                               
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footer"> © 2016 WebAdmin - All Rights
        Reserved. </footer>
</div>)
}

export default Admincompanysalebd;