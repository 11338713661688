import {useState,useEffect,React,useReducer} from 'react';
import {fetchbygetmethod,initialstate,myreducer} from '../Commonfiles/Myreducer'
import  Spinner  from '../Commonfiles/spinner';
import  NOdatafound  from '../Commonfiles/nodatafound'; 

function Dsjoinindetails()
{
	const [mydata,setmydata]=useReducer(myreducer,initialstate);

	function loaddata()
	{
		fetchbygetmethod(setmydata,'joiningdetails',{applicantid:localStorage.getItem('applicantID')});
	}	

	useEffect(()=>{loaddata()},[])


    return(
        <div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">View Profile</h4>
						
					</div>
				</div>
				{
					mydata.initialstage==true ? <></>
					:mydata.myloading==true ? <Spinner/>
					:mydata.myerror ==true?<NOdatafound/>
					:
					<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Joining Details</h4>
										<hr width="100%" />
										{ mydata.mypost.length>0 &&
										  
											
										<div class="row">
										
												<div class="col-sm-6 col-xs-12">
											
												<div class="m-t-20">
													<form class="" action="#">
														<div class="form-group">
															<label>Awareness No</label>
															 <label 
															 class="form-control"
																 >{mydata.mypost[0].AwarenessNo}</label>
														</div>
														<div class="form-group">
															<label>Sponsor No</label>
															
																<label class="form-control">{mydata.mypost[0].SponsorNo}</label>
															
															
														</div>
														<div class="form-group">
															<label>Position</label>
															<label class="form-control">{mydata.mypost[0].Position}</label>
														</div>
														<div class="form-group">
															<label>Status</label>
															<label class="form-control">{mydata.mypost[0].Status}</label>
														</div>
														
														
													
														
														
													</form>
												</div>
											</div>
											<div class="col-sm-6 col-xs-12">
												
												<div class="m-t-20">
													<form action="#">
														<div class="form-group">
															<label>Awareness Name</label>
															<label class="form-control">{mydata.mypost[0].AwarenessName}</label>
														</div>
														<div class="form-group">
															<label>Sponsor Name</label>
															<label class="form-control">{mydata.mypost[0].SponsorName}</label>
														</div>
														<div class="form-group">
															<label>Joining Date</label>
															<label class="form-control">{mydata.mypost[0].JoiningDate}</label>
														</div>
														<div class="form-group">
															<label>Date of Birth</label>
															<label class="form-control">{mydata.mypost[0].ApplicantDob}</label>
														</div>
                                                        </form>
														
														
														
														
														</div>
														
														</div>
														
														
													
												</div>
													
												
										}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
				}
				
					</div>
				</div>
    )
}

export default Dsjoinindetails;