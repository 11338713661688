import { useState, useEffect, React , useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod, fetchbypostmethod } from '../../Commonfiles/Myreducer';
//import { get } from 'http';
function AdminAddEditPurchaseVoucher()
{
    const [purchaseOrderinvoicecode, setpurchaseOrderinvoicecode] = useReducer(myreducer, initialstate);
    const [getvendorname, setgetvendorname] = useReducer(myreducer, initialstate);
    const [myitemdetail, setmyitemdetail] = useReducer(myreducer, initialstate);
    const [item,setitem]=useState([]);
    const [checkedrowslist,setcheckedrowslist]=useState({});
    const [jsonitemdata,setjsonitemdata]=useState([]);
    const [mydata, setmydata] = useReducer(myreducer, initialstate);
    const [currentDate, setCurrentDate] = useState('');

    async function loadmaxinvoiceno() {
        //alert('1');
        await fetchbygetmethod(setpurchaseOrderinvoicecode, 'Admingetmaxinvoicenopurchasevoucher', {});
       // console.log("Fetched Data:", mydata);
    }

    async function loadvendorname() {
        var POCode=document.getElementById('txtPOCode');
        await fetchbygetmethod(setgetvendorname, 'Admingetvendordetailpurchasevoucher', {POCode:POCode.value});
        console.log("Fetched Data:", getvendorname);
    }

     function loadItems() {
        var POCode=document.getElementById('txtPOCode');
         fetchbygetmethod(setmyitemdetail, 'Admingetitemsdetailpurchasevoucher', {pocode:POCode.value});
        console.log("Fetched Data:", myitemdetail);
    }

     function displaytable() {

	document.getElementById("tabledisplay").style.display = "block";
     loadItems();
    }

    function handlecheckbox(index)
{
   setcheckedrowslist((previousindex)=>({...previousindex,[index]:!previousindex[index]}));
}

    function handleaddtojsonitem()
{
         const selecteditems=item.filter((row,index)=>checkedrowslist[index]);
        // const orderno=(document.getElementById('txtpocode').textContent);
          setjsonitemdata(selecteditems.map(({itemid,itemname,orderqty,BatchNo,ReceivedItem,ExpiryDate,tax1,tax2,rate})=>({itemid,itemname,orderqty:parseInt(orderqty),BatchNo,ReceivedItem:parseInt(ReceivedItem),ExpiryDate,VAT:parseInt(tax1),SAT:parseInt(tax2),rate})));
        // console.log("jsondata",jsonitemdata);

}


function savetodatabase()
{
    const jsondata = {
        PVCode: document.getElementById('txtPOCode')?.value || '',     
        invoicetype: document.getElementById('txtincoicetype')?.value || '',      
        InvoiceNo: document.getElementById('txtinvoiceno')?.textContent || '',      
        VendorId: document.getElementById('txtvendorid')?.value || '',
        ReceivedItem: document.getElementById('txtreciveditem')?.value || '',
        Date: new Date().toISOString().split('T')[0],
        Status: 'Y',
        FinancialYear: parseInt(process.env.REACT_APP_FINANCIAL_YEAR),
        GST: 'Y',
        dateofsupply: document.getElementById('txtdate')?.value || '',
        OrderItems:jsonitemdata,
    };
    
    console.log("jsondata", jsondata);

   fetchbypostmethod(setmydata,'Admininvoicedatainsert', {jsondata:jsondata});
   window.location.reload();
}

function handleInputChange(e,index,column)
{
    const newitem=[...item];
    newitem[index][column]=e.target.value;
    setitem(newitem);

}

useEffect(()=>{console.log("checkedrowslist",checkedrowslist)},[checkedrowslist]);

// useEffect(()=>{
//     if(myitemdetail.mypost.length>0) setitem(myitemdetail.mypost.map(row=>({

//         ...row,
       
       
    
//     }
      
//     )))
//     console.log("myitemdetail",myitemdetail);
// }
    
//    ,[myitemdetail]);

   useEffect(()=>{ console.log("jsondata",jsonitemdata);},[jsonitemdata])   ;
    

    useEffect(()=>{
        loadmaxinvoiceno();
       // loadvendorname();
        //loadItems();
       
},[]);



useEffect(()=>{
    if(myitemdetail.mypost.length>0)
         setitem(myitemdetail.mypost.map(row=>({
        ...row,
        itemid: row.itemid || 0,
        itemname: row.itemname || '',
        orderqty: row.orderqty || 0
       
       
    }
      
    )))
    console.log("myitemdetail",myitemdetail);
}
    
   ,[myitemdetail]);
   useEffect(()=>{
   
    console.log("itemnew",item);
}
    
   ,[item]);
      
   useEffect(() => {
    const today = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format
    setCurrentDate(today);
  }, []);

  useEffect(()=>{console.log("purchaseOrderinvoicecode",purchaseOrderinvoicecode)},[purchaseOrderinvoicecode])


    return(<div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Purchase Voucher</h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">Add /Edit Purchase Voucher</h4>
                                {/* <hr width="100%"/> */}
                                <div className="row">
                                <div className="form-group">
                                                    <div>
                                                        
                                                         
                                                         <button onClick={() => window.history.back()} type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:800}}>
                                                        Go Back</button>       
                                                        
                                                    </div>
                                                </div> 


                                 <div class="form-group">
															{/* <label>IP OR Login ID</label> */}
															<div>
																<div>
                                                                <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Purchase Order"
                        aria-label="Search"
                        id="txtPOCode"
                        style={{ width: 200 }}
                        onChange={()=>{loadvendorname();loadItems();}} // Trigger when POCode is changed}
                    />
															</div>
															</div>
														</div>
                                
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>Invoice Number</label>
                                                    <p  class='form-control' id='txtinvoiceno'>{purchaseOrderinvoicecode.mypost.length > 0 &&
                                                      purchaseOrderinvoicecode.mypost[0].InvoiceNo &&
                                                            purchaseOrderinvoicecode.mypost[0].InvoiceNo.replace(/\d+/, match => (parseInt(match)).toString())}</p>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Invoice Type</label>
                                                   <select class='form-control' id='txtincoicetype'>
                                                   <option value='P'>Purchase</option>
                                                   <option value='PR'>Purchase Return</option>
                                                   </select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                 <label >Vendor Name</label>
                            <input
                                type="text"
                                className="form-control"
                                value={getvendorname.mypost.length > 0 ? getvendorname.mypost[0].vendorname : ''}
                                readOnly
                            />
                            <input
    type="hidden"
    id='txtvendorid'
    value={getvendorname.mypost.length > 0 ? getvendorname.mypost[0].Vendorid : ''}
/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>Unit</label>
                                                    <select class='form-control'>
                                                        <option>---Select Unit---</option>
                                                        <option>PCS</option>
                                                    </select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                  
                                    <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Reverse Charge</label>
                                                   <input type="text" value='0' class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>Date Of Supply</label>
                                                    <input type="date" class='form-control' id='txtdate'  value={currentDate} 
                                                     onChange={(e) => setCurrentDate(e.target.value)}  />
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Insurance Charge</label>
                                                   <input type="text" value={0} class='form-control'/>
                                                  
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Frieght Charge</label>
                                                   <input type="text" value={0} class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>Other Charge</label>
                                                    <input type="text" value={0} class='form-control'/>
                                                       
                                                    
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>GR NO</label>
                                                   <input type="text" value={0} class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Loading & Packing Charge</label>
                                                   <input type="text" value={0} class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>Transport Name</label>
                                                    <input type="text" class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>NAG</label>
                                                   <input type="text" value={0} class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Vehicle No:</label>
                                                   <input type="text"  class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>

                                        <div className="form-group">
                                                    <div>
                                                        
                                                          <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:-359}} onClick={displaytable}>
                                                           Show Item</button>   

                                                           <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:5}} onClick={handleaddtojsonitem}>
                                                            Add Item</button>  
                                                        
                                                        <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:593}} onClick={savetodatabase}>
                                                            Submit</button>    
                                                        
                                                    </div>
                                                </div> 
                                        
                                        
                                    </div>
                                     
                                    
                                 
                                    
                                     
                                    
                                </div>

                                <div class="row" id="tabledisplay" style={{ display: 'none' }}>
                                        <div class="col-md-12">
                                            <div class="panel panel-primary">
                                                <div class="panel-body">
                                                    <div class="form-group">
                                                        {/* <label>IP OR Login ID</label> */}
                                                        <div>
                                                            <div>
                                                                <input type="text" class="form-control" placeholder="Search Item......" aria-label="Search" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <h4 class="m-b-30 m-t-0">Pannel Comission</h4> */}
                                                    <table id="datatable-buttons"
                                                        class="table table-striped table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Select</th>
                                                                <th>Item Id</th>
                                                                <th>Item Name</th>
                                                                <th>Quantity</th>
                                                                <th>Received Items</th>
                                                                <th>Batch No</th>
                                                                <th>Expiry Date</th>
																




                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {item.length > 0 ? (
                                                                item.map((row, index) => (

                                                                    <tr>
                                                                        <td><input type="checkbox" onChange={()=>handlecheckbox(index)} style={{ marginLeft: 21 }}    /></td>
                                                                        <td>{row.itemid}</td>
                                                                        <td>{row.itemname}</td>
                                                                        <td>{row.orderqty}</td>
                                                                        <td><input type="text" id='txtreciveditem' onChange={(e)=>handleInputChange(e,index,'ReceivedItem')} /></td>
																		<td><input type="text"  onChange={(e)=>handleInputChange(e,index,'BatchNo')}  /></td>
                                                                       <td><input type="Date"   onChange={(e)=>handleInputChange(e,index,'ExpiryDate')} /></td>





                                                                    </tr>
                                                                ))

                                                            ) : null}
                                                        </tbody>

                                                    </table>
                                                  
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                               



                                
                               
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footer"> © 2016 WebAdmin - All Rights
        Reserved. </footer>
</div>)
}



export default AdminAddEditPurchaseVoucher;