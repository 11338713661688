import React from 'react';
import { Route, Routes } from 'react-router-dom';

import DSupdateprofile from './DirectSeller/DSupdateprofile';
import DSReportawarenessincomedetail from './DirectSeller/DSreportwarenessincome';
import DSChangePassword from './DirectSeller/DSChangePassword';
import DSmonthlyStatement from './DirectSeller/DSmonthlyStatement';
import DSIdCard from './DirectSeller/DSIdCard';
import Yourgroup from './DirectSeller/DSyourgroup';
import Dsawarenesstransferdetails from './DirectSeller/dsawarenesstransferdetails';
import Dsproducttraningprogram from './DirectSeller/dsproducttraningprogram';
import Rptincomeawarenessdetail from './DirectSeller/dsreportincomeawarenessdetail';
import Dsmatchingiad from './DirectSeller/dsmatchingpointincomeawarenessdetails';
//import Dsbusinesstransaction from './DirectSeller/DSBUSINESSTRANSACTION';
import Dspersonaldetail from './DirectSeller/dspersonaldetails';
import Dsjoinindetails from './DirectSeller/dsjoiningdetails';
import DscommunicationDetails from './DirectSeller/dsCommunicationDetails';
import Dsbusinesstransaction from './DirectSeller/DSBUSINESSTRANSACTION';
import Dsbankdetail from './DirectSeller/dsbankdetails';
import Dsyourbusiness from './DirectSeller/dsyourbusiness';
import Dsawarenessincomedetail from './DirectSeller/dsawarenessincomedetails';
import DsfinancialReport from './DirectSeller/dsfinancialyearpurchasingreport';
import Dsbusinessbuilding from './DirectSeller/dsbusinessbuildingclub';
import Dsdirectawarenessincome from './DirectSeller/dsdirectawarenessincome';
import Dsdirectid from './DirectSeller/dsDirectIncomeDetails'
import DSUploadPicture from './DirectSeller/DSUploadPicture';
import DSdashboard from './DirectSeller/dsDashboard';
import Dsrepurchaseincomereport from './DirectSeller/DsRepurchaseincomereport';
import DsproductList from './DirectSeller/productlist';
import Dsbusinessbuilding_2024 from './DirectSeller/dsbusinessbuildingclub_2024';
import Coupansdetails from './DirectSeller/dscoupondetails';

export default function Dsroutes() {

    return (<Routes>
        <Route path="Yourgroup" element={<Yourgroup dsname={localStorage.getItem("tempID")} />} />
        <Route path="userprofile" element={<DSupdateprofile />} />
        <Route path="iad" element={<DSReportawarenessincomedetail />} />
        <Route path="uploadpicture" element={<DSUploadPicture />} />
        <Route path='changepassword' element={<DSChangePassword />} />
        <Route path='monthlystate' element={<DSmonthlyStatement />} />
        <Route path='idcard' element={<DSIdCard />} />
        <Route path='Awarenesstransfer' element={<Dsawarenesstransferdetails />} />
        <Route path="userprofile" element={<Dspersonaldetail />} />
        <Route path='joiningdetail' element={<Dsjoinindetails />} />
        <Route path='personaldetails' element={<Dspersonaldetail />} />
        <Route path='communicationdetail' element={<DscommunicationDetails />} />
        <Route path='bankdetails' element={<Dsbankdetail />} />
        <Route path='repurchaseincome' element={<Dsrepurchaseincomereport />} />
        <Route path='producttraning' element={<Dsproducttraningprogram />} />
        <Route path='iwd' element={<Rptincomeawarenessdetail />} />
        <Route path='matchingpointds' element={<Dsmatchingiad />} />
        <Route path='businesstransaction' element={<Dsbusinesstransaction />} />
        <Route path='directi' element={<Dsdirectid />} />
        <Route path='directia' element={<Dsdirectawarenessincome />} />
        <Route path='yourbusiness' element={<Dsyourbusiness />} />
        <Route path='awarenessincome' element={<Dsawarenessincomedetail />} />
        <Route path='financialyear' element={<DsfinancialReport />} />
        <Route path='DsproductList' element={<DsproductList/>}/>
        <Route path='businessclub' element={<Dsbusinessbuilding />} />
        <Route path='businessclub_2024' element={<Dsbusinessbuilding_2024/>} />
        <Route path='DSDashboard' element={<DSdashboard />} />
        <Route path='Coupansdetails' element={<Coupansdetails />} />
        <Route path='/' element={<DSdashboard />} />
    </Routes>
    )
}