import { useState, useEffect, React, useReducer } from 'react';
import { initialstate, myreducer, fetchbygetmethod, fetchbypostmethod } from '../../Commonfiles/Myreducer';

function Admindownline()
{
    

      const [mydata,setmydata]=useReducer(myreducer, initialstate);
    
    
      async function loaddata()
   {
    var TempID=document.getElementById('txttempid').value;
   
    fetchbygetmethod(setmydata, 'Admindownline',{tempid:TempID});

     console.log("mydata",mydata);
   }
 
function display(){
         loaddata();
   var data=document.getElementById("tabledata");
	data.style.display='block'

  }
    return(<div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Downline</h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">Downline</h4>
                                {/* <hr width="100%"/> */}
                                <div className="row">
                                
                                
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                <div className="form-group">
                                                    <label>Enter DSID</label>
                                                    <input  id="txttempid" type="text" class="form-control" />
															    
																
                                                </div>
                                                
                                                
                                                
                                            </form>
                                        </div>
                                    </div>
                                     
                                    
                                     
                                    <div className="form-group">
                                                    <div>
                                                        
                                                        <input type="button" value="Submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:730}} onClick={display} />
                                                              
                                                             <input type="button" value="Download"
                                                            className="btn btn-default waves-effect waves-light" style={{marginLeft:5}} />
                                                            
                                                        
                                                    </div>
                                                </div>
                                    
                                    
                                </div>

                               



                                <div class="row" style={{display:'none'}} id='tabledata'>
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body" style={{overflow:'auto'}}>
                                    <div class="form-group">
															{/* <label>IP OR Login ID</label> */}
															<div>
																<div>
																 <input type="text" class="form-control" placeholder="Search Item......" aria-label="Search"  />
															</div>
															</div>
														</div>
                                                      
										{/* <h4 class="m-b-30 m-t-0">Pannel Comission</h4> */}
										 <table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
                                                    <th>Sr No</th>
												    <th>DSID</th>
												    <th>DS Name</th>
                                                    <th>Awareness Id</th>
													<th>Mobile</th>
                                                    <th>Password</th>
                                                    <th>State</th>
                                                    <th>City</th>
                                                    <th>Left Child</th>
                                                    <th>Right Child</th>
                                                    <th>Total Awareness</th>
                                                    <th>Total Downline</th>
													
                                                    
                                                
													
													
													
												</tr>
											</thead>
											<tbody>
                                            {mydata.mypost.length > 0 ? (
                                                                mydata.mypost.map((row, index) => (
											
                                              <tr>
													<td>{index+1}</td>
                                                    <td>{row.TempID}</td>
                                                    <td>{row.ApplicantName}</td>
                                                    <td>{row.awarenessid}</td>
													<td>{row.MobileNo}</td>
                                                    <td>{row.Password}</td>
                                                    <td>{row.StateName}</td>
                                                    <td>{row.CityName}</td>
                                                    <td>{row.LeftChild}</td>
                                                    <td>{row.RightChild}</td>
                                                    <td>{row.Directs}</td>
                                                    <td>{row.TotalDownline}</td>
                                                    
                                                   
                                                  
                                                   
                                                   
													
									
												</tr>
											
                                        ))
												
                                    ) : null}
											</tbody>
										
										</table> 
									</div>
								</div>
							</div>
						</div>
                                
                               
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footer"> © 2016 WebAdmin - All Rights
        Reserved. </footer>
</div>)
}

export default Admindownline;