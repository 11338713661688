import {useState,useEffect,React, useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod, fetchbypostmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
import { Gotohome } from '../Commonfiles/checkfortoken';
import jsPDF from 'jspdf'

function Depotproductlist()
{

var [mydata,setmydata]=useReducer(myreducer, initialstate);
    
   
    
        async function loaddata()
      {
    //    var x=await fetch(process.env.REACT_APP_URL+'productlistdepot');
       
    //    var y=await x.json();
    //    var z=JSON.stringify(y.recordset);
    //    var a=JSON.parse(z);
    //    setmydata(a);
    //     console.log("mydata",mydata);

		fetchbygetmethod(setmydata, 'productlistdepot', { tempid: localStorage.getItem("tempID") });
      }
     
      useEffect(()=>{loaddata();},[]);

function Downloadpdf(){

          const doc = new jsPDF();
		  doc.autoTable({ html: '#datatable-buttons' });
          doc.save('Depot-Product-List.pdf');

}

   return(
        <div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
					<div class="form-group">
															<div>
															
																<button type="submit" onClick={Downloadpdf}
																	class="btn btn-primary waves-effect waves-light">
																	Download</button>
																	
															</div>
														</div> 
						{/* <h4 class="page-title">PRODUCT LIST</h4> */}
						
					</div>
				</div>
				
				<div class="page-content-wrapper ">
					<div class="container">
						
                                     <div class="row">
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-b-30 m-t-0" style={{textAlign:'center', color:'red',fontSize:65,marginTop:5}}>JMVD Product List</h4>
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
												    <th>S.No</th> 
													<th>Product Name</th>
							                        <th>M.R.P.</th>
													<th>S.P.</th>
													<th>Store Rate</th>
													<th>Depot Rate</th>
													<th>B.V.</th>
                                                    
													
													
												</tr>
											</thead>
											<tbody>
												
											{mydata.myloading == true ? <Spinner />
											: mydata.myerror == true ? <Nodatafound />
												:
												mydata.mypost.length > 0 &&
												mydata.mypost.map((row,index)=>{
                                              
                                              return(
                                              <tr>
													<td>{index+1}</td>
													<td>{row.ItemName}</td>
                                                    <td>{row.MRP}</td>
													<td>{row.storeSellingPrice}</td>
                                                    <td>{row.storePurchasingPrice}</td>
													<td>{row.depotPurchasingPrice}</td>
													<td>{row.businessvolume}</td>
													
                                                    
												</tr>
											
                                               ) 
                                              })
												
                                                }
											</tbody>
										
										</table>
									</div>
								</div>
							</div>
						</div>
							</div>
						</div>

                      
					</div>
					{/* <Gotohome mydata={mydata} /> */}
				</div>
    )
}

export default Depotproductlist;