import { useState,useEffect ,React} from "react";
import axios from 'axios';

function Adminpaymentinfo()
{
   
    var [mydata,setmydata]=useState([]);
    

        async function loaddata()
      {
        var month=document.getElementById('txtmonth').value;
        var year=document.getElementById('txtyear').value;
        var fy=document.getElementById('fy').value;
        console.log(month,year,fy);
       var x=await fetch(process.env.REACT_APP_URL+'Adminpaymentinfo?month='+month+'&year='+year+'&financialyear='+fy);
       
       var y=await x.json();
       var z=JSON.stringify(y.recordset);
       var a=JSON.parse(z);
       setmydata(a);
        console.log("mydata",mydata);
      }
     
     // useEffect(()=>{loaddata();},[]);

function display(){
         loaddata();
   var data=document.getElementById("tabledata");
	data.style.display='block'

  }
    return(<div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Payment Info Record</h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">Monthwise Payment Info Record</h4>
                                {/* <hr width="100%"/> */}
                                <div className="row">
                                
                                 
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>Month</label>
                                                    <select class="form-control" id="txtmonth">
                                                               <option>Select</option>
															    <option value="01">January</option>
                                                                <option value="02">February</option>
                                                                <option value="03">March</option>
                                                                <option value="04">April</option>
                                                                <option value="05">May</option>
                                                                <option value="06">June</option>
                                                                <option value="07">July</option>
                                                                <option value="08">August</option>
                                                                <option value="09">September</option>
                                                                <option value="10">October</option>
                                                                <option value="11">November</option>
                                                                <option value="12">December</option>

																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Year</label>
                                                    <select class="form-control" id="txtyear">
                                                                 <option>Select</option>
															     <option>2016</option>
																<option>2017</option>
																<option>2018</option>
																<option>2019</option>
																<option>2020</option>
                                                                <option>2021</option>
                                                                <option>2022</option>
                                                                <option>2023</option>
                                                                <option>2024</option>

																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                <div className="form-group">
                                                        <label>Financial Year</label>
                                                       <select  class="form-control" id="fy">
                                                       <option value="3">2018-2019</option>
                                                       <option value="4">2019-2020</option>
                                                       <option value="5">2020-2021</option>
                                                       <option value="6">2021-2022</option>
                                                       <option value="7">2022-2023</option>
                                                       <option value="8" selected>2023-2024</option>
                                                       <option value="8">2024-2025</option>
        

                                                       </select>
                                                    </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                 <div className="form-group">
                                                    <div>
                                                        
                                                        <input type="submit"
                                                            className="btn btn-primary waves-effect waves-light" value="Submit" style={{marginLeft:730}} onClick={display} />
                                                      
                                                             <input type="submit"
                                                            className="btn btn-default waves-effect waves-light" value="Download" style={{marginLeft:5}} />
                                                           
                                                        
                                                    </div>
                                                </div> 
                                    
                                    
                                     
                                    
                                </div>

                               



                                <div class="row" style={{display:'none'}} id='tabledata'>
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
                                    <div class="form-group">
															{/* <label>IP OR Login ID</label> */}
															<div>
																<div>
																 <input type="text" class="form-control" placeholder="Search......" aria-label="Search"  />
															</div>
															</div>
														</div>
										{/* <h4 class="m-b-30 m-t-0">Pannel Comission</h4> */}
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
                                                    <th>Sr No</th>
												    <th>Holder ID</th>
												    <th>Holder Name</th>
													 <th>Invoice No.</th>
                                                    <th>Payment Code</th>
                                                    <th>Bank Details</th>
                                                    <th>Payment Details</th>
                                                    <th>Payment Date</th>
                                                    <th>Payment Mode</th>
                                                    <th>Payment Amount</th>
                                                    <th>Against Credit</th>
													
                                                    
                                                
													
													
													
												</tr>
											</thead>
											<tbody>
												{mydata.length>0 &&
											  mydata.map((row,index)=>{
                                              
                                              return(	
											
                                              <tr>
													
													
                                                    <td>{index+1}</td>
                                                    <td>{row.id}</td>
                                                    <td>{row.applicantname}</td>
                                                    <td>{row.invoiceno}</td>
                                                    <td>{row.InvoiceCode}</td>
                                                    <td>{row.bankdetail}</td>
                                                    <td>{row.pdetail}</td>
                                                    <td>{row.pdate}</td>
                                                    <td>{row.PaymentMode}</td>
                                                    <td>{row.pamount}</td>
                                                    <td>{row.againstcredit}</td>
                                                  
                                                  
                                                   
                                                   
													
									
												</tr>
											
                                             ) 
                                              })
												
                                                }
											</tbody>
										
										</table>
									</div>
								</div>
							</div>
						</div>
                                
                               
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footer"> © 2016 WebAdmin - All Rights
        Reserved. </footer>
</div>)
}

export default Adminpaymentinfo;