export function numberToWords(number) {
    var belowTwenty = [
        '', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 
        'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 
        'Eighteen', 'Nineteen'
    ];
    var tens = [
        '', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'
    ];
    var aboveThousand = [
        '', 'Thousand', 'Million', 'Billion'
    ];

    if (number === 0) return 'Zero';

    function helper(num) {
        if (num < 20) return belowTwenty[num];
        else if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? ' ' + belowTwenty[num % 10] : '');
        else return belowTwenty[Math.floor(num / 100)] + ' Hundred' + (num % 100 !== 0 ? ' ' + helper(num % 100) : '');
    }

    function convert(num) {
        let result = '';
        let i = 0;

        while (num > 0) {
            if (num % 1000 !== 0) {
                result = helper(num % 1000) + ' ' + aboveThousand[i] + ' ' + result;
            }
            num = Math.floor(num / 1000);
            i++;
        }

        return result.trim();
    }

    // Split the number into integer and decimal parts
    let parts = number.toString().split(".");
    let integerPart = parseInt(parts[0], 10);
    let decimalPart = parts[1] ? parseInt(parts[1], 10) : 0;

    let words = convert(integerPart) + " Rupees";
    
    if (decimalPart > 0) {
        words += " and " + convert(decimalPart) + " Paise";
    }

    return words;
}