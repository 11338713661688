import {useState,useEffect,React} from 'react'
function Storecompanybankdetail(props)
{
// const [data,setdata]=useState([]);

// async function loaddata()
// {
//      var a=await fetch(process.env.REACT_APP_URL+"Storepersonaldata?tempid="+props.loginstatus);
//              var b=await a.json();
//              var c=b.recordset;
//              console.log("only recordset",c);

// setdata(c);
// }	

// useEffect(()=>{loaddata()},[])


    return(
        <div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Company Bank Details</h4>
						
					</div>
				</div>
				
				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
									{/* <img src="Images/hdfcbanklogo.png" alt='HDFCLOGO' /> */} 	
									<h4 class="m-t-0 m-b-30">HDFC Bank Details</h4> 
										<hr width="100%" />
										 
										<div class="row">
										
											<div class="col-sm-6 col-xs-12">
											
												<div class="s-t-10">
													<form class="" action="#">
														<div class="form-group">
															<label>BANK NAME </label>
															 <label class="form-control">HDFC BANK</label>
																 
																 
														</div>
														<div class="form-group">
															<label>IFSC CODE</label>
															
																<label class="form-control">
																	 HDFC0001908
																</label>
															
															
														</div>
														<div class="form-group">
															<label>COMPANY NAME</label>
															<label class="form-control">
																	JMVD MULTITRADE PRIVATE LIMITED
																</label>
														</div>

														

													
														
														
													
														
														
													</form>
												</div>
											</div>
											
											<div class="col-sm-6 col-xs-12">
											
												<div class="s-t-10">
													<form class="" action="#">
														
															
													

														<div class="form-group">
															<label>ACCOUNT NO.</label>
															<label class="form-control">
																	 50200024268548
																</label>
														</div>

														<div class="form-group">
															<label>Branch</label>
                                                            <label class="form-control">
																	JANKIPURAM
																	
																</label>
															</div>

															<div class="form-group">
															<label>BANK ADDRESS</label>
                                                            <label class="form-control" style={{height:50}}>
																	B-1/294,SECTOR G,SAHARA 
																	STATES ROAD,JANKIPURAM,
																	LUCKNOW-226021,UTTAR PRADESH
																</label>
															</div>


													
														
														
													
														
														
													</form>
												</div>
											</div>
											
														
													
												</div>
										
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
    )

}
export default Storecompanybankdetail;