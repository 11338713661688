
import {useState,useEffect,React, useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
import {Gotohome} from '../Commonfiles/checkfortoken';
function Dspersonaldetails()
{ 
	
const [mydata,setmydata]=useReducer(myreducer, initialstate);

async function loaddata()
{
		
	fetchbygetmethod(setmydata, 'personaldetails', { tempid:localStorage.getItem("tempID") }); 
}	

useEffect(()=>{loaddata()},[])

    return(
        <div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Update Profile</h4>
						
					</div>
				</div>
				
				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Personal Details</h4>
										<hr width="100%" />
										{mydata.initialstage==true?<></>:  mydata.myloading == true ? <Spinner />
											: mydata.myerror == true ? <Nodatafound />
												:
												mydata.mypost.length > 0 &&
												mydata.mypost.map((row,index)=>{
											return(
										<div class="row">
											<div class="col-sm-6 col-xs-12">
											
												<div class="m-t-20">
													<form class="" action="#">
														<div class="form-group">
															<label>Applicant Name  </label>
															 <label 
															 class="form-control"
																 > {row.ApplicantName} </label>
																 
														</div>
														<div class="form-group">
															<label>Applicant DOB</label>
															
																<label class="form-control">
																	{row.ApplicantDob}
																</label>
															
															
														</div>
														<div class="form-group">
															<label>Nominee DOB</label>
															<label class="form-control">
																	{row.NomineeDob}
																</label>
														</div>
														
														
														
													
														
														
													</form>
												</div>
											</div>
											<div class="col-sm-6 col-xs-12">
												
												<div class="m-t-20">
													<form action="#">
														<div class="form-group">
															<label>Guardian Name</label>
															<div>
																<label class="form-control">
																	{row.ApplicantGuardionName}
																</label>
															</div>
														</div>
														<div class="form-group">
															<label>Nominee Name</label>
															<div>
																<label class="form-control">
																	{row.NomineeName}
																</label>
															</div>
														</div>
														<div class="form-group">
															<label>Gender</label>
															<div>
																<div>
																<input type="text" class="form-control" required
																	parsley-type="text" value={row.ApplicantGender} />
															</div>
															</div>
														</div>
														
														</form>
														
														
														
														</div>
														
														</div>
														
													
												</div>
											)
												})
										}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Gotohome mydata={mydata}/>
				</div>
    )
}

export default Dspersonaldetails;