import React,{useState,useEffect, useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
import { Gotohome } from '../Commonfiles/checkfortoken';
import { CSVLink, CSVDownload } from "react-csv";
function Storestock()
{

var [mydata,setmydata]=useReducer(myreducer, initialstate);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalBV, setTotalBV] = useState(0);

function search()
  {
	 // Declare variables
	 var input, filter, table, tr, td, i, txtValue;
	 input = document.getElementById("txtsearch");
	 filter = input.value.toUpperCase();
	 table = document.getElementById("datatable-buttons");
	 tr = table.getElementsByTagName("tr");
   
	 // Loop through all table rows, and hide those who don't match the search query
	 for (i = 0; i < tr.length; i++)
	  {
		for(var ci=2;ci<4;ci++)
		{
	   td = tr[i].getElementsByTagName("td")[ci];
	   if (td) {
		 txtValue = td.textContent || td.innerText;
		 if (txtValue.toUpperCase().indexOf(filter) > -1) {
		   tr[i].style.display = "";
		   break;
		 } else {
		   tr[i].style.display = "none";
		 }
		 }//end if
		}//end loop column
	 }//end for lopp row
 }

	async function loaddata()
  {
//    var x=await fetch(process.env.REACT_APP_URL+'Storestock?dsid='+localStorage.getItem("tempID"));
//    var y=await x.json();
//    var z=JSON.stringify(y.recordset);
//    var a=JSON.parse(z);
//    setmydata(a);
//     console.log("mydata",mydata);
	fetchbygetmethod(setmydata, 'Storestock', {  dsid: localStorage.getItem("tempID")});
  }
 
  useEffect(()=>{loaddata();},[]);

 useEffect(() => {
    let amountTotal = 0;
    let bvTotal = 0;

    mydata.mypost.forEach((row) => {
      amountTotal += row.Amount;
      bvTotal += (row.businessvolume* row.Quantity);
    });

    setTotalAmount(Number(amountTotal.toFixed(2)));
    setTotalBV(Number(bvTotal.toFixed(2)));
  }, [mydata]);



  return(
		<div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Stock</h4>
						
					</div>
				</div>
				
				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Stock</h4>
										<hr width="100%" />
										
										<div class="row">
                                        	<div class="form-group">
															<div>
															<CSVLink data={mydata.mypost} filename={'Stock-Report.csv'}>
																<button type="submit"
																	class="btn btn-primary waves-effect waves-light">
																	Download</button>
																	</CSVLink>
															</div>
														</div> 
                                                       
                                                         	
                                                          <div class="container">
                                                                      <div class="row justify-content-center mt-5">
                                                                      <div class="col-md-6">
                                                                        <form>
                                                                         <div class="input-group col-lg-12 ">
                                                                         <input type="text" class="form-control " id='txtsearch' onChange={search} placeholder="Search" aria-label="Search" />
            
                                                                       </div>
                                                                        </form>
                                                                         </div>
                                                                         </div>
                                                                        </div>
											
														
													
												</div>

       
										
										
											</div>
                                            
										</div>
									</div>
								</div>
							
							<div class="row">
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-b-30 m-t-0">Purchase</h4>
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
												    <th>Sr No.s</th>
												    <th>ApplicantId</th>
													<th>Batch No</th>
													<th>Product Name</th>
													<th>Quantity</th>
													<th>Rate</th>
													<th>Amount</th>
													<th>BV</th>
													<th>Total BV</th>
													
													
												</tr>
											</thead>
											<tbody>
												{mydata.myloading == true ? <Spinner />
													: mydata.myerror == true ? <Nodatafound />
														: mydata.mypost.length>0 &&
														mydata.mypost.map((row,index)=>{
                                              
                                              return(	
											
                                              <tr key={index}>
													<td>{index+1}</td>
													<td>{row.applicantid}</td>
                                                    <td>{row.batchno}</td>
													<td>{row.ItemName}</td>
                                                    <td>{row.Quantity}</td>
													<td>{row.sellingprice}</td>
													<td>{row.Amount}</td>
													<td>{row.businessvolume}</td>
													<td>{row.businessvolume*row.Quantity}</td>
									
												</tr>
											
                                             ) 
                                              })
												
                                                }
											</tbody>
										<tfoot>
											
											
                                              <tr>
													<td></td>
													<td></td>
                                                    <td></td>
													<td></td>
                                                    <td></td>
													<td></td>
													<td><strong>Total ={totalAmount}</strong> </td>
													<td></td>
													<td><strong>Total ={totalBV}</strong></td>
									
												</tr>
												
                                             
										</tfoot>
										</table>
									</div>
								</div>
							</div>
						</div>
							
							
							</div>
						</div>
					</div>

					<Gotohome mydata={mydata} />
				</div>
	)


  
}

export default Storestock;