import React, {useState,useEffect} from 'react'
function Dsproducttraningprogram()
{
    return(
        <div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Update Profile</h4>
						
					</div>
				</div>
				
				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">PRODUCT TRAINING PROGRAM</h4>
										<hr width="100%" />
										<div class="row">
											<div class="col-sm-6 col-xs-12">
											
												<div class="m-t-20">
													<form class="" action="#">
														<div class="form-group">
															<label>Country</label>
															 <select class="form-control"><option>India</option>
																
																
															</select>
														</div>
														<div class="form-group">
															<label>City</label>
													
															
																<select class="form-control"><option>Prayagraj</option>
																<option>Lucknow</option>
																<option>Delhi</option>
																<option>Panjab</option>
																<option>Patna</option>
															</select>
															
															
														</div>
														<div class="form-group">
															<label>Date</label>
															<div>
																<input type="date" class="form-control" required
																	parsley-type="date"  />
															</div>
														</div>
														<div class="form-group">
															<label>Time</label>
															<div>
																<input type="time" class="form-control" required
																	parsley-type="time"  />
															</div>
														</div>
														<div class="form-group">
															<label>Mobile Number</label>
															<div>
																<input type="number" class="form-control" required
																	 maxlength="10" />
															</div>
														</div>
														
														
													
														
														
													</form>
												</div>
											</div>
											<div class="col-sm-6 col-xs-12">
												
												<div class="m-t-20">
													<form action="#">
														<div class="form-group">
															<label>State</label>
															<div>
																<input type="text" class="form-control" required
																	data-parsley-minlength="6" placeholder="Min 6 chars." />
															</div>
														</div>
														<div class="form-group">
															<label>Area</label>
															<div>
																<input type="text" class="form-control" required
																	data-parsley-maxlength="6" placeholder="Max 6 chars." />
															</div>
														</div>
														<div class="form-group">
															<label>Venue</label>
															<div>
																<input type="text" class="form-control" required
																	data-parsley-length="[5,10]"
																	placeholder="Text between 5 - 10 chars length" />
															</div>
														</div>
														<div class="form-group">
															<label>Leader Name</label>
															<div>
																<input type="text" class="form-control" required
																	data-parsley-min="6" placeholder="Min value is 6" />
															</div>
														</div>
                                                        </form>
														
														
														
														
														</div>
														
														</div>
														
													
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
    )
}

export default Dsproducttraningprogram;