import { useState, useEffect, React, useReducer } from 'react';
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod, fetchbypostmethod } from '../../Commonfiles/Myreducer';



// import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

function AdminAddEditItem()
{
    const [mydata, setmydata] = useReducer(myreducer, initialstate);

    const [AdminItemcategory,setAdminItemcategory]=useReducer(myreducer, initialstate);

    const [AdminItemsubcategory,setAdminItemsubcategory]=useReducer(myreducer, initialstate);
    
    
    async function loaddata2()
  {
  
   fetchbygetmethod(setAdminItemsubcategory, 'AdminItemsubcategory',{});

    console.log("mydata",mydata);
  }
 
  
    
    
    async function loaddata1()
  {
  
   fetchbygetmethod(setAdminItemcategory, 'AdminItemcategory',{});

    console.log("mydata",mydata);
  }
 
  useEffect(()=>{loaddata1();loaddata2();},[]);

    async function Insertdata() {
		var ItemName = document.getElementById('ItemName');
		var SubCategory = document.getElementById('SubCategory');
        var Category = document.getElementById('Category');
		// var status = document.getElementById('selectstatus');
	
		var jsonobj = { ItemName: ItemName.value, SubCategoryId: SubCategory.value,CategoryId:Category.value };
		
		try {
			await fetchbypostmethod(setmydata, 'Admininsertitem', jsonobj);
			alert("Item Added Successfully");
			window.location.reload(); // Reloads the page after successful registration
		} catch (error) {
			console.error("Error during registration:", error);
		}
	}
   // setvalue(1);
    return(<div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Item </h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">Add / Edit Item</h4>
                                {/* <hr width="100%"/> */}
                                <div className="row">
                                <div className="form-group">
                                                    <div>
                                                        
                                                         
                                                         <button onClick={() => window.history.back()} type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:5}}>
                                                           Item data </button>       
                                                        
                                                    </div>
                                                </div> 
                                
                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>Item Name</label>
                                                    <input type="text" class='form-control' id='ItemName'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     {/* <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                            <div className="form-group">
                                                    <label>Video URL(Path)</label>
                                                   <input type="text" class='form-control'/>
                                                </div> 
                                               
                                                 
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div> */}
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Category Id</label>
                                                    <select class="form-control" id='Category'>

															    {AdminItemcategory.mypost.length > 0 ? (
                                                                AdminItemcategory.mypost.map((row, index) => (
															   <option value={row.CategoryId}>{row.CategoryName}</option>
                                                            ))
												
                                                        ) : null}

																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Sub Category Id</label>
                                                    <select class="form-control" id='SubCategory'>
													   
                                                    {AdminItemsubcategory.mypost.length > 0 ? (
                                                                AdminItemsubcategory.mypost.map((row, index) => (
															   <option value={row.SubCategoryId}>{row.SubCategoryName}</option>
                                                            ))
												
                                                        ) : null}

																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    
                                 <div className="form-group">
                                                    <div>
                                                        
                                                        <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:1300}} onClick={Insertdata}>
                                                            Submit</button>    
                                                        
                                                    </div>
                                                </div> 
                                    
                                     
                                    
                                </div>

                               



                                
                               
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footer"> © 2016 WebAdmin - All Rights
        Reserved. </footer>
</div>)
}



export default AdminAddEditItem;