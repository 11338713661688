import {useState,useEffect,React} from 'react'

function Brconsolidatebusiness()
{

	// let bankdetails=[{BankName:"hdfc",IFSCCode:"hdfc123456",
    //                  BranchName:'jankipuram',ChequeNo:'1233445566778899',
	// 				 AccountNo:'123456789', BankBranchState:'lucknow' }]

	// const [data,setdata]=useState([]);

	// function loaddata()
	// {
	// 	setdata(bankdetails);
	// }	

	// useEffect(()=>{loaddata()})



    return(
        <div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Awareness Transfer</h4>
						
					</div>
				</div>
				
				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Consolidate Business</h4>
										<hr width="100%" />
										
										<div class="row">
										
												<div class="col-sm-6 col-xs-12">
											
												<div class="m-t-20">
													<form class="" action="#">
														<div class="form-group">
															<label>Item Name</label>
															<select class="form-control">
															    <option>Select</option>
																 

																</select>
														</div>
														<div class="form-group">
															<label>Date From</label>
															
																<div>
																<div>
																<input id='txtdateto' type="date" class="form-control" required />
															</div>
															</div>
															
															
														</div>
														
														
														
													
														
														
													</form>
												</div>
											</div>
											<div class="col-sm-6 col-xs-12">
												
												<div class="m-t-20">
													<form action="#">
														<div class="form-group">
															<label>Panel Name</label>
															<select class="form-control">
															    <option>Select</option>
																 

																</select>
                                          
														</div>
														<div class="form-group">
															<label>Date To</label>
															<div>
																<div>
																<input id='txtdateto' type="date" class="form-control" required />
															</div>
															</div>
														</div>
														
                                                        </form>
														
														
														
														
														</div>
														
														</div>
														
														
													
												</div>
                                                <div class="form-group">
															<div>
																<button type="submit" 
																	class="btn btn-primary waves-effect waves-light">
																	Submit</button>
																<button type="reset"
																	class="btn btn-default waves-effect m-l-5">
																	Report</button>
															</div>
														</div>
													
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
    )
}

export default Brconsolidatebusiness;