import {useState,useEffect,React, useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod,fetchbyputmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
import {Gotohome} from '../Commonfiles/checkfortoken';
function DSChangePassword()
{

    
	const [mydata,setmydata]=useReducer(myreducer, initialstate);
    

   async   function loaddata()
    {

         var old=document.getElementById('old');
          var nyu=document.getElementById('nyu');
          var nyuvalid=document.getElementById('nyuvalid');

         
   
 var jsonobj={old:old.value,nyu:nyu.value,applicantid:localStorage.getItem("applicantID")};
//alert('old'+old.value+'nyu'+nyu.value+'applicantid'+localStorage.getItem("applicantID"));


       
          if(old.value=='')
         {
            alert('Enter old password');
            old.focus();
         }
         else if(nyu.value=='')
            {
               alert('Enter new password');
            }
          else  if(nyuvalid.value!==nyu.value){
                alert('Confirm Password Does Not Match.');
               // window.location.reload();
               
             }
             else  if(old.value==nyu.value){
                alert('Please Change New password.');
               // window.location.reload();
               
             }
             else
             {
                //alert("executed");
                fetchbyputmethod(setmydata, 'updatepassword',jsonobj);
             }
              // { applicantid: localStorage.getItem("applicantID"),old:old.value,nyu:nyu.value});//, month: month, year: year })
      
        
      
    }

useEffect(()=>{  
             console.log("mydata",mydata);
           
            if(mydata.initialstage==false && mydata.myloading==false && mydata.myerror==false && mydata.mypost.length>0)
           {
            
            if(mydata.mypost[0].updatestatus==1)
            {
                alert('Password Changed Successfully.');
                localStorage.clear();
                window.location.reload();
            }
            else{
                alert('Password Not Changed Successfully.Kindly Recheck Current Password');
            }
           
            
           }  
        },[mydata])


    return(<div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Change Password</h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
             
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">Change Password</h4>
                            
                                <div className="row">
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                                <div className="form-group">
                                                    <label>Old Password</label>
                                                    
                                                        <input type='text'  className="form-control" placeholder="Type in Old Password"  id='old' required /> 
                                                            
                                                    
                                                   
                                                </div>
                                                <div className="form-group">
                                                    <label>New Password</label>
                                                    
                                                       <input type='text'  className="form-control"  placeholder="Type in new Password" id='nyu' required /> 
                                                          
                                                          
                                                    
                                                </div>
                                                <div className="form-group">
                                                    <label>Confirm Password</label>
                                                    <div className="m-t-10">
                                                        <input type="password" className="form-control" id='nyuvalid' required
                                                            data-parsley-equalto="#pass2"
                                                            placeholder="Re-Type Password" />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div>
                                                        <button 
                                                            className="btn btn-primary waves-effect waves-light" onClick={loaddata}>
                                                            Submit</button>
                                                        <button 
                                                            className="btn btn-default waves-effect m-l-5">
                                                            Cancel</button>
                                                    </div>
                                                </div>
                                               
                                            </form>
                                        </div>
                                    </div>
                                    
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                			 
					 
            </div>
        </div>
    </div>
    {/* <Gotohome mydata={mydata}/> */}
</div>)
}

export default DSChangePassword;