import { useState, useEffect, React, useReducer } from 'react';
import { initialstate, myreducer, fetchbygetmethod, fetchbypostmethod } from '../../Commonfiles/Myreducer';

function Directseller()
{

    const [mydata, setmydata] = useReducer(myreducer, initialstate);
    
    
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 25;
    const [filter, setFilter] = useState(''); 
    const totalItems = mydata.mypost.length;

    // Calculate total pages
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    // Calculate the current items to display
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    


    const filteredData = mydata.mypost.filter((row) => {
        const searchValue = filter.toLowerCase();
        return (
            row.TempID.toLowerCase().includes(searchValue) ||
            row.ApplicantName.toLowerCase().includes(searchValue)
        );
    });

    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    


    // Pagination state
    const [startPage, setStartPage] = useState(1);
    const [endPage, setEndPage] = useState(5);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        
        // Update the range of pages displayed
        if (pageNumber === endPage) {
            // If the last page in the current range is clicked, shift the range by 5
            if (endPage < totalPages) {
                setStartPage(endPage + 1);
                setEndPage(Math.min(endPage + 5, totalPages));
            }
        } else if (pageNumber === startPage && startPage > 1) {
            // If the first page in the current range is clicked, shift the range back
            setStartPage(startPage - 5);
            setEndPage(startPage - 1);
        }
    }
    async function loaddata() {
       
        await fetchbygetmethod(setmydata, 'AdminDirectseller', {});
        console.log(setmydata);
    }



    


      useEffect(()=>{loaddata();},[]);
    
    
    
    
      return(
            <div class="content-page">
                <div class="content">
                    <div class="">
                        <div class="page-header-title">
                            <h4 class="page-title">Direct Seller</h4>
                            
                        </div>
                    </div>
                    
                    <div class="page-content-wrapper ">
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="panel panel-primary">
                                        <div class="panel-body">
                                            <h4 class="m-t-0 m-b-30">Direct Seller</h4>
                                            <hr width="100%" />
                                            
                                            <div class="row">
                                               <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                <div className="form-group">
                                                    <label>Direct Seller ID or Direct Seller Name</label>
                                                    <input type="text" placeholder="Enter Ds Id Or Ds Name"  value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                     class="form-control" />
															
																 

															
                                                </div>
                                                
                                              <div className="form-group">
                                                    <div>
                                                        
                                                        <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:5}}>
                                                            Submit</button>  
                                                             
                                                    </div>
                                                </div>  
                                                
                                            </form>
                                        </div>
                                    </div>
                                                
                                                           
                                                                
                                                              
                                                        
                                                    </div>
    
                                                 {/* <div class="row">
                                <div class="col-md-12">
                                    <div class="panel panel-primary">
                                        <div class="panel-body"> 
                                            <h4 class="m-b-30 m-t-0">Pannel Comission</h4>
                                            <table id="datatable-buttons"
                                                class="table table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Sr No.</th>
                                                        <th>DS ID</th>
                                                        <th>Applicant Name</th>
                                                        <th>Password</th>
                                                        <th>Is Approved</th>
                                                        <th>Sponsor Name</th>
                                                        <th>Awareness</th>
                                                        <th>Profile</th>
                                                        <th>Group</th>
                                                        <th>DS Business</th>
                                                        
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {mydata.mypost.length>0 &&
                                                  mydata.mypost.map((row,index)=>{
                                                  
                                                  return(	
                                                
                                                  <tr>
                                                        <td>{index+1}</td>
                                                         <td>{row.TempID}</td>
                                                        <td>{row.ApplicantName}</td>
                                                        <td>{row.Password}</td>
                                                        <td>{row.IsApproved}</td>
                                                        <td>{row.SponsorName}</td>
                                                        <td>{row.Awareness}</td>
                                                        <td><input type="button" value="Edit" className="btn btn-primary waves-effect waves-light" /></td>
                                                        <td><input type="button" value="Edit" className="btn btn-primary waves-effect waves-light" /></td>
                                                        
                                                        
                                                        <td><input type="button" value="Edit" className="btn btn-primary waves-effect waves-light" /> </td>
                                                       
                                                        
                                        
                                                    </tr>
                                                
                                                 ) 
                                                  })
                                                    
                                                    }
                                                </tbody>
                                            
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                             */}
                                            
                                            <div className="row">
            <div className="col-md-12">
                <div className="panel panel-primary">
                    <div className="panel-body">
                        <table id="datatable-buttons" className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr No.</th>
                                    <th>DS ID</th>
                                    <th>Applicant Name</th>
                                    <th>Password</th>
                                    <th>Is Approved</th>
                                    <th>Sponsor Name</th>
                                    <th>Awareness</th>
                                    <th>Profile</th>
                                    <th>Group</th>
                                    <th>DS Business</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((row, index) => (
                                    <tr key={row.TempID}>
                                        <td>{indexOfFirstItem + index + 1}</td>
                                        <td>{row.TempID}</td>
                                        <td>{row.ApplicantName}</td>
                                        <td>{row.Password}</td>
                                        <td>{row.IsApproved ? 'Yes' : 'No'}</td>
                                        <td>{row.SponsorName}</td>
                                        <td>{row.Awareness}</td>
                                        <td><input type="button" value="Edit" className="btn btn-primary waves-effect waves-light" /></td>
                                        <td><input type="button" value="Edit" className="btn btn-primary waves-effect waves-light" /></td>
                                        <td><input type="button" value="Edit" className="btn btn-primary waves-effect waves-light" /></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {/* Pagination Controls */}
                        <div className="pagination">
                            {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                                <button 
                                    key={startPage + index} 
                                    onClick={() => handlePageChange(startPage + index)}
                                    disabled={startPage + index > totalPages}
                                >
                                    {startPage + index}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                
                                
                                
                            
                                
                                </div>
                            </div>
                        </div>
    
    
                    </div>
        )
    
    
      
    }

export default Directseller;