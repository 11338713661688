// src/RegistrationForm.jsx
import React from 'react';

import './registrationform.css';
import { useState, useEffect } from "react";

const StoreRegistrationForm = () => {

  const [mydata,setmydata]=useState(null);
  const [getcountry,setgetcountry]=useState(null);
  const [getcity,setgetcity]=useState(null);
  const [getstate,setgetstate]=useState(null);
  const [selectedStateId, setSelectedStateId] = useState(null);
  
  const[jsonregistration,setjsonregistration]= useState(null);

  async function Getcountryname() {
    await fetch(process.env.REACT_APP_URL + "getcountryname", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then((response) => response.json())
        .then((data) => {
            setgetcountry(data.recordset);
        })
        .catch((err) => {
            console.log("error", err);
        });
}



async function Getstatesname() {
await fetch(process.env.REACT_APP_URL + "getstatesname", {
    method: "GET",
    headers: {
        "Content-Type": "application/json",
    }
})
    .then((response) => response.json())
    .then((data) => {
        setgetstate(data.recordset);
    })
    .catch((err) => {
        console.log("error", err);
    });
}

async function Getcityname(stateid) {
if (!stateid) {
    console.log("Invalid state ID:", stateid);
    return; // Don't make the request if the stateid is invalid
}

await fetch(process.env.REACT_APP_URL + "getcityname?stateid=" + stateid, {
    method: "GET",
    headers: {
        "Content-Type": "application/json",
    }
})
    .then((response) => response.json())
    .then((data) => {
        setgetcity(data.recordset);
    })
    .catch((err) => {
        console.log("error", err);
    });

//alert("Selected State ID: " + stateid);
}

  function createdatafordatabase (e) {
    e.preventDefault(); // Prevent the default form submission
    const dsregistration = {
      "ApplicantName": document.getElementById('Applicantname').value,
      "ApplicantGuardionName": document.getElementById('ApplicantGuardionName').value,
      "ApplicantGender": document.getElementById("ApplicantGender").value,
      "Password": document.getElementById("Password").value,
      "NomineeName": document.getElementById("NomineeName").value,
      "ApplicantDob": document.getElementById("ApplicantDob").value,
      "NomineeDob": document.getElementById("NomineeDob").value,
      "Status": document.getElementById("Status").value,
      "MobileNo": document.getElementById("Mobileno").value,
      "Email": document.getElementById("Email").value,
      "CityId": document.getElementById("city").value,
      "CountryId": document.getElementById("Country").value,
      "stateid": document.getElementById("states").value
      //"Registrationdetail": jsonregistration
    };
    console.log(dsregistration);
    setjsonregistration(dsregistration);
  }; 



   
  async function Dsregisterdata() {
  
    var Password= document.getElementById("Password");
    var NewPassword= document.getElementById("Passwordnew");
   
  
    
    // var textApplicantName=document.getElementById('Applicantname').value;
  
    // if(textApplicantName.length === 0){
    //   alert("Fill Applicant Name.");
    //   return;
    // }
    var requiredFields = document.querySelectorAll('#myForm input[required], #myForm select[required]');
    var allFilled = true;

    requiredFields.forEach(function(field) {
        if (field.value.trim() === '' || field.value === 'Select Country') {
            alert(`Please fill in the ${field.placeholder || field.id} field.`);
            field.focus(); // Focus on the empty field
            allFilled = false;
            return; // Exit the loop on the first empty field
        }
    });

    if (!allFilled) {
        return; // Stop further execution if any required field is empty
    }

  
  var mobileNo = document.getElementById('Mobileno').value;
  
  // Ensure the number is exactly 10 digits long
  if (mobileNo.length !== 10) {
    alert("Mobile number must be exactly 10 digits.");
    document.getElementById('Mobileno').focus();
    return;
  }
  
     if(Password.value!=NewPassword.value)
    {
      alert('Confirm Password Does Not Match.');
      return;
    }
      
    else
    await fetch(process.env.REACT_APP_URL + "Storeregistration", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            // 'authorization': `Bearer ${token}`
        },
        body: JSON.stringify(jsonregistration) 
    })
    .then((response) => response.json())
    .then((data) => {
      //alert(data);
  
      //console.log("data",data);
  
        setmydata(data.recordset); 
        //alert(setmydata);
        console.log("Registration successful:", data); 
    })
    .catch((err) => {
        console.log("Registration error:", err); 
    });
    window.location.reload();
  }


useEffect(()=>{ if(jsonregistration!=null) Dsregisterdata()},[jsonregistration]);

useEffect(()=>{console.log("mydata",mydata);
  if(mydata!=null)
    {


      
       alert("Registration is successful. Your Store ID is("+ mydata[0].Newtempid+").");

}

},[mydata])

useEffect(() => {
  Getcountryname(); // Call the function here
}, []);

useEffect(() => {
Getstatesname(); // Call the function here
}, []);

useEffect(() => {
Getcityname(); // Call the function here
}, []);

  return (
    <div className="form-container">
      <form className="registration-form" id='myForm'>
        <h2>STORE REGISTRATION</h2>
        
        <div className="form-row">
        <input type="text"  placeholder=" Name" id='Applicantname' required/> 
        <input type="text" placeholder="Father/Husband Name" id='ApplicantGuardionName' required />
        </div>
        
        <div className="form-row">
          <input type="date" placeholder="Applicant DOB" id='ApplicantDob' required />
          <input type="text" placeholder="Nominee Name" id='NomineeName' required />
        </div>

      

        <div className="form-row">
         
          <input type="number" placeholder="Mobile No" id='Mobileno' required />
          <input type="date" placeholder="Nominee DOB" id='NomineeDob' required />
        </div>

        {/* <div className="form-row">
          <select>
            <option value="" disabled selected>Applicant Gender</option>
            <option value="1">Male</option>
            <option value="2">Female</option>
          </select>
        </div> */}
        
        {/* <div className="form-row">
          <input type="text" placeholder="Email" />
          <input type="text" placeholder="Nominee Name" />
        </div> */}
        
        <div className="form-row">
          <select id='ApplicantGender' required>
            <option value="M">Male</option>
            <option value="F">Female</option>
            <option value="O">Other</option>
          </select>
          <select id='Status'>
            <option value="Y">UNBlock</option>
            <option value="N">Block</option>
            
          </select>
          {/* <input type="date" placeholder="Nominee DOB" /> */}
        </div>
        
        <div className="form-row">
          {/* <input type="text" placeholder="Contact No" /> */}
          <input type="email" placeholder="Email" id='Email' required />
          <select id='Country' required>
                <option>Select Country</option>
                {getcountry && getcountry.map((country, index) => (
                    <option key={index} value={country.Countryid}>
                        {country.CountryName}
                    </option>
                ))}
            </select>
        </div>

        <div className="form-row">
        <select required
                id='states' 
                onChange={(e) => {
                    const selectedId = e.target.value;
                    setSelectedStateId(selectedId);  // Update the selected StateId
                    Getcityname(selectedId);         // Fetch cities for the selected StateId
                }}
            >
                <option value="">Select State</option>
                {getstate && getstate.map((state, index) => (
                    <option key={index} value={state.StateId}>
                        {state.StateName}
                    </option>
                ))}
            </select>

            <select id='city' required>
                <option value="">Select City</option>
                {Array.isArray(getcity) && getcity.map((city, index) => (
                    <option key={index} value={city.CityId}>
                        {city.CityName}
                    </option>
                ))}
            </select>
        </div>

        <div className="form-row">
          <input type="password" placeholder="Password" id='Password' required  />
          <input type="password" placeholder="Confirm Password" id='Passwordnew' required />
        </div>

        {/* <div className="form-row">
          <input type="checkbox" id="terms" />
          <label htmlFor="terms" style={{marginRight:602}}>I agree with the <a href="#">Terms and Conditions</a></label>
        </div> */}

        <div className="form-row">
          <button type="submit" onClick={(e=>{createdatafordatabase(e)})} >Submit</button>
        </div>

        {/* <p>Already have an account? <a href="#">Sign In</a></p> */}
      </form>
    </div>
  );
};

export default StoreRegistrationForm;
