import { useNavigate } from 'react-router-dom';
export function Gotohome({mydata}) {
    console.log("gotohome",mydata);
    const navigate = useNavigate();
    // localStorage.clear();
    // navigate('/');
    //  window.location.reload(); 
   if(mydata.mypost!=null && mydata.mypost != undefined)
    {
        if (mydata.mypost.length>0 ) {
            if(mydata.mypost[0].tokenstatus === 'go home') 
            {
             localStorage.clear();
             navigate('/');
             window.location.reload(); 
            } 
         }
    }
   
}