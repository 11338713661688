import React, {useState,useEffect, useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
import {Gotohome} from '../Commonfiles/checkfortoken';
function Dsrepurchaseincomereport()
{

    const [mydata,setmydata]=useReducer(myreducer, initialstate);

async function loaddata()
{
   

             fetchbygetmethod(setmydata, 'repurchaseincome', { tempid:localStorage.getItem("tempID") }); 
}	

useEffect(()=>{loaddata()},[])
    
return(
    <div class="content-page">
        <div class="content">
            <div class="">
                <div class="page-header-title">
                    <h4 class="page-title">Repurchase Income Report </h4>
                    
                </div>
            </div>
            
            <div class="page-content-wrapper ">
                <div class="container">
                
										  
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="panel panel-primary">
                                <div class="panel-body">
                                    <h4 class="m-t-0 m-b-30">Repurchase Income Report for Financial Year 2022-23</h4>
                                    <hr width="100%" />
                                    
                                    <div class="row">
											<div class="col-sm-6 col-xs-12">
											
												<div class="m-t-20">
													<form class="" action="#">
														<div class="form-group">
															<label>Enter Your DS ID</label>
															 <label 
															 class="form-control"
																 ></label>
														</div>
														
														
                                                        
                                                        
														
														
													
														
														
													</form>
												</div>
											</div>
											
														
													
												</div>
                            
                                        <div class="form-group">
                                                        <div>
                                                            <button type="submit"
                                                                class="btn btn-primary waves-effect waves-light">
                                                                Submit</button>
                                                           
                                                        </div>
                                                    </div>
                                            
                                    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                        <div class="row">
                        <div class="col-md-12">
                            <div class="panel panel-primary">
                                <div class="panel-body">
                                    <h4 class="m-b-30 m-t-0">Item Details </h4>
                                    <table id="datatable-buttons"
                                        class="table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th>S No.</th>
                                                <th>DS ID</th>
                                                <th>Applicant Name</th>
                                                <th>Applicant id</th>
                                                <th>Repuchase Income</th>
                                                <th>Net Commission</th>
                                                <th>Month</th>
                                                <th>Year</th>
                                                
                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            
                                        {mydata.initialstage==true?<></>:  mydata.myloading == true ? <Spinner />
											: mydata.myerror == true ? <Nodatafound />
												:
												mydata.mypost.length > 0 &&
												mydata.mypost.map((row,index)=>{
											return(
                                          <tr>
                                                <td>{index+1}</td>
                                                <td>{row.tempid}</td>
                                                <td>{row.applicantname}</td>
                                                <td>{row.dsid}</td>
                                                <td>{row.RepurchaseIncome}</td>
                                                <td>{row.NetCommission}</td>
                                                <td>{row.month}</td>
                                                <td>{row.year}</td>
                                               
                                                
                                                
                                            </tr>
                                           )
                                        })
                  }
                                            
                                         
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                   
                
                
                 </div>
                                            
                                                                     
                        </div>
                    </div>
                </div>

                <Gotohome mydata={mydata}/>
            </div>
)


}

export default Dsrepurchaseincomereport;