import { useState, useEffect, React, useReducer } from 'react';
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod, fetchbypostmethod } from '../../Commonfiles/Myreducer';


function AdminAddEditUnit()
{
    const [mydata, setmydata] = useReducer(myreducer, initialstate);

    async function Insertdata() {
		var UnitName = document.getElementById('UnitName');
		var Description = document.getElementById('Description');
		var status = document.getElementById('selectstatus');
	
		var jsonobj = { UnitName: UnitName.value, UnitDescription: Description.value,Status: status.value };
		
		try {
			await fetchbypostmethod(setmydata, 'Admininsertunit', jsonobj);
			alert("Unit Added Successfully");
			window.location.reload(); // Reloads the page after successful registration
		} catch (error) {
			console.error("Error during registration:", error);
		}
	}
    return(<div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title"> Unit </h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">Add / Edit Unit</h4>
                                {/* <hr width="100%"/> */}
                                <div className="row">
                                <div className="form-group">
                                                    <div>
                                                        
                                                         
                                                         <button onClick={() => window.history.back()} type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:5}}>
                                                           Units data </button>       
                                                        
                                                    </div>
                                                </div> 
                                
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>Unit Name</label>
                                                    <input type="text" class='form-control' id='UnitName'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label> Description</label>
                                                   <input type="text" class='form-control' id='Description'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Status</label>
                                                    <select class="form-control" id='selectstatus'>
                                                                <option value='Y'>Unblock</option>
															     <option value='N'>Block</option>
																
																

																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    
                                 <div className="form-group">
                                                    <div>
                                                        
                                                        <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:1300}} onClick={Insertdata}>
                                                            Submit</button>    
                                                        
                                                    </div>
                                                </div> 
                                    
                                     
                                    
                                </div>

                               



                                
                               
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footer"> © 2016 WebAdmin - All Rights
        Reserved. </footer>
</div>)
}



export default AdminAddEditUnit;