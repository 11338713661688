import { useState, useEffect, React, useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod, fetchbypostmethod } from '../../Commonfiles/Myreducer';
//import { use } from '../adminapi/adminapilist';

function Admindamageitem()
{

const [myitemdetail, setmyitemdetail] = useReducer(myreducer, initialstate);
const [mydata, setmydata] = useReducer(myreducer, initialstate);
const [checkedrowslist,setcheckedrowslist]=useState({});
const [item,setitem]=useState([]);
const [jsonitemdata,setjsonitemdata]=useState([]);
const [jsondamageitems, setjsondamageitems] = useState(false);
const [jsondamagereason, setdamagereason] = useState(false);

   async function Getitemdetail() {

        fetchbygetmethod(setmyitemdetail, 'Admingetcurrentquantity', {});

        console.log("myitem", myitemdetail);
    }

	function handlecheckbox(index)
	{
	   setcheckedrowslist((previousindex)=>({...previousindex,[index]:!previousindex[index]}));
	   
	}

	function handleInputChange(e, index, column) {
		const newitem = [...item];
		newitem[index][column] = e.target.value; // Update the quantity field
		setitem(newitem); // Update the state
	}
	

function handleaddtojsonitem()
{
         const selecteditems=item.filter((row,index)=>checkedrowslist[index]);
        // const orderno=(document.getElementById('txtpocode').textContent);
          setjsonitemdata(selecteditems.map(({ProductId,itemname,StandardPrice,batchno,AvailableQuantity,Quantity})=>({ProductId,itemname,StandardPrice,batchno,AvailableQuantity,Quantity:parseInt(Quantity)})));
        // console.log("jsondata",jsonitemdata);

        alert("Item Added Successfully");

}

function savetodatabase(damagereason)
{
   

    fetchbypostmethod(setmydata,'Admindamageproductinsert', {jsondata:damagereason});
            alert("Damage Product Added Successfully");
			window.location.reload(); // Reloads the page after successful registration
}


function savedata() {

	//console.log("jsondamageitems in reason"+JSON.stringify(jsondamageitems));
        var damagereason = {
			'financialyear':parseInt(process.env.REACT_APP_FINANCIAL_YEAR),
			'gst': "Y",
			'tdate': document.getElementById('date').value,
			 'towhom': document.getElementById('towhom').value,
		
		"diitem": jsonitemdata
	}
  
    console.log("damagereasonnew",damagereason);

   // setdamagereason(damagereason)
	// console.log( "FromApplicantId",fromApplicantId,
	//     "ApplicantId", applicantId);
    savetodatabase(damagereason);
}
useEffect(()=>{console.log("damagereason",jsondamagereason)},[jsondamagereason])



useEffect(()=>{console.log("checkedrowslist",checkedrowslist)},[checkedrowslist]);


	async function displaytable() {

		Getitemdetail();

        document.getElementById("tabledisplay").style.display = "block";
    }

	useEffect(() => {
        //loaddata();
        Getitemdetail();
       
    }, []);

    useEffect(()=>{
        if(myitemdetail.mypost.length>0)
			 setitem(myitemdetail.mypost.map(row=>({
            ...row,
			ProductId: row.ProductId || 0,
			itemname: row.itemname || '',
            StandardPrice: row.StandardPrice ||0,
			batchno:row.batchno ||'',
			AvailableQuantity: row.AvailableQuantity || 0,
           // Quantity:document.getElementById('Quantity').value || 0
        }
          
        )))
        console.log("myitemdetail",myitemdetail);
    }
        
       ,[myitemdetail]);
       useEffect(()=>{
       
        console.log("itemnew",item);
    }
        
       ,[item]);

    useEffect(()=>{ console.log("jsondata",jsonitemdata);},[jsonitemdata])   ;


    return(
        <div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Damaged Items Details</h4>
						
					</div>
				</div>
				
				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Damaged Items Details</h4>
										<hr width="100%" />
										
										<div class="row">
										
											<div class="col-sm-6 col-xs-12">
											
												<div class="m-t-20">
													<form class="" action="#">
														<div class="form-group">
															<label>Date</label>
															 
															 <input type='date' class="form-control"  id='date'/>
															
																 
																 
														</div>
														

														
														
														
														
													
														
														
													</form>
												</div>
											</div>
											<div class="col-sm-6 col-xs-12">
												
												<div class="m-t-20">
													<form action="#">
													
														
														<div class="form-group">
															<label>To</label>
															<div>
                                                           
															<select class="form-control" id='towhom'>
                                                            <option>--Select--</option>
                                                            <option value="Sangeeta">Sangeeta Srivastava</option>
                                                            <option value="Divyanshi">Divyanshi Srivastava</option>
                                                            <option value="Priyank">Priyank Srivastava</option>
                                                            <option value="Mansi">Mansi Srivastava</option>
                                                            <option value="Sultanpur">Sultanpur</option>
                                                            <option value="BP">Business Promotion</option>
                                                            <option value="Damage">Damaged</option>
                                                            <option value="Expiry">Expired</option>
                                                            </select>
																	
																
															</div>
														</div>
														
                                      
														
														</form>
														
														
														
														</div>
														
														</div>
														
													
												</div>
                                                <div className="form-group">
                                                    <div>
													<button type="submit"
                                                    className="btn btn-primary waves-effect waves-light" style={{ marginLeft: 10 }} onClick={displaytable}>
                                                    Show Items</button>
                                             
                                                    <button type="submit"
                                                                className="btn btn-primary waves-effect waves-light" style={{ marginLeft: 5 }} onClick={handleaddtojsonitem} >
                                                                Add Items</button>
                                                        <button type="reset"
                                                            className="btn btn-primary waves-effect m-l-5" style={{marginLeft:742.3}} onClick={ savedata}>
                                                            Submit</button>
                                                    </div>
                                                </div>

											</div>

											<div class="row" id="tabledisplay" style={{ display: 'none' }}>
                                        <div class="col-md-12">
                                            <div class="panel panel-primary">
                                                <div class="panel-body">
                                                    <div class="form-group">
                                                        {/* <label>IP OR Login ID</label> */}
                                                        <div>
                                                            <div>
                                                                <input type="text" class="form-control" placeholder="Search Item......" aria-label="Search" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <h4 class="m-b-30 m-t-0">Pannel Comission</h4> */}
                                                    <table id="datatable-buttons"
                                                        class="table table-striped table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Select</th>
                                                                <th>Item Id</th>
                                                                <th>Item Name</th>
                                                                <th>Rate</th>
                                                                <th>Batch No</th>
                                                                <th> Quantity</th>
																<th>Damage Quantity</th>




                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {item.length > 0 ? (
                                                                item.map((row, index) => (

                                                                    <tr>
                                                                        <td><input type="checkbox" style={{ marginLeft: 21 }}  onChange={()=>handlecheckbox(index)}  /></td>
                                                                        <td>{row.ProductId}</td>
                                                                        <td>{row.itemname}</td>
                                                                        <td>{row.StandardPrice}</td>
																		<td>{row.batchno}</td>
                                                                        <td>{row.AvailableQuantity}</td>
                                                                        
                                                                        <td><input type="text"  onChange={(e)=>handleInputChange(e,index,'Quantity')} /></td>





                                                                    </tr>
                                                                ))

                                                            ) : null}
                                                        </tbody>

                                                    </table>
                                                  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                            
										</div>
									</div>
                                    
								</div>
                                
							</div>
                            
						</div>
                        
					</div>
				</div>
    )

}
export default Admindamageitem;