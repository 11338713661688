import React,{ useState, useEffect} from "react";
import {useNavigate } from 'react-router-dom';

function AdminTopBar(props)
{
    const [value,setvalue]=useState(0);
    const [mydata,setdata]=useState([]);
    const navtologin=useNavigate();

    function setlogout()
    {
        localStorage.setItem("tempID",'');
			localStorage.setItem("applicantID",'');
			localStorage.setItem("applicantType",'');
			localStorage.setItem("islogin",false);
            localStorage.setItem("isadminlogin",null);
        navtologin('/');
        window.location.reload();
    }
     function setlogout()
    {
        localStorage.clear();
        navtologin('/');
        window.location.reload();
    }
    const imagefromdb="https://jmvdnet.jmvdbusiness.com/DirectSeller/DSImages/";
    const imagedefault="/assets/images/users/avatar-1.jpg";
    let z;
    async function topbarloaddata()
    {
        var x = await fetch(process.env.REACT_APP_URL+"profileinformation?tempid="+localStorage.getItem("tempID"));
        console.log("topbarid: "+localStorage.getItem("tempID"));
        console.log("URL: "+x);
        var y = await x.json();
        z = await y.recordset;
        console.log('dataprofiletop',z );
        setdata(z);
        console.log('dataprofileinfotop',mydata );
        //return z;
        
    // axios.post(process.env.REACT_APP_URL+"pd?id=20")
    //      .then((mydata)=>{console.log("data1"+mydata.data)})
    //      .catch(error => console.log(error));
    
        
    }
    useEffect(()=>{
      
        topbarloaddata();
        
           
    },[])

    return(<div className="topbar">
    <div className="topbar-left">
        <div className="text-center">
            <a href="index.html" className="logo"><span>ADMIN</span>Profile</a> <a
                href="index.html" className="logo-sm"><span>S</span></a>
        </div>
    </div>
    <div className="navbar navbar-default" role="navigation">
        <div className="container">
            <div className="">
                <div className="pull-left">
                    <button type="button"
                        className="button-menu-mobile open-left waves-effect waves-light">
                        <i className="ion-navicon"></i>
                    </button>
                    <span className="clearfix"></span>
                </div>
                <form className="navbar-form pull-left" role="search">
                    <div className="form-group">
                        <input type="text" className="form-control search-bar"
                            placeholder="Search..."/>
                                
                               
                    </div>
                    <button type="submit" className="btn btn-search">
                        <i className="fa fa-search"></i>
                    </button>
                </form>
                <ul className="nav navbar-nav navbar-right pull-right">
                    <li className="dropdown hidden-xs"><a href="#" data-target="#"
                        className="dropdown-toggle waves-effect waves-light notification-icon-box"
                        data-toggle="dropdown" aria-expanded="true"> <i
                            className="fa fa-bell"></i> <span
                            className="badge badge-xs badge-danger"></span>
                    </a>
                    <ul className="dropdown-menu dropdown-menu-lg">
                            <li className="text-center notifi-title">Notification <span
                                className="badge badge-xs badge-success">3</span></li>
                            <li className="list-group"><a href="javascript:void(0);"
                                className="list-group-item"><div className="media">
                                        <div className="media-heading">Your order is placed</div>
                                        <p className="m-0">
                                            <small>Dummy text of the printing and typesetting
                                                industry.</small>
                                        </p>
                                    </div> </a> <a href="javascript:void(0);" className="list-group-item"><div
                                        className="media">
                                        <div className="media-body clearfix">
                                            <div className="media-heading">New Message received</div>
                                            <p className="m-0">
                                                <small>You have 87 unread messages</small>
                                            </p>
                                        </div>
                                    </div> </a> <a href="javascript:void(0);" className="list-group-item"><div
                                        className="media">
                                        <div className="media-body clearfix">
                                            <div className="media-heading">Your item is shipped.</div>
                                            <p className="m-0">
                                                <small>It is a long established fact that a reader
                                                    will</small>
                                            </p>
                                        </div>
                                    </div> </a> <a href="javascript:void(0);" className="list-group-item">
                                    <small className="text-primary">See all notifications</small>
                            </a></li>
                        </ul></li>
                    <li className="hidden-xs"><a href="#" id="btn-fullscreen"
                        className="waves-effect waves-light notification-icon-box"><i
                            className="mdi mdi-fullscreen"></i></a></li>
                    <li className="dropdown"><a href="#"
                        className="dropdown-toggle profile waves-effect waves-light"
                        data-toggle="dropdown" aria-expanded="true"> <img
                            src={mydata!=null && mydata.length>0 ? imagefromdb+mydata[0].ApplicantPhoto:process.env.PUBLIC_URL+imagedefault} alt="user-img"
                            className="img-circle"/> <span className="profile-username">
                                {mydata!=null  && mydata.length>0 ? mydata[0].ApplicantName:''}<br /> <small>{(mydata!=null && mydata.length>0)  && mydata[0].Achiver} </small><strong>{(mydata!=null && mydata.length>0) && mydata[0].TempID}</strong>
                        </span>
                    </a>
                    <ul className="dropdown-menu">
                            <li><a href="javascript:void(0)"> Profile</a></li>
                            <li><a href="javascript:void(0)"><span
                                    className="badge badge-success pull-right">5</span> Settings </a></li>
                            <li><a href="javascript:void(0)"> Lock screen</a></li>
                            <li className="divider"></li>
                            <li onClick={()=>{ setlogout(); }}><a href=""> Logout</a></li>
                        </ul></li>
                </ul>
            </div>
        </div>
    </div>
</div>)
}

export default AdminTopBar;

