import {useState,useEffect,React, useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod, fetchbypostmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
import { Gotohome } from '../Commonfiles/checkfortoken';
import axios from 'axios';

function Brbankdetail()
{

	const imagedefault = "/assets/images/users/avatar-1.jpg";
	const [data, setdata] = useReducer(myreducer, initialstate);
	const [myimgupload, setmyimgupload] = useReducer(myreducer, initialstate);



	//photo section

	const [filecheque, setfilecheque] = useState(null);
	const [filechequeimage, setfilechequeimage] = useState(null);

	const handleFileChangeforcheque = (event) => {
		const imgfile = event.target.files[0];

		if (imgfile) {


			const reader = new FileReader();
			reader.onloadend = () => {

				setfilechequeimage(reader.result);
				setfilecheque(imgfile);

			};
			reader.readAsDataURL(imgfile);

		}
	};

	const onSubmitcheque = async (e) => {

		e.preventDefault();

		if (!filecheque) {

			console.error('No file selected.');
			return;
		}
		const imagename = localStorage.getItem('tempID') + '_cheque';
		// Create a new file with the new name
		const newFileName = `${imagename}.${filecheque.name.split('.').pop()}`; // e.g., dsid.jpg
		const newFile = new File([filecheque], newFileName, { type: filecheque.type });

		const formData = new FormData();

		formData.append('file', newFile);


		try {
			const res = await axios.post(process.env.REACT_APP_URL + 'upload', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});

			const { fileName } = res.data;

			console.log('fileName', fileName);

			fetchbypostmethod(setmyimgupload, 'dsuploadcheque', { fileName: fileName, applicantid: localStorage.getItem('applicantID') });

			alert("Image Uploaded!!");
		} catch (err) {
			console.error('ret', err);
		}
	};

	const [filepan, setfilepan] = useState(null);
	const [filepanimage, setfilepanimage] = useState(null);
	const handleFileChangeforpan = (event) => {
		const imgfile = event.target.files[0];

		if (imgfile) {


			const reader = new FileReader();
			reader.onloadend = () => {

				setfilepanimage(reader.result);
				setfilepan(imgfile);

			};
			reader.readAsDataURL(imgfile);

		}
	};

	const onSubmitpan = async (e) => {

		e.preventDefault();

		if (!filepan) {

			console.error('No file selected.');
			return;
		}
		const imagename = localStorage.getItem('tempID') + '_pan';
		// Create a new file with the new name
		const newFileName = `${imagename}.${filepan.name.split('.').pop()}`; // e.g., dsid.jpg
		const newFile = new File([filepan], newFileName, { type: filepan.type });

		const formData = new FormData();

		formData.append('file', newFile);

		try {
			const res = await axios.post(process.env.REACT_APP_URL + 'upload', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});

			const { fileName } = res.data;

			console.log('fileName', fileName);

			fetchbypostmethod(setmyimgupload, 'dsuploadpan', { fileName: fileName, applicantid: localStorage.getItem('applicantID') });
			alert("Image Uploaded!!");

		} catch (err) {
			alert("err" + err);
			console.error('ret', err);
		}
	};

	async function loaddata()
	{
        
		// var a=await fetch(process.env.REACT_APP_URL+'bankdetails?tempid='+localStorage.getItem("tempID"));
		// var b=await a.json();
		// var z=JSON.stringify(b.recordset);
        // var a=JSON.parse(z);
        //    setdata(a);
		   fetchbygetmethod(setdata, 'bankdetails', { tempid: localStorage.getItem("tempID") });
	}	

	useEffect(() => { loaddata() }, [])
	useEffect(() => {
		// if (isFirstRender.current) {
		// 	isFirstRender.current = false;
		//   } else {
		//console.log("mydata",myimgupload);
		if (myimgupload.myerror === true)
			alert("Please Upload Your Image Again!!");
		else if (myimgupload.initialstage === false && myimgupload.myloading === false) {
			alert('File uploaded Successfully');
			loaddata();

		}

		// }

	}, [myimgupload])

    
    return(
        <div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Bank Details</h4>
						
					</div>
				</div>
				
				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Bank Details</h4>
										<hr width="100%" />
										{data.myloading === true ? <Spinner />
											: data.myerror === true ? <Nodatafound />
												:
												data.mypost.length > 0 &&
												data.mypost.map(
												(item,index) =>{
												  return(
										<div class="row">
											<div class="col-sm-6 col-xs-12">
											
												<div class="m-t-20">
													<form class="" action="#">
														<div class="form-group">
															<label>Bank Name</label>
															<lable  class="form-control">
																{item.bankname}
															</lable>
														</div>
														
														<div class="form-group">
															<label>Branch Name</label>
															 <label 
															 class="form-control" >
															{item.branchname} 
																 </label>
															
																
															
														</div>
														
														<div class="form-group">
															<label>State</label>
															 <label 
															 class="form-control">
															 {item.statename}
															 </label>
														</div>

														<div class="form-group">
															<label>Pan No.</label>
															 <label 
															 class="form-control">
															 {item.panno}
															 </label>
														</div>
														
														<div class="form-group">
															<label>GST No.</label>
															 <label 
															 class="form-control">
															 {item.gstin}
															 </label>
														</div>
														<div class="form-group">
															<label>Deposit Date</label>
															 <label 
															 class="form-control">
															 {item.depositdate}
															 </label>
														</div>
														<div class="form-group">
																		<label>Pan Card Image</label>
																		<div>
																			<input type="file" class="form-control" onChange={handleFileChangeforpan}  />
																		</div>
																		<div>
																			<img style={{ height: 300, width: 300 }} src={filepanimage ? filepanimage : Array.isArray(data.mypost) && data.mypost.length > 0 && data.mypost[0].panphoto ? './uploads/' + data.mypost[0].panphoto : process.env.PUBLIC_URL + imagedefault} alt=""
																				className="img-circle" />

																		</div>

																	</div>
																	<div class="form-group">
																		<div>

																			<button type="reset" onClick={(e) => onSubmitpan(e)}
																				class="btn btn-primary waves-effect m-l-5">
																				Submit PAN</button>
																		</div>
																	</div>
													
																	<div class="form-group">
																		<label>Is Block</label>
																		<select class="form-control">
																			<option>Select</option>
																			<option>Un Block</option>
																			<option>Block</option>

																		</select>

																	</div>
													</form>

												</div>

											</div>
											
											<div class="col-sm-6 col-xs-12">
												
												<div class="m-t-20">
													<form action="#">
														<div class="form-group">
															<label>Account No.</label>
															 <label 
															 class="form-control">
															 {item.accno}
																 </label>
														</div>
														<div class="form-group">
															<label>IFSC Code</label>
															 <label 
															 class="form-control">
															 {item.ifscno}
															 </label>
														</div>

															<div class="form-group">
															<label>Aadhar No.</label>
															 <label 
															 class="form-control" >
															 {item.adhaarno}
															 </label>
                                            
														</div>
															<div class="form-group">
															<label>Cheque No.</label>
															 <label 
															 class="form-control" >
															 {item.chequeno}
															 </label>
                                            
														</div>
															<div class="form-group">
															<label>Deposit Amt.</label>
															 <label 
															 class="form-control" >
															 {item.depositamt}
															 </label>
                                            
														</div>
															<div class="form-group">
															<label>Deposit Mode</label>
															 <label 
															 class="form-control" >
															 {item.mode}
															 </label>
                                            
														</div>
														<div class="form-group">
																		<label>Cheque Label Image</label>

																		<div>
																			<input type="file" class="form-control"  onChange={handleFileChangeforcheque}
																			/>
																		</div>
																		<div>
																			<img style={{ height: 300, width: 300 }}
																				src={filechequeimage ? filechequeimage : Array.isArray(data.mypost) && data.mypost.length > 0 && data.mypost[0].chequephoto ? './uploads/' + data.mypost[0].chequephoto : process.env.PUBLIC_URL + imagedefault} alt=""
																				className="img-circle" />

																		</div>
																	</div>
																	<div class="form-group">
																		<div>
																			<button type="submit" onClick={(e) => onSubmitcheque(e)}
																				class="btn btn-primary waves-effect waves-light">
																				Submit Cheque</button>


																		</div>
																	</div>
														
														

                                                        	
                                                        </form>
														
													
														
														
														</div>
														
														</div>
														

															<div class="form-group">
															<div>
																<button type="submit"
																	class="btn btn-primary waves-effect waves-light" style={{marginTop:99, marginLeft:350}}>
																	Submit</button>
																
															</div>
														</div>
														
													
												</div>)
														}
												
													
												
											   )
																
																}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Gotohome mydata={data} />
				</div>
    )
}

export default Brbankdetail;