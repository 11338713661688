import { useState, useEffect, React, useReducer } from 'react';
import { initialstate, myreducer, fetchbygetmethod, fetchbypostmethod } from '../../Commonfiles/Myreducer';

// import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

function AdminAddEditPurchaseOrder() {

    const [mydata, setmydata] = useReducer(myreducer, initialstate);
    
    const [myitemdetail, setmyitemdetail] = useReducer(myreducer, initialstate);
    const [purchaseOrderCode, setPurchaseOrderCode] = useReducer(myreducer, initialstate);
    const [tableVisible, setTableVisible] = useState(false); // State to control table visibility
    const [showbill, setshowbill] = useReducer(myreducer, initialstate);
    const [checkedrowslist,setcheckedrowslist]=useState({});
    const [item,setitem]=useState([]);
    const [jsonitemdata,setjsonitemdata]=useState([]);

function handlecheckbox(index)
{
   setcheckedrowslist((previousindex)=>({...previousindex,[index]:!previousindex[index]}));
}
function handleaddtojsonitem()
{
         const selecteditems=item.filter((row,index)=>checkedrowslist[index]);
        // const orderno=(document.getElementById('txtpocode').textContent);
          setjsonitemdata(selecteditems.map(({orderNo,ItemId,ItemName,Rate,Quantity})=>({orderNo,ItemId,ItemName,Rate:parseFloat(Rate),Quantity:parseInt(Quantity)})));
        // console.log("jsondata",jsonitemdata);

}
function savetodatabase()
{
    const jsondata={
        POCode:document.getElementById('txtpocode').textContent,
        OrderItems:jsonitemdata,
        VendorId:document.getElementById('txtvendorid').value,
        Status:'Y',
        FinancialYear: parseInt(process.env.REACT_APP_FINANCIAL_YEAR),
        GST:'Y'
    }
    console.log("jsondata",jsondata);

    fetchbypostmethod(setmydata,'Admincompanypurchasedetails', {jsondata:jsondata});
}
function handleInputChange(e,index,column)
{
    const newitem=[...item];
    newitem[index][column]=e.target.value;
    setitem(newitem);

}
useEffect(()=>{console.log("checkedrowslist",checkedrowslist)},[checkedrowslist]);

    async function Getitemdetail() {

        fetchbygetmethod(setmyitemdetail, 'Admingetitempodetail', {});

        console.log("myitem", myitemdetail);
    }


    async function loaddata() {
        await fetchbygetmethod(setPurchaseOrderCode, 'Admingetmaxpocode', {});
       // console.log("Fetched Data:", mydata);
    }

    async function displaytable() {

        document.getElementById("tabledisplay").style.display = "block";
    }

    useEffect(() => {
        loaddata();
        Getitemdetail();
    }, []);

    useEffect(()=>{
        if(myitemdetail.mypost.length>0) setitem(myitemdetail.mypost.map(row=>({

            ...row,
            Rate:0,
            Quantity:0,
            orderNo:document.getElementById('txtpocode').textContent
        }
          
        )))
        console.log("myitemdetail",myitemdetail);
    }
        
       ,[myitemdetail]);
       useEffect(()=>{
       
        console.log("item",item);
    }
        
       ,[item]);

    useEffect(()=>{ console.log("jsondata",jsonitemdata);},[jsonitemdata])   ;


    return (<div className="content-page">
        <div className="content">
            <div className="">
                <div className="page-header-title">
                    <h4 className="page-title">Purchase Order</h4>

                </div>
            </div>

            <div className="page-content-wrapper ">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="panel panel-primary">
                                <div className="panel-body">
                                    <h4 className="m-t-0 m-b-30">Add /Edit Purchase Order</h4>
                                    {/* <hr width="100%"/> */}
                                    <div className="row">
                                        <div className="form-group">
                                            <div>


                                                <button onClick={() => window.history.back()} type="submit"
                                                    className="btn btn-primary waves-effect waves-light" style={{ marginLeft: 5 }}>
                                                    Purchase Order Data</button>

                                            </div>
                                        </div>

                                        <div className="col-sm-4 col-xs-12">

                                            <div className="m-t-20">
                                                <form className="" action="#">

                                                    <div className="form-group">
                                                        <label>Vendor Name</label>
                                                        <select class="form-control" id='txtvendorid' onChange={() => { loaddata(); }}>
                                                            <option value="1">D.N.K. Traders</option>
                                                            <option value="2">DOLLY FRUIT PRODUCTS</option>
                                                            <option value="3">SHANTI TRADERS</option>
                                                            <option value="4">MODI NATURALS LIMITED</option>
                                                            <option value="5">PERIWINKLE DETERGENT INDUSTRIES</option>
                                                            <option value="6">AYUSHI ENTERPRISES</option>
                                                            <option value="7">AROMA HOME PRODUCT</option>
                                                            <option value="8">ILLINOIS MEDICA</option>
                                                            <option value="9">VIJAY IMPEX</option>
                                                            <option value="10">CHAITANYA SOLVEX PVT LTD</option>
                                                            <option value="11">VANDITA FOOD PRODUCTS</option>
                                                            <option value="12">J.K.ENTERPRISES</option>
                                                            <option value="10012">RSA HERBAL PHARMACEUTICALS</option>
                                                            <option value="10013">INNOVATIVE HEALTHCARE</option>
                                                            <option value="20013">SARDAR JI PAPAD WALE RAHUL PAPAD BHANDAR</option>

                                                        </select>
                                                    </div>



                                                </form>
                                            </div>


                                        </div>
                                        <div className="col-sm-4 col-xs-12">

                                            <div className="m-t-20">
                                                <form className="" action="#">

                                                    <div className="form-group">
                                                        <label>Purchase Order Code</label>
                                                        <p class='form-control' id='txtpocode'>{purchaseOrderCode.mypost.length > 0 &&
                                                            purchaseOrderCode.mypost[0].POCode.replace(/\d+/, match => (parseInt(match) + 1).toString())}
                                                        </p>
                                                    </div>



                                                </form>
                                            </div>


                                        </div>
                                        <div className="col-sm-4 col-xs-12">

                                            <div className="m-t-20">
                                                <form className="" action="#">

                                                    <div className="form-group">
                                                        <label>Unit</label>
                                                        <select class="form-control">
                                                            <option>---Select---</option>
                                                            <option>PCS</option>
                                                        </select>
                                                    </div>



                                                </form>
                                            </div>


                                        </div>

                                        <div className="form-group">
                                            <div>
                                                <button type="submit"
                                                    className="btn btn-primary waves-effect waves-light" style={{ marginLeft: 10 }} onClick={displaytable}>
                                                    Show Items</button>
                                             
                                                    <button type="submit"
                                                                className="btn btn-primary waves-effect waves-light" style={{ marginLeft: 5 }} onClick={handleaddtojsonitem} >
                                                                Add Items</button>
                                                                <button type="submit"
                                                    className="btn btn-primary waves-effect waves-light" style={{ marginLeft: 750 }} onClick={savetodatabase}>
                                                    Submit Order</button>

                                            </div>
                                        </div>
                                      



                                    </div>


                                    <div class="row" id="tabledisplay" style={{ display: 'none' }}>
                                        <div class="col-md-12">
                                            <div class="panel panel-primary">
                                                <div class="panel-body">
                                                    <div class="form-group">
                                                        {/* <label>IP OR Login ID</label> */}
                                                        <div>
                                                            <div>
                                                                <input type="text" class="form-control" placeholder="Search Item......" aria-label="Search" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <h4 class="m-b-30 m-t-0">Pannel Comission</h4> */}
                                                    <table id="datatable-buttons"
                                                        class="table table-striped table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Select</th>
                                                                <th>Item Id</th>
                                                                <th>Item Name</th>
                                                                <th>Order Rate</th>
                                                                <th>Order Quantity</th>




                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {item.length > 0 ? (
                                                                item.map((row, index) => (

                                                                    <tr>
                                                                        <td><input type="checkbox" onChange={()=>handlecheckbox(index)} style={{ marginLeft: 21 }} /></td>
                                                                        <td>{row.ItemId}</td>
                                                                        <td>{row.ItemName}</td>
                                                                        <td><input type="text" onChange={(e)=>handleInputChange(e,index,'Rate')}/></td>
                                                                        <td><input type="text" onChange={(e)=>handleInputChange(e,index,'Quantity')}/></td>





                                                                    </tr>
                                                                ))

                                                            ) : null}
                                                        </tbody>

                                                    </table>
                                                  
                                                </div>
                                            </div>
                                        </div>
                                    </div>









                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer className="footer"> © 2016 WebAdmin - All Rights
            Reserved. </footer>
    </div>)
}



export default AdminAddEditPurchaseOrder;