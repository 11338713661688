import React,{useState,useEffect} from 'react'
function Storepaymentdetail()
{

// var [mydata,setmydata]=useState([]);
// var [filtereddata,setfiltereddata]=useState([]);

// 	async function loaddata()
//   {
//    var x=await fetch(process.env.REACT_APP_URL+'businesstransaction?option=1');
//    var y=await x.json();
//    var z=JSON.stringify(y.recordset);
//    var a=JSON.parse(z);
//    setmydata(a);
//     console.log("mydata",mydata);
//   }
 
//   useEffect(()=>{loaddata();},[]);




   return(
        <div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Payment Detail</h4>
						
					</div>
				</div>
				
				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Payment Detail</h4>
										<hr width="100%" />
										
										<div class="row">
                                     
										 <div class="panel panel-primary">
									<div class="panel-body">
                                     <div class="container">
                                                                      <div class="row justify-content-center mt-5">
                                                                      <div class="col-md-6">
                                                                        <form>
                                                                         <div class="input-group col-lg-12 ">
                                                                         <input type="text" class="form-control " placeholder="Search" aria-label="Search" />
            
                                                                       </div>
                                                                        </form>
                                                                         </div>
                                                                         </div>
                                                                        </div>	
                                        </div>
                                    </div>
											
														
													
												</div>
                                                
												 
											</div>
										</div>
									</div>
								</div>
                                     <div class="row">
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-b-30 m-t-0">Payment Detail</h4>
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
												    <th>Approved</th>
													<th>Applicant ID</th>
													<th>User Id</th>
													<th>Panel Name</th>
													<th>Mobile No</th>
													<th>Acc No</th>
                                                    <th>IFSC No</th>
													<th>is paid</th>
													<th>Month</th>
													<th>Prev Amount</th>
													<th>Amount</th>
													<th>Total</th>
                                                    
													
													
												</tr>
											</thead>
											<tbody>
												
											
                                              <tr>
													<td></td>
													<td></td>
                                                    <td></td>
													<td></td>
                                                    <td></td>
													<td></td>
                                                    <td></td>
													<td></td>
                                                    <td></td>
													<td></td>
                                                    <td></td>
													<td></td>
                                                    
												</tr>
											
                                             
											</tbody>
										
										</table>
									</div>
								</div>
							</div>
						</div>
							</div>
						</div>

                      
					</div>
				</div>
    )


  
}

export default Storepaymentdetail;