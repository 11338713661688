import { useState, useEffect, React, useReducer, useRef } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod, fetchbypostmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
import { Gotohome } from '../Commonfiles/checkfortoken';
import axios from 'axios';
function DSUploadPicture(props) {
	const isFirstRender = useRef(true);
	const [mydata, setmydata] = useReducer(myreducer, initialstate);
	const [myimgupload, setmyimgupload] = useReducer(myreducer, initialstate);
	//console.log('mydata',mydata);
	const imagefromdb = "https://jmvdnet.jmvdbusiness.com/DirectSeller/DSImages/";
	//const imagefromdbnew="../Commonfiles/uploads/";
	const imagedefault = "/assets/images/users/avatar-1.jpg";
	let z;
	const [image, setImage] = useState(null);
	const [file, setfile] = useState(null);

	const handleFileChange = (event) => {
		const imgfile = event.target.files[0];

		if (imgfile) {


			const reader = new FileReader();
			reader.onloadend = () => {

				setImage(reader.result);
				setfile(imgfile);

			};
			reader.readAsDataURL(imgfile);

		}
	};

	const onSubmit = async (e) => {

		e.preventDefault();

		if (!file) {

			console.error('No file selected.');
			return;
		}
		const imagename=localStorage.getItem('tempID')+'_pic';
	// Create a new file with the new name
    const newFileName = `${imagename}.${file.name.split('.').pop()}`; // e.g., dsid.jpg
    const newFile = new File([file], newFileName, { type: file.type });

	const formData = new FormData();
  
	formData.append('file', newFile);
	//alert("newFile"+newFile.filename);

		try {
			const res = await axios.post(process.env.REACT_APP_URL + 'upload', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});

			const { fileName } = res.data;

			console.log('fileName', fileName);

			fetchbypostmethod(setmyimgupload, 'dsuploadphoto', { fileName: fileName, applicantid: localStorage.getItem('applicantID') });


		} catch (err) {
			console.error('ret', err);
		}
	};


	async function loaddata() {
		//alert();
		fetchbygetmethod(setmydata, 'profileinformation', { tempid: localStorage.getItem('tempID') });
        //alert();
	}

	useEffect(() => {
		//alert('1');
		loaddata();


	}, [])

	useEffect(() => {
		// if (isFirstRender.current) {
		// 	isFirstRender.current = false;
		//   } else {
		//console.log("mydata",myimgupload);
		if (myimgupload.myerror == true)
			alert("Please Upload Your Image Again!!");
		else if (myimgupload.initialstage==false && myimgupload.myloading == false)
		{
			alert('File uploaded Successfully');
			loaddata();
			
		}
			
		// }

	}, [myimgupload])



	return (<div className="content-page">
		<div className="content">
			<div className="">
				<div className="page-header-title">
					<h4 className="page-title">Profile Picture</h4>

				</div>
			</div>

			<div className="page-content-wrapper ">
				<div className="container">
					<div className="container">
						<div className="row">
							<div className="col-sm-6">
								<div className="panel panel-primary">
									<div className="panel-body">

										<div className="row">
											<div className="col-md-12">
												<div className="m-b-30">

													<div className="fallback">
														<input type="file" accept="image/*" onChange={handleFileChange} />
														{image && <img src={image} alt="Selected" style={{ marginTop: '20px', maxWidth: '100%' }} />}
													</div>

												</div>
												<div className="text-center m-t-15">
													<button type="button" onClick={onSubmit}
														className="btn btn-primary waves-effect waves-light">Submit
														File</button>
												
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-sm-6">
								<div className="panel panel-primary">
									<div className="panel-body" style={{ textAlign: 'center' }}>
										<div className="row">
											<div className="col-md-12" >
												<div className="m-b-30" >
													<img style={{ height: 300, width: 300 }} src={Array.isArray(mydata.mypost) && mydata.mypost.length > 0 && mydata.mypost[0].ApplicantPhoto ? './uploads/' + mydata.mypost[0].ApplicantPhoto : process.env.PUBLIC_URL + imagedefault} alt=""
														className="img-circle" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		{/* <Gotohome mydata={mydata}/> */}
	</div>)
}

export default DSUploadPicture;