import React,{useState,useEffect} from 'react'
function Admincomissionmaster()
{

var [mydata,setmydata]=useState([]);


	async function loaddata()
  {
   var x=await fetch(process.env.REACT_APP_URL+'Admincomissionmaster');
   
   var y=await x.json();
   var z=JSON.stringify(y.recordset);
   var a=JSON.parse(z);
   setmydata(a);
    console.log("mydata",mydata);
  }
 
  useEffect(()=>{loaddata();},[]);




  return(
		<div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Pannel Comission</h4>
						
					</div>
				</div>
				
				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Pannel Comission</h4>
										<hr width="100%" />
										
										<div class="row">
                                        	<div class="form-group">
															<div>
                                                            
                                                            <label class="form-group" style={{marginLeft:29}}>
                                                                     Status
                                                            </label>
                                                            <select id='ddlitem' class="btn mdi-border-color" style={{marginLeft:5}} >
                                                            <option style={{textAlign:'justify'}}>Show All</option>
															    <option style={{textAlign:'justify'}}>Unblock</option>
                                                                <option  style={{textAlign:'justify'}}>Block</option>
                                                                </select>
																<a href='AdminAddEditPannelComission' type="submit"
																	class="btn btn-primary waves-effect waves-light" style={{marginLeft:5}}>
																	Add New Pannel Commission</a>

															</div>
														</div> 
                                            
                                                       
                                                        	
                                                          
													
												</div>

                                             <div class="row">
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										{/* <h4 class="m-b-30 m-t-0">Pannel Comission</h4> */}
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
												    <th>Sr No.</th>
												    <th>Applicant Type</th>
													<th>Comission Percentage</th>
													<th>Comission Amount</th>
													<th>Created Date</th>
													<th>Status</th>
													<th>Edit</th>
													
													
													
												</tr>
											</thead>
											<tbody>
												{mydata.length>0 &&
											  mydata.map((row,index)=>{
                                              
                                              return(	
											
                                              <tr>
													<td>{index+1}</td>
                                                     <td>{row.ApplicantType}</td>
													<td>{row.CommisionPercentage}</td>
                                                    <td>{row.CommisionAmount}</td>
													<td>{row.CreatedDate}</td>
										             <td>{row.Status=='Y'?'UnBlock':'Block'}</td>
													<td><input type="button" value="Edit" /> </td>
                                                   
													
									
												</tr>
											
                                             ) 
                                              })
												
                                                }
											</tbody>
										
										</table>
									</div>
								</div>
							</div>
						</div>
										
										
											</div>
                                            
										</div>
									</div>
								</div>
							
							
							
						
							
							</div>
						</div>
					</div>


				</div>
	)


  
}

export default Admincomissionmaster;