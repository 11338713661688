import { Float } from "react";
import { FiShoppingBag } from "react-icons/fi";
import { BsBagFill } from "react-icons/bs";
import { RiMoneyRupeeCircleLine } from "react-icons/ri";
import { FaRupeeSign } from "react-icons/fa";
import React, { useState, useEffect , useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
import {Gotohome} from '../Commonfiles/checkfortoken';
import { Bar, Doughnut } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
//import {bardata} from "./data.js";
//import { addComponentMeta as Utils } from 'js-react-utils';
//import { UtilityFunction as Utils } from 'js-react-utils';

export default function Storedashboard() {

	//morris chart work 

	// function barchart()
	// {
	// 	var obj=[{month:'jan',sales:100},
	// 	{month:'feb',sales:40},
	// 	{month:'mar',sales:90}];

	// 	//Morris.Bar({element:'morris-bar-example',data:obj,xkey:'month',ykeys:['sales'],labels:['Sales']});
	// }

	var [monthlypurchase, setmonthlypurchase] = useReducer(myreducer, initialstate);
	var [monthlysale, setmonthlysale] = useReducer(myreducer, initialstate);
	var [lastpurchase, setlastpurchase] =useReducer(myreducer, initialstate);
	const [mydata, setmydata] = useReducer(myreducer, initialstate);
	//const labels = Utils.months({count: 12});


	async function loadbarchart() {
		try {
			
			fetchbygetmethod(setmydata, 'depotfinancialyearearning', { dsid: localStorage.getItem("applicantID"), financialyear: process.env.REACT_APP_FINANCIAL_YEAR });//,old:old.value,nyu:nyu.value});//, month: month, year: year })
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	}


	async function loaddata() {
		try {
			
			fetchbygetmethod(setmonthlypurchase, 'depotcurrentmonthpurchase', { ApplicantId: localStorage.getItem("applicantID"), financialyear: process.env.REACT_APP_FINANCIAL_YEAR });//,old:old.value,nyu:nyu.value});//, month: month, year: year })
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	}

	async function loaddata3() {
		try {
			
			fetchbygetmethod(setmonthlysale, 'depotcurrentmonthsales', { fromapplicantid: localStorage.getItem("applicantID"), financialyear: process.env.REACT_APP_FINANCIAL_YEAR });//,old:old.value,nyu:nyu.value});//, month: month, year: year })
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	}


	async function loaddata2() {
		try {
			
			fetchbygetmethod(setlastpurchase, 'depotcurrentmonthsales', { applicantid: localStorage.getItem("applicantID"), financialyear: process.env.REACT_APP_FINANCIAL_YEAR });//,old:old.value,nyu:nyu.value});//, month: month, year: year })
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	}


	useEffect(() => { loaddata(); loaddata2(); loadbarchart(); loaddata3(); }, [])

	return (<div class="content-page">
		<div class="content">
			<div class="">
				<div class="page-header-title">
					<h4 class="page-title">Dashboard</h4>
				</div>
			</div>
			<div class="page-content-wrapper ">
				<div class="container">
					<div class="row">
						<div class="col-sm-6 col-lg-3">
							<div class="panel text-center">
								<div class="panel-heading">
									<h4 class="panel-title text-muted font-light">Total
										Monthly Purchases</h4>
								</div>
								<div class="panel-body p-t-10">
									{/* <FiShoppingBag style={{marginLeft:10}} /> */}
									<h2 class="m-t-0 m-b-15">

										<BsBagFill style={{ marginRight: 13, color: 'sandybrown' }} />
										{/* <RiMoneyRupeeCircleLine /> */}
										<FaRupeeSign style={{ height: 21 }} />

										{monthlypurchase.myloading == true ? <Spinner />
											: monthlypurchase.myerror == true ? <Nodatafound />
												:
												monthlypurchase.mypost.length > 0 &&
												monthlypurchase.mypost.map((row, index) => {
												return (
													<label key={index} value={row.totalpurchase} style={{ display: 'none' }}>
														{row.totalpurchase}
													</label>
												);
											})
										}
										{monthlypurchase.mypost.length > 0 &&
											<label>{Math.round(Math.abs(monthlypurchase.mypost.reduce((total, row) => total + row.totalpurchase, 0)))}</label>
										}

									</h2>
									{/* <p class="text-muted m-b-0 m-t-20">
										{lastpurchase.length > 0 && 
                                             lastpurchase.map((row, index) => {
                                              return (
											<label>Your last purchase was made on {row.LastPurchaseDate} </label> 
										);
									})
								  }                                    
										</p> */}
								</div>
							</div>
						</div>
						<div class="col-sm-6 col-lg-3" style={{ marginLeft: 100 }}>
							<div class="panel text-center">
								<div class="panel-heading">
									<h4 class="panel-title text-muted font-light">Total BV
									</h4>
								</div>
								<div class="panel-body p-t-10">
									<h2 class="m-t-0 m-b-15">
										<i
											class="mdi mdi-arrow-up-bold-circle-outline text-success m-r-10"></i>
										<label>

											{monthlypurchase.myloading == true ? <Spinner />
											: monthlypurchase.myerror == true ? <Nodatafound />
												:
												monthlypurchase.mypost.length > 0 &&
												monthlypurchase.mypost.map((row, index) => {
													return (
														<label key={index} value={row.BV} style={{ display: 'none' }}>
															{row.TotalBV}
														</label>
													);
												})
											}
											{monthlypurchase.mypost.length > 0 &&
												<label>{Math.round(Math.abs(monthlypurchase.reduce((total, row) => total + row.TotalBV, 0)))}</label>
											}



										</label>
									</h2>
									{/* <p class="text-muted m-b-0 m-t-20">
											<b>42%</b> Orders in Last 10 months
										</p> */}
								</div>
							</div>
						</div>
						{/* <div class="col-sm-6 col-lg-3">
								<div class="panel text-center">
									<div class="panel-heading">
										<h4 class="panel-title text-muted font-light">Unique
											Visitors</h4>
									</div>
									<div class="panel-body p-t-10">
										<h2 class="m-t-0 m-b-15">
											<i
												class="mdi mdi-arrow-up-bold-circle-outline text-success m-r-10"></i><b>452</b>
										</h2>
										<p class="text-muted m-b-0 m-t-20">
											<b>22%</b> From Last 24 Hours
										</p>
									</div>
								</div>
							</div> */}
						<div class="col-sm-6 col-lg-3" style={{ marginLeft: 100 }}>
							<div class="panel text-center">
								<div class="panel-heading">
									<h4 class="panel-title text-muted font-light">Total
										Sales</h4>
								</div>
								<div class="panel-body p-t-10">
									<h2 class="m-t-0 m-b-15">
										<i
											class="mdi mdi-arrow-down-bold-circle-outline text-danger m-r-10"></i>

                                            {monthlysale.myloading == true ? <Spinner />
											: monthlysale.myerror == true ? <Nodatafound />
												:
												monthlysale.mypost.length > 0 &&
												monthlysale.mypost.map((row, index) => {
													return (
														<label key={index} value={row.totalsale} style={{ display: 'none' }}>
															{row.totalsale}
														</label>
													);
												})
											}
											{monthlysale.mypost.length > 0 &&
												<label>{Math.round(Math.abs(monthlysale.reduce((total, row) => total + row.totalsale, 0)))}</label>
											}

									</h2>
									{/* <p class="text-muted m-b-0 m-t-20">
											<b>35%</b> From Last 1 Month
										</p> */}
								</div>
							</div>
						</div>
					</div>
					{/* <div class="row">
						<div class="col-sm-12">
							<div class="panel panel-primary">
								<div class="panel-body">
									<h4 class="m-t-0">Bar Chart</h4>
									<div>

										<Bar data={{
											labels: mydata.map((data) => data.month),

											datasets: [{
												label: 'NetCommision', data: mydata.map((row) => row.NetCommission),
												backgroundColor: 'red', borderRadius: 5
											}
											],
											options: {
												scales: {
												  y: {
													suggestedMin:0,
													suggestedMax:50000
												  }
												}
											  }


										}} />

									</div>

								</div>
							</div>
						</div>
						
					</div> */}
					<div class="row">
						<div class="col-md-4">
							<div class="panel">
								<div class="panel-body">
									<h4 class="m-b-30 m-t-0">Current Offer</h4>

									<p class="font-600 m-b-5">
										नए वर्ष का उपहार
										जनवरी से मार्च तक टोटल 5000,10000,20000,40000 तक के सेलिंग प्राइस की खरीददारी पर पाए निष्चित उपहार।
										<span class="text-primary pull-right"></span>
									</p>
									<hr />
									{/* <div class="progress m-b-20">
											<div class="progress-bar progress-bar-primary "
												role="progressbar" aria-valuenow="80" aria-valuemin="0"
												aria-valuemax="100" ></div>
										</div> */}

									<p class="font-600 m-b-5">
										टोटल सेलिंग प्राइस 5000 - उपहार
										<span class="text-primary pull-right"></span>
									</p>
									<div class="progress m-b-20">
										<div class="progress-bar progress-bar-primary "
											role="progressbar" aria-valuenow="80" aria-valuemin="0"
											aria-valuemax="100" ></div>
									</div>
									<p class="font-600 m-b-5">
										टोटल सेलिंग प्राइस 10000 - उपहार
										<span class="text-primary pull-right"></span>
									</p>
									<div class="progress m-b-20">
										<div class="progress-bar progress-bar-primary "
											role="progressbar" aria-valuenow="50" aria-valuemin="0"
											aria-valuemax="100" ></div>
									</div>
									<p class="font-600 m-b-5">
										टोटल सेलिंग प्राइस 20000 - उपहार
										<span class="text-primary pull-right"></span>
									</p>
									<div class="progress m-b-20">
										<div class="progress-bar progress-bar-primary "
											role="progressbar" aria-valuenow="70" aria-valuemin="0"
											aria-valuemax="100" ></div>
									</div>
									<p class="font-600 m-b-5">
										टोटल सेलिंग प्राइस 40000 - उपहार
										<span class="text-primary pull-right"></span>
									</p>
									<div class="progress m-b-20">
										<div class="progress-bar progress-bar-primary "
											role="progressbar" aria-valuenow="65" aria-valuemin="0"
											aria-valuemax="100" ></div>
									</div>
									<p class="font-600 m-b-5">
										तीन महीनो में अपने सेलिंग टारगेट को पूरा करे और उपहार में शामिल हो|<span class="text-primary pull-right"></span>
									</p>
									{/* <div class="progress m-b-20">
											<div class="progress-bar progress-bar-primary "
												role="progressbar" aria-valuenow="25" aria-valuemin="0"
												aria-valuemax="100" ></div>
										</div> */}
									{/* <p class="font-600 m-b-5">
											Daily Visits<span class="text-primary pull-right"><b>40%</b></span>
										</p>
										<div class="progress m-b-0">
											<div class="progress-bar progress-bar-primary "
												role="progressbar" aria-valuenow="40" aria-valuemin="0"
												aria-valuemax="100"></div>
										</div> */}
								</div>
							</div>
						</div>
						<div class="col-md-8">
							<div class="panel">
								<div class="panel-body">
									<h4 class="m-b-30 m-t-0">Recent Contacts</h4>
									<div class="row">
										<div class="col-xs-12">
											<div class="table-responsive">
												<table class="table table-hover m-b-0">
													{/* <thead>
														<tr>
															<th>Name</th>
															<th>Position</th>
															<th>Office</th>
															<th>Age</th>
															<th>Start date</th>
															<th>Salary</th>
														</tr>
													</thead> */}
													{/* <tbody>
														<tr>
															<td>Tiger Nixon</td>
															<td>System Architect</td>
															<td>Edinburgh</td>
															<td>61</td>
															<td>2011/04/25</td>
															<td>$320,800</td>
														</tr>
														<tr>
															<td>Garrett Winters</td>
															<td>Accountant</td>
															<td>Tokyo</td>
															<td>63</td>
															<td>2011/07/25</td>
															<td>$170,750</td>
														</tr>
														<tr>
															<td>Ashton Cox</td>
															<td>Junior Technical Author</td>
															<td>San Francisco</td>
															<td>66</td>
															<td>2009/01/12</td>
															<td>$86,000</td>
														</tr>
														<tr>
															<td>Cedric Kelly</td>
															<td>Senior Javascript Developer</td>
															<td>Edinburgh</td>
															<td>22</td>
															<td>2012/03/29</td>
															<td>$433,060</td>
														</tr>
														<tr>
															<td>Airi Satou</td>
															<td>Accountant</td>
															<td>Tokyo</td>
															<td>33</td>
															<td>2008/11/28</td>
															<td>$162,700</td>
														</tr>
														<tr>
															<td>Brielle Williamson</td>
															<td>Integration Specialist</td>
															<td>New York</td>
															<td>61</td>
															<td>2012/12/02</td>
															<td>$372,000</td>
														</tr>
														<tr>
															<td>Herrod Chandler</td>
															<td>Sales Assistant</td>
															<td>San Francisco</td>
															<td>59</td>
															<td>2012/08/06</td>
															<td>$137,500</td>
														</tr>
													</tbody> */}
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Gotohome mydata={mydata}/>
	</div>
	)
}