import React, {  useEffect, useReducer, useState } from 'react';
import tree from './../treeimg.gif';
import { initialstate, myreducer, fetchbygetmethod, actiontypes } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';

import { Gotohome } from '../Commonfiles/checkfortoken';


export default function Yourgroup(props) {
    const imagefromdb = "./uploads/";
    const imagedefault = "/assets/images/users/avatar-default.png";
    const [mydata, setmydata] = useReducer(myreducer, initialstate);// useReducer(initialstate,myreducer);

    // Get the date string from the data
   

    var [previoustempID, setprevioustempID] = useState([]);
    var timesclicked;

    function dateformat(x) {
        if(mydata.status=='ok')
            {

         
        let dateString = mydata.mypost[x].date;
        // Split the date string by the hyphen character
        let dateArray = dateString.split("-");
        // Access the individual parts of the date
        let year = dateArray[0];
        let month = dateArray[1];
        let restofdate = dateArray[2];
        let dayArray = restofdate.split("T");
        let day = dayArray[0];
        let formatteddate = day + "/" + month + "/" + year;
        return formatteddate;
    }
    return '';
    }
    function getdata(tempid) {
        fetchbygetmethod(setmydata, 'yourgroup', { tempid: tempid })
        console.log("mydata", mydata);
    }
    function setbackbutton() {
        var btnback = document.getElementById('btngoback');
        if (previoustempID.length == 1) {
            btnback.disabled = true;
        }
        else {
            btnback.disabled = false;
        }
    }
    function removelastitemfromarray() {
        //alert();
        const newarray = previoustempID.filter((item, itemindex) => {

            return itemindex != previoustempID.length - 1
        })

        setprevioustempID(newarray);
    }
    function goBack(parentID, boolworker, newparentID) {
        //alert(previoustempID.length);
        parentID = parentID;
        newparentID = newparentID;
        if (boolworker == 0) {
            //alert(previoustempID.length);
            var x = previoustempID[previoustempID.length - 1];
            getdata(x);
            removelastitemfromarray();
        } else {
            setprevioustempID([...previoustempID, parentID]);
            getdata(newparentID);
            //console.log("data going to array is: " + previoustempID[0]);

        }

        setbackbutton();



    }
  
   
    useEffect(() => { getdata(props.dsname); }, [props.dsname]);
    useEffect(() => { console.log('myarray', previoustempID); }, [previoustempID]);
  


    return (

        <div className="content-page">
            <div className="content">
                <div className="">
                    <div className="page-header-title">
                        <h4 className="page-title">My Group Tree</h4>
                    </div>
                </div>
                <div className="page-content-wrapper ">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="panel panel-primary">
                                    <div className="panel-body">
                                        <h4 className="m-t-0 m-b-30">
                                            <div className='treeheader'>
                                                <div className='backButton'>
                                                    <button id='btngoback' onClick={() => { goBack(props.dsname, 0, props.dsname) }} >Go Back</button>

                                                    {/*This won't work because only one previous temp id will be stored. If the cleint clicks on multiple 
                                                    tree nodes, then it will be difficult to store each node head of all the times he has clicked.*/}
                                                </div>
                                                <div className='resetButton'>
                                                    <button onClick={() => { getdata(props.dsname) }}>Reset</button>
                                                </div>
                                            </div>
                                        </h4>
                                        <hr width="100%" />
                                        {mydata.initialstage==true?<></>:  mydata.myloading == true ? <Spinner /> : <>
                                            {mydata.mypost != null && mydata.mypost.length > 0 ?
                                                <div className="row">
                                                    <div className='treelevel1'>
                                                        <div className='treenode'>
                                                            <div className='treenodeheader'>
                                                                <label>{mydata.mypost != null && mydata.mypost[0].ApplicantName}</label>
                                                                <label><span>{mydata.mypost != null && mydata.mypost[0].TempID}</span></label>
                                                            </div>

                                                            <div className='treenodebody'>
                                                                <div className='treephoto'>
                                                                    <img src={mydata.mypost != null && mydata.mypost.length > 0 ? imagefromdb + mydata.mypost[0].ApplicantPhoto : process.env.PUBLIC_URL + imagedefault} alt="nirmal pic"
                                                                        className="img-circle" />
                                                                </div>
                                                                <div className='treeinfo'>
                                                                    <label>Sponsor ID: <span>{mydata.mypost != null && mydata.mypost[0].sponsorid}</span></label><br />
                                                                    <label>Awareness ID:<span>{mydata.mypost != null && mydata.mypost[0].awarenessid}</span></label><br />
                                                                    <label>Joining Date: <span>{mydata.mypost != null && mydata.mypost[0].date.split('T')[0]}</span></label><br />
                                                                    {/* <label>Is Approved: <span>{mydata.mypost[0].IsApproved=="Y"?"Approved":"Pending"}</span></label> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='treebranch'>
                                                        <img src={tree} alt="" />
                                                    </div>
                                                    <div className='treelevel2'>
                                                        {mydata.mypost != null && mydata.mypost[1] != undefined ?
                                                            <div className='treelevel2child'>
                                                                <div className='treenode' onClick={() => { goBack(mydata.mypost[0].TempID, 1, mydata.mypost[1].TempID) }}>
                                                                    <div className='treenodeheader'>
                                                                        <label>{mydata.mypost[1].ApplicantName}</label>
                                                                        <label><span>{mydata.mypost[1].TempID}</span></label>
                                                                    </div>

                                                                    <div className='treenodebody'>
                                                                        <div className='treephoto'>
                                                                            <img src={mydata.mypost != null && mydata.mypost.length > 0 ? imagefromdb + mydata.mypost[1].ApplicantPhoto : process.env.PUBLIC_URL + imagedefault} alt=""
                                                                                className="img-circle" />
                                                                        </div>
                                                                        <div className='treeinfo'>
                                                                            <label>Sponsor ID: <span>{mydata.mypost != null && mydata.mypost[1].sponsorid}</span></label><br />
                                                                            <label>Awareness ID:<span>{mydata.mypost != null && mydata.mypost[1].awarenessid}</span></label><br />
                                                                            <label>Joining Date: <span>{mydata.mypost != null && mydata.mypost[1].date.split('T')[0]}</span></label><br />
                                                                            {/* <label>Is Approved: <span>{mydata.mypost[1].IsApproved=="Y"?"Approved":"Pending"}</span></label> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className='treelevel2child'>
                                                                <div className='treenode'>
                                                                    <div className='treenodebody'>
                                                                        <div className='defaulttreephoto'>
                                                                            <img src={process.env.PUBLIC_URL + imagedefault} alt=""
                                                                                className="img-circle" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {mydata.mypost != null && mydata.mypost[2] != undefined ?
                                                            <div className='treelevel2child'>
                                                                <div className='treenode' onClick={() => { goBack(mydata.mypost[0].TempID, 1, mydata.mypost[2].TempID) }}>
                                                                    <div className='treenodeheader'>
                                                                        <label>{mydata.mypost != null && mydata.mypost[2].ApplicantName}</label>
                                                                        <label><span>{mydata.mypost != null && mydata.mypost[2].TempID}</span></label>
                                                                    </div>

                                                                    <div className='treenodebody'>
                                                                        <div className='treephoto'>
                                                                            <img src={mydata.mypost != null && mydata.mypost.length > 0 ? imagefromdb + mydata.mypost[2].ApplicantPhoto : process.env.PUBLIC_URL + imagedefault} alt=""
                                                                                className="img-circle" />
                                                                        </div>
                                                                        <div className='treeinfo'>
                                                                            <label>Sponsor ID: <span>{mydata.mypost != null && mydata.mypost[2].sponsorid}</span></label><br />
                                                                            <label>Awareness ID:<span>{mydata.mypost != null && mydata.mypost[2].awarenessid}</span></label><br />
                                                                            <label>Joining Date: <span>{mydata.mypost != null && mydata.mypost[2].date.split('T')[0]}</span></label><br />
                                                                            {/* <label>Is Approved: <span>{mydata.mypost[2].IsApproved=="Y"?"Approved":"Pending"}</span></label> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className='treelevel2child'>
                                                                <div className='treenode'>
                                                                    <div className='treenodebody'>
                                                                        <div className='defaulttreephoto'>
                                                                            <img src={process.env.PUBLIC_URL + imagedefault} alt=""
                                                                                className="img-circle" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className='treebranch'>
                                                        <img src={tree} alt="" />
                                                        <img src={tree} alt="" />
                                                    </div>
                                                    <div className='treelevel3'>
                                                        {mydata.mypost != null && mydata.mypost[3] != undefined ?
                                                            <div className='treelevel3child'>
                                                                <div className='treenode' onClick={() => { goBack(mydata.mypost[0].TempID, 1, mydata.mypost[3].TempID) }}>
                                                                    <div className='treenodeheader'>
                                                                        <label>{mydata.mypost != null && mydata.mypost[3].ApplicantName}</label>
                                                                        <label><span>{mydata.mypost != null && mydata.mypost[3].TempID}</span></label>
                                                                    </div>

                                                                    <div className='treenodebody'>
                                                                        <div className='treephoto'>
                                                                            <img src={mydata.mypost != null && mydata.mypost.length > 0 ? imagefromdb + mydata.mypost[3].ApplicantPhoto : process.env.PUBLIC_URL + imagedefault} alt=""
                                                                                className="img-circle" />
                                                                        </div>
                                                                        <div className='treeinfo'>
                                                                            <label>Sponsor ID: <span>{mydata.mypost[3].sponsorid}</span></label><br />
                                                                            <label>Awareness ID:<span>{mydata.mypost[3].awarenessid}</span></label><br />
                                                                            <label>Joining Date: <span>{mydata.mypost != null && mydata.mypost[3].date.split('T')[0]}</span></label><br />
                                                                            {/* <label>Is Approved: <span>{mydata.mypost[3].IsApproved=="Y"?"Approved":"Pending"}</span></label> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className='treelevel3child'>
                                                                <div className='treenode'>
                                                                    <div className='treenodebody'>
                                                                        <div className='defaulttreephoto'>
                                                                            <img src={process.env.PUBLIC_URL + imagedefault} alt=""
                                                                                className="img-circle" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {mydata.mypost != null && mydata.mypost[4] != undefined ?
                                                            <div className='treelevel3child'>
                                                                <div className='treenode' onClick={() => { goBack(mydata.mypost[0].TempID, 1, mydata.mypost[4].TempID) }}>
                                                                    <div className='treenodeheader'>
                                                                        <label>{mydata.mypost[4].ApplicantName}</label>
                                                                        <label><span>{mydata.mypost[4].TempID}</span></label>
                                                                    </div>

                                                                    <div className='treenodebody'>
                                                                        <div className='treephoto'>
                                                                            <img src={mydata.mypost != null && mydata.mypost.length > 0 ? imagefromdb + mydata.mypost[4].ApplicantPhoto : process.env.PUBLIC_URL + imagedefault} alt=""
                                                                                className="img-circle" />
                                                                        </div>
                                                                        <div className='treeinfo'>
                                                                            <label>Sponsor ID: <span>{mydata.mypost[4].sponsorid}</span></label><br />
                                                                            <label>Awareness ID:<span>{mydata.mypost[4].awarenessid}</span></label><br />
                                                                            <label>Joining Date: <span>{mydata.mypost != null && mydata.mypost[4].date.split('T')[0]}</span></label><br />
                                                                            {/* <label>Is Approved: <span>{mydata.mypost[4].IsApproved=="Y"?"Approved":"Pending"}</span></label> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className='treelevel3child'>
                                                                <div className='treenode'>
                                                                    <div className='treenodebody'>
                                                                        <div className='defaulttreephoto'>
                                                                            <img src={process.env.PUBLIC_URL + imagedefault} alt=""
                                                                                className="img-circle" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {mydata.mypost != null && mydata.mypost[5] != undefined ?
                                                            <div className='treelevel3child'>
                                                                <div className='treenode' onClick={() => { goBack(mydata.mypost[0].TempID, 1, mydata.mypost[5].TempID) }}>
                                                                    <div className='treenodeheader'>
                                                                        <label>{mydata.mypost[5].ApplicantName}</label>
                                                                        <label><span>{mydata.mypost[5].TempID}</span></label>
                                                                    </div>

                                                                    <div className='treenodebody'>
                                                                        <div className='treephoto'>
                                                                            <img src={mydata.mypost != null && mydata.mypost.length > 0 ? imagefromdb + mydata.mypost[5].ApplicantPhoto : process.env.PUBLIC_URL + imagedefault} alt=""
                                                                                className="img-circle" />
                                                                        </div>
                                                                        <div className='treeinfo'>
                                                                            <label>Sponsor ID: <span>{mydata.mypost[5].sponsorid}</span></label><br />
                                                                            <label>Awareness ID:<span>{mydata.mypost[5].awarenessid}</span></label><br />
                                                                            <label>Joining Date: <span>{mydata.mypost != null && mydata.mypost[5].date.split('T')[0]}</span></label><br />
                                                                            {/* <label>Is Approved: <span>{mydata.mypost[5].IsApproved=="Y"?"Approved":"Pending"}</span></label> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className='treelevel3child'>
                                                                <div className='treenode'>
                                                                    <div className='treenodebody'>
                                                                        <div className='defaulttreephoto'>
                                                                            <img src={process.env.PUBLIC_URL + imagedefault} alt=""
                                                                                className="img-circle" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {mydata.mypost != null && mydata.mypost[6] != undefined ?
                                                            <div className='treelevel3child'>
                                                                <div className='treenode' onClick={() => { goBack(mydata.mypost[0].TempID, 1, mydata.mypost[6].TempID) }}>
                                                                    <div className='treenodeheader'>
                                                                        <label>{mydata.mypost[6].ApplicantName}</label>
                                                                        <label><span>{mydata.mypost[6].TempID}</span></label>
                                                                    </div>

                                                                    <div className='treenodebody'>
                                                                        <div className='treephoto'>
                                                                            <img src={mydata.mypost != null && mydata.mypost.length > 0 ? imagefromdb + mydata.mypost[6].ApplicantPhoto : process.env.PUBLIC_URL + imagedefault} alt=""
                                                                                className="img-circle" />
                                                                        </div>
                                                                        <div className='treeinfo'>
                                                                            <label>Sponsor ID: <span>{mydata.mypost[6].sponsorid}</span></label><br />
                                                                            <label>Awareness ID:<span>{mydata.mypost[6].awarenessid}</span></label><br />
                                                                            <label>Joining Date: <span>{mydata.mypost != null && mydata.mypost[6].date.split('T')[0]}</span></label><br />
                                                                            {/* <label>Is Approved: <span>{mydata.mypost[6].IsApproved=="Y"?"Approved":"Pending"}</span></label> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className='treelevel3child'>
                                                                <div className='treenode'>
                                                                    <div className='treenodebody'>
                                                                        <div className='defaulttreephoto'>
                                                                            <img src={process.env.PUBLIC_URL + imagedefault} alt=""
                                                                                className="img-circle" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <Nodatafound />
                                            }
                                        </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
<Gotohome mydata={mydata}/>
        </div>
    )
}