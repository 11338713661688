import React, { useState, useEffect, useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
import { Gotohome } from '../Commonfiles/checkfortoken';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

function DsproductList() {

	const [mydata, setmydata] = useReducer(myreducer, initialstate);



	async function loaddata() {

		fetchbygetmethod(setmydata, 'productlist', { tempid: localStorage.getItem("tempID") });
	}

	useEffect(() => { loaddata(); }, []);

	function Downloadpdf() {

		const doc = new jsPDF();

		// Get current date and time
		const date = new Date();
		const formattedDate = date.toLocaleDateString();
		const formattedTime = date.toLocaleTimeString();

		// Add heading
		doc.setFontSize(30);
		doc.setTextColor('Red');
		doc.text("JMVD Product List", 60, 27); // Adjust the coordinates as needed

		// Add date and time
		doc.setFontSize(10);
		doc.setTextColor('Blue');
		doc.text(`Date: ${formattedDate}`, 14, 22);
		doc.text(`Time: ${formattedTime}`, 14, 27);

		// Add some space between the heading and the table
		const finalY = 30;

		// Add table
		doc.autoTable({
			html: '#datatable-buttons',
			startY: finalY
		});

		doc.save(`Product-List_${formattedDate}_${formattedTime}.pdf`);

	}

	return (
		<div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<div class="form-group">
							<div>

								<button type="submit" onClick={Downloadpdf}
									class="btn btn-primary waves-effect waves-light">
									Download</button>

							</div>
						</div>

					</div>
				</div>

				<div class="page-content-wrapper ">
					<div class="container">


						<div class="row" id='downloadpdf'>
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-b-30 m-t-0" style={{ textAlign: 'center', color: 'red', fontSize: 60, marginTop: 5 }}>JMVD Product List</h4>
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>

												<tr>
													<th>S.No</th>
													<th>Product Name</th>
													<th>M.R.P.</th>
													<th>S.P.</th>


													<th>B.V.</th>



												</tr>
											</thead>
											<tbody>

												{mydata.initialstage==true?<></>:  mydata.myloading == true ? <Spinner />
													: mydata.myerror == true ? <Nodatafound />
														:
														mydata.mypost.length > 0 &&
														mydata.mypost.map((row, index) => {

															return (
																<tr>
																	<td>{index + 1}</td>
																	<td>{row.ItemName}</td>
																	<td>{row.MRP}</td>
																	<td>{row.storeSellingPrice}</td>


																	<td>{row.businessvolume}</td>


																</tr>

															)
														})

												}
											</tbody>

										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>


			</div>
			<Gotohome mydata={mydata} />
		</div>
	)



}

export default DsproductList;