import React, { useReducer, useState } from 'react';
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod } from '../../Commonfiles/Myreducer';
import Spinner from '../../Commonfiles/spinner';
import Nodatafound from '../../Commonfiles/nodatafound';
import { Gotohome } from '../../Commonfiles/checkfortoken';

function AdminChangePassword() {
    const [data, setdata] = useReducer(myreducer, initialstate);
    const [mydata, setmydata] = useReducer(myreducer, initialstate);
    const [form, setForm] = useState({
        userid: '',
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    });

    async function loaddata(


        
    ) {
        var userid = document.getElementById('userid');
        //console.log("Loading data for UserID:", userid);

        // Fetch data from API
        const result = await fetchbygetmethod(setdata, 'Admingetchangepassword', { userid:userid.value });
        
        // Check the server's response
        console.log("Server Response:", result);

        if (result && result.data) {
            console.log("Fetched data:", result.data);
            return result.data;
        } else {
            console.log("No data fetched or data is invalid");
            return null;
        }
    }

    async function handleSubmit() {
        const { userid, oldPassword, newPassword, confirmPassword } = form;

        // Check if new password and confirmation match
        if (newPassword !== confirmPassword) {
            alert('Confirm Password Does Not Match.');
            return;
        }

        // Fetch existing password
        const fetchedData = await loaddata(userid);

        // Debugging: Check what was fetched
        console.log("Fetched Password:", fetchedData ? fetchedData.upassword : "No Data");
        console.log("Entered Old Password:", oldPassword);

        // Compare the fetched password with the entered old password
        if (fetchedData && fetchedData.upassword === oldPassword) {
            try {
                console.log("Updating user password...");
                const jsonobj = {
                    userid,
                    upassword: oldPassword,
                    newpassword: newPassword,
                };

                // Attempt to update the password
                const userResponse = await fetchbyputmethod(setmydata, 'updatepasswordadmin', jsonobj);
                console.log("Password updated:", userResponse);

                alert("Your Password has been changed successfully");
                window.location.reload();
            } catch (error) {
                console.error("Error during password update:", error);
            }
        } else {
            alert('Old Password is incorrect.');
            console.log("Comparison failed: fetchedData", fetchedData);
        }
    }

    function handleChange(e) {
        const { id, value } = e.target;
        setForm(prevForm => ({ ...prevForm, [id]: value }));
    }

    return (
        <div className="content-page">
            <div className="content">
                <div className="">
                    <div className="page-header-title">
                        <h4 className="page-title">Change Password</h4>
                    </div>
                </div>

                <div className="page-content-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="panel panel-primary">
                                    <div className="panel-body">
                                        <h4 className="m-t-0 m-b-30">Change Password</h4>
                                        <hr width="100%" />

                                        <div className="row">
                                            <div className="col-sm-6 col-xs-12">
                                                <div className="m-t-20">
                                                    <form className="">
                                                        <div className="form-group">
                                                            <label>User ID</label>
                                                            <input
                                                                type='text'
                                                                className="form-control"
                                                                placeholder="Type in User ID"
                                                                id='userid'
                                                                value={form.userid}
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Old Password</label>
                                                            <input
                                                                type='password'
                                                                className="form-control"
                                                                placeholder="Type in Old Password"
                                                                id='oldPassword'
                                                                value={form.oldPassword}
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-xs-12">
                                                <div className="m-t-20">
                                                    <form className="">
                                                        <div className="form-group">
                                                            <label>New Password</label>
                                                            <input
                                                                type='password'
                                                                className="form-control"
                                                                placeholder="Type in New Password"
                                                                id='newPassword'
                                                                value={form.newPassword}
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Confirm Password</label>
                                                            <div className="m-t-10">
                                                                <input
                                                                    type="password"
                                                                    className="form-control"
                                                                    placeholder="Re-Type Password"
                                                                    id='confirmPassword'
                                                                    value={form.confirmPassword}
                                                                    onChange={handleChange}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <div>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary waves-effect waves-light"
                                                                    style={{ marginLeft: 300 }}
                                                                    onClick={handleSubmit}
                                                                >
                                                                    Submit
                                                                </button>
                                                                <button
                                                                    type="reset"
                                                                    className="btn btn-default waves-effect m-l-5"
                                                                    style={{ marginLeft: 5 }}
                                                                    onClick={() => setForm({
                                                                        userid: '',
                                                                        oldPassword: '',
                                                                        newPassword: '',
                                                                        confirmPassword: ''
                                                                    })}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="footer"> © 2016 WebAdmin - All Rights Reserved. </footer>
            <Gotohome mydata={data} />
        </div>
    );
}

export default AdminChangePassword;
