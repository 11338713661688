import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Adminchangepassword from './Admin/User Management/Adminchangepassword';
import Admincreateuser from './Admin/User Management/Admincreateuser';
import Adminchangepermission from './Admin/User Management/Adminchangepermission';
import Itemcategory from './Admin/Inventory Master/Inventorymasteritemcategory';
import Adminupdateuserlogin from './Admin/User Management/Adminupdateuserlogin';
import Adminprogramapprove from './Admin/User Management/Adminprogramapprove';
import Adminpanelapprove from './Admin/User Management/Adminpanelapprove';
import Adminpaymentdetails from './Admin/User Management/Adminpaymentdetail';
import Admintransportdetail from './Admin/User Management/Admintransportdetail';
import Admincomissionmaster from './Admin/Comission Tax/Admincomissionmaster';
import Admintaxmaster from './Admin/Comission Tax/Admintaxmaster';
import Itemsubcategory from './Admin/Inventory Master/Inventorymasteritemsubcategory';

import Itemmaster from './Admin/Inventory Master/Inventorymasteritemmaster';
import Unitmaster from './Admin/Inventory Master/Inventorymasterunitmaster'
import Companymaster from './Admin/Ordermaster/Companymaster'
import POmaster from './Admin/Ordermaster/POmaster'
import Vendormaster from './Admin/Ordermaster/Vendormaster'
import Adminitemdetails from './Admin/Comission Tax/Adminitemdetails';
import Admingenerateinvoicecode from './Admin/Comission Tax/Admingenerateinvoicecode';
import Admincreditpaymentmaster from './Admin/Comission Tax/Admincreditpaymentmaster';
import Adminpurchasevoucher from './Admin/Inventory Transaction/Adminpurchasevoucher';
import Adminsalevoucher from './Admin/Inventory Transaction/Adminsalevoucher';
import Directseller from './Admin/Directsellerpayout/Directseller';
import DSpersonaldetails from './Admin/Directsellerpayout/Directsellerprofile';
import Admindamageitem from './Admin/Inventory Transaction/Admindamageitem';
import Adminlistofarea from './Admin/Inventory Reports/AdminListofarea';
import Adminstock from './Admin/Inventory Reports/Adminstock';
import Adminbranchdepotsale from './Admin/Inventory Reports/Adminbranchdepotsale';
import Adminconsolidatebusiness from './Admin/Inventory Reports/Adminconsolidatebusiness';
import AdminCompanysalepurchase from './Admin/Inventory Reports/AdminCompanysalepurchase';
import Adminbusinessreport from './Admin/Inventory Reports/Adminbusinessreport';
import Admindoublebilling from './Admin/Inventory Reports/Admindoublebilling';
import Admincompanysaletransfer from './Admin/Inventory Reports/Admincompanysaletransfer';
import Admintreebreak from './Admin/Inventory Reports/Admintreebreak';
import Adminpaymentinfo from './Admin/Inventory Reports/Adminpaymentinfo';
import Adminpaymentledger from './Admin/Inventory Reports/Adminpaymentledger';
import Adminviewcredit from './Admin/Inventory Reports/Adminviewcredit';
import Adminstockafterturnover from './Admin/Inventory Reports/Adminstockafterturnover';
import Adminviewprevious from './Admin/Inventory Reports/Adminviewprevious';
import Admindsmonthlypurchasing from './Admin/Inventory Reports/Admindsmonthlypurchasing';
import Adminstoremonthlyselling from './Admin/Inventory Reports/Adminstoremonthlyselling';
import Adminstoremonthlyreturn from './Admin/Inventory Reports/Adminstoremonthlyreturn';
import Admindownline from './Admin/Inventory Reports/Admindownline';
import AdminTDSreport from './Admin/Inventory Reports/AdminTDSreport';
import AdminDsApprovedKycList from './Admin/Inventory Reports/AdminDsApprovedKycList';
import AdminDspendingKycList from './Admin/Inventory Reports/AdminDspendingKycList';
import AdminDsRejectKycList from './Admin/Inventory Reports/AdminDsRejectKycList';
import AdminStatewiseDsPurchasing from './Admin/Inventory Reports/AdminStatewiseDsPurchasing';
import AdminPowerLine from './Admin/Inventory Reports/AdminPowerLine';
import AdminApowerline from './Admin/Inventory Reports/AdminApowerline';
import AdminStoreDownLinePurchasing from './Admin/Inventory Reports/AdminStoreDownLinePurchasing';
import AdminMonthlyDSJoining from './Admin/Inventory Reports/AdminMonthlyDSJoining';
import AdminDeductStockReport from './Admin/Inventory Reports/AdminDeductStockReport';
import Adminpaymenttransferreport from './Admin/Inventory Reports/Adminpaymenttransferreport';
import AdminMonthwisePurchasingReport from './Admin/Inventory Reports/AdminMonthwisePurchasingReport';
import AdminBDSStockReport from './Admin/Inventory Reports/AdminBDSStockReport';
import Admincompanysalebd from './Admin/Inventory Reports/Admincompanysalebd';
import AdminBusinessBuildingClub from './Admin/Inventory Reports/AdminBusinessBuildingClub';
import AdminDownlineRepurchaseIncome from './Admin/Inventory Reports/AdminDownlineRepurchaseIncome';
import AdminOpenChoiceBillReport from './Admin/Inventory Reports/AdminOpenChoiceBillReport';
import AdminDreamclubBillReport from './Admin/Inventory Reports/AdminDreamclubBillReport';
import AdminAddEditCategorypage from './Admin/Inventory Master/AdminAddEditCategorypage';
import AdminItemSubCategory from './Admin/Inventory Master/AdminItemSubCategory';
import AdminAddEditUnit from './Admin/Inventory Master/AdminAddEditUnit';
import AdminAddEditItem from './Admin/Inventory Master/AdminAddEditItem';
import AdminAddEditPannelComission from './Admin/Comission Tax/AdminAddEditPannelComission';
import AdminAddEditTaxMaster from './Admin/Comission Tax/AdminAddEditTaxMaster';
import AdminAddEditCompany from './Admin/Ordermaster/AdminAddEditCompany';
import AdminAddEditVendor from './Admin/Ordermaster/AdminAddEditVendor';
import AdminAddEditPurchaseOrder from './Admin/Ordermaster/AdminAddEditPurchaseOrder';
import AdminAddEditPurchaseVoucher from './Admin/Inventory Transaction/AdminAddEditPurchaseVoucher';
import AdminAddEditSaleInvoice from './Admin/Inventory Transaction/AdminAddEditSaleInvoice';
import Adminsearchawareness from './Admin/Directsellerpayout/Adminsearchawareness';
import AdminDsBusinessVolume from './Admin/Directsellerpayout/AdminDsBusinessVolume';
import AdminDsmonthlystatement from './Admin/Directsellerpayout/AdminDsmonthlystatement';
import AdminRepurchaseandFreeItems from './Admin/Directsellerpayout/AdminRepurchase&FreeItems';
import AdminGeneratePayout from './Admin/Directsellerpayout/AdminGeneratePayout';
import AdminViewCommision from './Admin/Directsellerpayout/AdminViewCommision';
import AdminViewRoyality from './Admin/Directsellerpayout/AdminViewRoyality';
import AdminDSUncompleteProfile from './Admin/Directsellerpayout/AdminDSUncompleteProfile';
import ViewOrder from './Admin/Ordermaster/ViewOrder';




export default function Adminroutes() {

    return (
        <Routes>
            {console.log("adminroutes")}
              <Route path='/' element={<Adminpanelapprove/>} />
           <Route path='Adminchangepassword' element={<Adminchangepassword />} /> 
            <Route path='Admincreateuser' element={<Admincreateuser />} /> 
            <Route path='Adminchangepermission' element={<Adminchangepermission/>} />
            <Route path='Adminupdateuserlogin' element={<Adminupdateuserlogin/>} />
            <Route path='Adminprogramapprove' element={<Adminprogramapprove/>} />
            <Route path='Adminpanelapprove' element={<Adminpanelapprove/>} />
          
            <Route path='Adminpaymentdetails' element={<Adminpaymentdetails/>} />
            <Route path='Admintransportdetail' element={<Admintransportdetail/>}/>
            <Route path='Itemcategory' element={<Itemcategory/>} />
            <Route path='Admincomissionmaster' element={<Admincomissionmaster/>} />
            <Route path='Admintaxmaster' element={<Admintaxmaster/>} />
            <Route path='Adminitemdetails' element={<Adminitemdetails/>} />
            <Route path='Admingenerateinvoicecode' element={<Admingenerateinvoicecode/>}/>
            <Route path='Admincreditpaymentmaster' element={<Admincreditpaymentmaster/>} />
            <Route path='Itemsubcategory' element={<Itemsubcategory/>} />
            <Route path='Companymaster' element={<Companymaster/>} />
            <Route path='Vendormaster' element={<Vendormaster/>} />
            <Route path='POmaster' element={<POmaster/>} />
            <Route path='ViewOrder' element={<ViewOrder/>}/>
            <Route path='Unitmaster' element={<Unitmaster/>} />
            <Route path='Itemmaster' element={<Itemmaster/>} />
            <Route path='Adminpurchasevoucher' element={<Adminpurchasevoucher/>}/>
            <Route path='Adminsalevoucher' element={<Adminsalevoucher/>} />
            <Route path='Directseller' element={<Directseller/>} />
            <Route path='DSpersonaldetails' element={<DSpersonaldetails/>} />
            <Route path='Admindamageitem' element={<Admindamageitem/>} />
            <Route path='Adminlistofarea' element={<Adminlistofarea/>} />
            <Route path='Adminstock' element={<Adminstock/>} />
            <Route path='AdminCompanysalepurchase' element={<AdminCompanysalepurchase/>} />
            <Route path='Adminbranchdepotsale' element={<Adminbranchdepotsale/>} />
            <Route path='Adminconsolidatebusiness' element={<Adminconsolidatebusiness/>} />
            <Route path='Adminbusinessreport' element={<Adminbusinessreport/>}/>
            <Route path='Admindoublebilling' element={<Admindoublebilling/>}/>
            <Route path='Admincompanysaletransfer' element={<Admincompanysaletransfer/>}/>
            <Route path='Admintreebreak' element={<Admintreebreak/>}/>
            <Route path='Adminpaymentinfo' element={<Adminpaymentinfo/>}/>
            <Route path='Adminpaymentledger' element={<Adminpaymentledger/>} />
            <Route path='Adminviewcredit' element={<Adminviewcredit/>} />
            <Route path='Adminstockafterturnover' element={<Adminstockafterturnover/>}/>
            <Route path='Adminviewprevious' element={<Adminviewprevious/>} />
            <Route path='Admindsmonthlypurchasing' element={<Admindsmonthlypurchasing/>}/>
            <Route path='Adminstoremonthlyselling' element={<Adminstoremonthlyselling/>}/>
            <Route path='Adminstoremonthlyreturn' element={<Adminstoremonthlyreturn/>}/>
            <Route path='Admindownline' element={<Admindownline/>}/>
            <Route path='AdminTDSreport' element={<AdminTDSreport/>}/>
            <Route path='AdminDsApprovedKycList' element={<AdminDsApprovedKycList/>}/>
            <Route path='AdminDspendingKycList' element={<AdminDspendingKycList/>}/>
            <Route path='AdminDsRejectKycList' element={<AdminDsRejectKycList/>}/>
            <Route path='AdminStatewiseDsPurchasing' element={<AdminStatewiseDsPurchasing/>}/>
            <Route path='AdminPowerLine' element={<AdminPowerLine/>}/>
            <Route path='AdminApowerline' element={<AdminApowerline/>}/>
            <Route path='AdminStoreDownLinePurchasing' element={<AdminStoreDownLinePurchasing/>}/>
            <Route path='AdminMonthlyDSJoining' element={<AdminMonthlyDSJoining/>}/>
            <Route path='AdminDeductStockReport' element={<AdminDeductStockReport/>}/>
            <Route path='Adminpaymenttransferreport' element={<Adminpaymenttransferreport/>}/>
            <Route path='AdminMonthwisePurchasingReport' element={<AdminMonthwisePurchasingReport/>}/>
            <Route path='AdminBDSStockReport' element={<AdminBDSStockReport/>}/>
            <Route path='Admincompanysalebd' element={<Admincompanysalebd/>}/>
            <Route path='AdminBusinessBuildingClub' element={<AdminBusinessBuildingClub/>}/>
            <Route path='AdminDownlineRepurchaseIncome' element={<AdminDownlineRepurchaseIncome/>}/>
            <Route path='AdminOpenChoiceBillReport' element={<AdminOpenChoiceBillReport/>}/>
            <Route path='AdminDreamclubBillReport' element={<AdminDreamclubBillReport/>}/>
            <Route path='AdminAddEditCategorypage' element={<AdminAddEditCategorypage/>}/>
            <Route path='AdminItemSubCategory' element={<AdminItemSubCategory/>}/>
            <Route path='AdminAddEditUnit' element={<AdminAddEditUnit/>} />
            <Route path='AdminAddEditItem' element={<AdminAddEditItem/>}/>
            <Route path='AdminAddEditPannelComission' element={<AdminAddEditPannelComission/>}/>
            <Route path='AdminAddEditTaxMaster' element={<AdminAddEditTaxMaster/>}/>
            <Route path='AdminAddEditCompany' element={<AdminAddEditCompany/>}/>
            <Route path='AdminAddEditVendor' element={<AdminAddEditVendor/>}/>
            <Route path='AdminAddEditPurchaseOrder' element={<AdminAddEditPurchaseOrder/>}/>
            <Route path='AdminAddEditPurchaseVoucher' element={<AdminAddEditPurchaseVoucher/>}/>
            <Route path='AdminAddEditSaleInvoice' element={<AdminAddEditSaleInvoice/>}/>
            <Route path='Adminsearchawareness' element={<Adminsearchawareness/>}/>
            <Route path='AdminDsBusinessVolume' element={<AdminDsBusinessVolume/>}/>
            <Route path='AdminDsmonthlystatement' element={<AdminDsmonthlystatement/>}/>
            <Route path='AdminRepurchaseandFreeItems' element={<AdminRepurchaseandFreeItems/>}/>
            <Route path='AdminGeneratePayout' element={<AdminGeneratePayout/>}/>
            <Route path='AdminViewCommision' element={<AdminViewCommision/>}/>
            <Route path='AdminViewRoyality' element={<AdminViewRoyality/>}/>
            <Route path='AdminDSUncompleteProfile' element={<AdminDSUncompleteProfile/>}/>
            

        </Routes>
    )
}