import React, { useState,useEffect , useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
import {Gotohome} from '../Commonfiles/checkfortoken';
function DSReportawarenessincomedetail()
{
  //  alert("enrter iad");
    const [value,setvalue]=useState(0);
    const [mydata,setmydata]=useReducer(myreducer, initialstate);
    let z;
    async   function loaddata()
    {
             
        fetchbygetmethod(setmydata, 'iad', { id:localStorage.getItem("applicantID") }); 
            
    }

 
    useEffect(()=>{
      
        loaddata();
       
           
    },[])

    return(<div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Reports </h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">Income Awareness Details</h4>
                                <hr width="100%"/>
                                <div className="row">
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                                <div className="form-group">
                                                    <label>Month</label>
                                                    <select className="form-control">
                                                      <option value="01">January</option>
                                                                <option value="02">February</option>
                                                                <option value="03">March</option>
                                                                <option value="04">April</option>
                                                                <option value="05">May</option>
                                                                <option value="06">June</option>
                                                                <option value="07">July</option>
                                                                <option value="08">August</option>
                                                                <option value="09">September</option>
                                                                <option value="10">October</option>
                                                                <option value="11">November</option>
                                                                <option value="12">December</option>
                                                    </select>
                                                </div>
                                                
                                                
                                            
                                                <div className="form-group">
                                                    <div>
                                                        <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light">
                                                            Submit</button>
                                                        <button type="reset"
                                                            className="btn btn-default waves-effect m-l-5">
                                                            Cancel</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                        
                                        <div className="m-t-20">
                                            <form action="#">
                                                <div className="form-group">
                                                    <label>Year</label>
                                                    <select className="form-control"><option>2020</option>
                                                        <option>2021</option>
                                                        <option>2022</option>
                                                        <option>2023</option>
                                                        <option selected='true'>2024</option>
                                                    </select>
                                                </div>
                                            
                                            
                                                
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-b-30 m-t-0">Results </h4>
                                <table id="datatable-buttons"
                                    className="table table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>DSID</th>
                                            <th>Name</th>
                                            <th>MobileNO</th>
                                            <th>Commision</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                                    
                                      {mydata.initialstage==true?<></>: mydata.myloading == true ? <Spinner />
											: mydata.myerror == true ? <Nodatafound />
												:
												mydata.mypost.length > 0 &&
												mydata.mypost.map((data,index)=>{

                                         return    <tr>
                                         <td>{index+1}</td>
                                         <td>{data.TempID}</td>
                                         <td>{data.ApplicantName}</td>
                                         <td>{data.MobileNo}</td>
                                         <td>{data.commision}</td>
                                         
                                     </tr>

                                      })}
                                    
                                       
                                     
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <Gotohome mydata={mydata}/>
</div>)
}

export default DSReportawarenessincomedetail;

