import {useState,useEffect,React} from 'react'

function Admincreditpaymentmaster()
{
	 let joiningdata=[{AwarenessNo:"ds1234",AwarenessName:"xyz",
                     SponsorNo:'ds23456',SponsorName:'ghy',
					 Position:'lest',Status:'y',
					 JoiningDate:'10/05/22', idcard:'na' }]

	const [data,setdata]=useState([]);

	function loaddata()
	{
		setdata(joiningdata);
	}	

	useEffect(()=>{loaddata()})


    return(
        <div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Credit Balance</h4>
						
					</div>
				</div>
				
				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Credit Balance</h4>
										<hr width="100%" />
										{/* { data.length>0 &&
										  data.map((mydata,index)=>{
											return( */}
										<div class="row">
										
												<div class="col-sm-6 col-xs-12">
											
												<div class="m-t-20">
													<form class="" action="#">
														<div class="form-group">
															<label>Payment Code</label>
															 <label class="form-control">
                                                             {/* {mydata.AwarenessNo} */}
                                                             </label>
														</div>
														<div class="form-group">
															<label>Holder ID</label>
															
																<label class="form-control">
                                                                {/* {mydata.SponsorNo} */}
                                                                </label>
															
															
														</div>
														<div class="form-group">
															<label>Payment Amount</label>
															<label class="form-control">
                                                            {/* {mydata.Position} */}
                                                            </label>
														</div>
														
														
														
													
														
														
													</form>
												</div>
											</div>
											<div class="col-sm-6 col-xs-12">
												
												<div class="m-t-20">
													<form action="#">
														<div class="form-group">
															<label>Payment Date</label>
															<label class="form-control">
                                                            {/* {mydata.AwarenessName} */}
                                                            </label>
														</div>
														<div class="form-group">
															<label>Invoice No.</label>
															<label class="form-control">
                                                            {/* {mydata.SponsorName} */}
                                                            </label>
														</div>
														
                                                        </form>
														
														
														
														
														</div>
														
														</div>
														
														
													
												</div>
													{/* )
												})
										} */}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
    )
}

export default Admincreditpaymentmaster;