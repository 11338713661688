import { useState, useEffect, React, useReducer } from 'react';
import { initialstate, myreducer, fetchbygetmethod, fetchbypostmethod ,fetchbyputmethod} from '../../Commonfiles/Myreducer';
function Admintransportdetail()
{

	const [mydata,setmydata]=useReducer(myreducer, initialstate);
	const [editingRow, setEditingRow] = useState(null);
	const [inputValues, setInputValues] = useState({
		invoiceno: '',
		VehicleNo: '',
		transportname: '',
		packets: '',
		GRNO: '',
		Dos: ''
	  });
  const[updatedata,setupdatedata]=useReducer(myreducer, initialstate)


	async function loaddata()
	 {
   
	   var txtinvoiceno=document.getElementById('txtinvoiceno');
	  
   
	   fetchbygetmethod(setmydata, 'Admintransportdetail',{invoiceno:txtinvoiceno.value});
   
	
	  
	  console.log("mydata",mydata);
	 }

	 async function Updatedata() {
		//alert('1');
		const jsonobj = {
		  invoiceno: inputValues.invoiceno,
		  tranportname: inputValues.transportname,
		  vehicleno: inputValues.VehicleNo,
		  packets: inputValues.packets,
		  dos: inputValues.Dos,
		  grno: inputValues.GRNO
		};

		console.log('jsonobj',jsonobj);
	  
		try {
		  await fetchbyputmethod(setupdatedata, 'updatetransportdetailadmin', jsonobj);
            alert("Details Updated Successfully");
		  // Optionally update the UI instead of reloading the page
		//   setEditingRow(null); // Exit editing mode after updating
		} catch (error) {
		  console.error("Error during update:", error);
		}
	  }
	  

	 // Handle the "Edit" button click
	 const handleEditClick = (index, row) => {
		setEditingRow(index);
		setInputValues({ ...row }); // Set initial values for input fields from the row data
	  };
	
	  // Handle the "Update" button click
	//   const handleUpdateClick = () => {
	// 	// Perform the update logic here

	// 	var txtinvoiceno = document.getElementById('txtinvoiceno');
	// 	var txtVehicleNo = document.getElementById('VehicleNo');
	// 	var txttransportname = document.getElementById('transportname');
    //     var txtpackets = document.getElementById('packets');
	// 	var txtGRNO = document.getElementById('GRNO');
	// 	var txtDos = document.getElementById('Dos');
	
	// 	var jsonobj = { invoiceno: txtinvoiceno.value, tranportname: txttransportname.value,vehicleno:txtVehicleNo.value,packets: txtpackets.value,dos: txtDos.value,grno: txtGRNO.value };
		
	// 	try {
	// 		 fetchbyputmethod(setupdatedata, 'updatetransportdetailadmin', jsonobj);
	// 		alert("Details Updated Successfully");
	// 		//window.location.reload(); // Reloads the page after successful registration
	// 		setEditingRow(null);
	// 	} 

	// 	catch (error) {
	// 		console.error("Error during registration:", error);
	// 	}
	// 	 // Exit editing mode
	//   };
	
	  // Handle the "Cancel" button click
	  const handleCancelClick = () => {
		setEditingRow(null); // Exit editing mode
	  };
	
	  const handleInputChange = (e, field) => {
		setInputValues({
		  ...inputValues,
		  [field]: e.target.value
		});
	  };
	 function display(){
		loaddata();
  var data=document.getElementById("tabledata");
   data.style.display='block'

 }




  return(
		<div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Transport Detail</h4>
						
					</div>
				</div>
				
				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Edit Transport Detail</h4>
										<hr width="100%" />
										
										

                                                <div class="row">
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-b-30 m-t-0">Enter Invoice No.</h4>
                                        <div class="form-group">
															{/* <label>IP OR Login ID</label> */}
															<div>
																<div>
																 <input type="text" class="form-control" placeholder="Search......" aria-label="Search" id='txtinvoiceno'  />
															</div>
															</div>
														</div>
                                                        <div class="row">
                                        	<div class="form-group">
															<div>
																<button type="submit" class="btn btn-primary waves-effect waves-light" style={{marginLeft:10}} onClick={display}>
																	Submit</button>
															</div>
														</div> 
                                                       
                                                        	
                                                          
													
												</div>
												<div class="row" style={{display:'none'}} id='tabledata'>
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										{/* <h4 class="m-b-30 m-t-0">Update User Login</h4> */}
                                        <div class="form-group">
															{/* <label>IP OR Login ID</label> */}
															<div>
																{/* <div>
																 <input type="text" class="form-control" placeholder="Search......" aria-label="Search"  />
															</div> */}
															</div>
														</div>
										 <table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
												    <th></th>
												    <th>Invoice No</th>
													<th>Vehicle No</th>
													<th>Transpoert Name</th>
													<th>Packet</th>
													<th>GR NO</th>
													<th>Date of Supply</th>
													
													
												</tr>
											</thead>
											<tbody>
											{mydata.mypost.length > 0 ? (
                                                                mydata.mypost.map((row, index) => (
											
                                              <tr>
													
													<td>
              {editingRow === index ? (
  <>
    <input type="button" value="Update" onClick={Updatedata} />
    <input type="button" value="Cancel" onClick={handleCancelClick} />
  </>
) : (
  <input type="button" value="Edit" onClick={() => handleEditClick(index, row)} />
)}
              </td>
                                                    <td>{row.InvoiceNo}</td>
                                                    <td>
													{editingRow === index ? (
                  <input
                    type="text"
                    style={{ width: 100 }}
					id='VehicleNo'
                    value={inputValues.VehicleNo || row.VehicleNo}
                    onChange={(e) => handleInputChange(e, "VehicleNo")}
                  />
                ) : (
                  <span>{row.VehicleNo}</span>
                )}</td>
                                                    <td>
													{editingRow === index ? (
                  <input
                    type="text"
                    style={{ width: 100 }}
					id='transportname'
                    value={inputValues.transportname || row.transportname}
                    onChange={(e) => handleInputChange(e, "transportname")}
                  />
                ) : (
                  <span>{row.transportname}</span>
                )}</td>
													<td>{editingRow === index ? (
                  <input
                    type="text"
					id='packets'
                    style={{ width: 100 }}
                    value={inputValues.packets || row.packets}
                    onChange={(e) => handleInputChange(e, "packets")}
                  />
                ) : (
                  <span>{row.packets}</span>
                )}</td>
													<td>{editingRow === index ? (
                  <input
                    type="text"
                    style={{ width: 100 }}
					id='GRNO'
                    value={inputValues.GRNO || row.GRNO}
                    onChange={(e) => handleInputChange(e, "GRNO")}
                  />
                ) : (
                  <span>{row.GRNO}</span>
                )}</td>
													<td>{editingRow === index ? (
                  <input
                    type="date"
                    style={{ width: 100 }}
					id='Dos'
                    value={inputValues.Dos || row.Dos}
                    onChange={(e) => handleInputChange(e, "Dos")}
                  />
                ) : (
                  <span>{row.Dos}</span>
                )}</td>
									
												</tr>
											
										))
												
									) : null}
											</tbody>
										
										</table> 
									</div>
								</div>
							</div>
						</div>
								</div>
							</div>
						</div>

       
										
										
											</div>
                                            
										</div>
									</div>
								</div>
							
							</div>
						
							</div>
						</div>
					</div>


				</div>
	)


  
}

export default Admintransportdetail;