import React from 'react';

import {useReducer,useState,useEffect} from 'react'
import { Container, Row, Col, Card, Button, Image } from 'react-bootstrap';
import {  initialstate, myreducer,fetchbygetmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
//import {logo} from '../Images/logo.jpg'


function DSIdCard() {


    const [mydata, setmydata] =useReducer(myreducer,initialstate);

 async function loaddata()
{
  //alert('1');
 	   fetchbygetmethod(setmydata,'idcarddetail',{tempid:localStorage.getItem("tempID")})
     // alert('2');

}	

useEffect(()=>{loaddata()},[])
useEffect(()=>{console.log("mydata",mydata)},[mydata])

  return (
    <div className="content-page">
      <div className="content">
        <div className="">
          <div className="page-header-title">
          <h4 className="page-title">ID Card<br/><br/>
          <Button onClick={()=>{ window.print();}}  className="btn btn-primary">Print</Button>
          </h4>
            
          
        
          </div>
        </div>
        <div className="page-content-wrapper">
          <div className="container">
          
										 
            <div className="row">
              {mydata.initialstage==true?<></>: mydata.myloading==true
                                           ?<Spinner/>
                                           :mydata.myerror==true
                                           ?<Nodatafound/>
                                           :mydata.mypost.length >0 &&
                <div className="col-s-12" >
                  <div className="panel">
                    <div className="panel-body">
					<Container className="my-4">
      <Row className="justify-content-center" style={{  marginTop:20}}>
        <Col md={6}>
          <Card className="p-3 ">
            <Card.Body>
              <div className="text-center mb-3">
                <Image
                  src={require('../Images/logo.jpg')}
                  fluid
                  className="mb-2"
                />
                {/* <h5>{idCardData.companyName}</h5>
                <p>{idCardData.opportunity}</p> */}
                <p style={{marginLeft:102}}>529, GHA/98, Gandhi Nagar, Khurram Nagar Lucknow U.P.</p>
              </div>
              <Row>
                <Col xs={4} className="text-left">
				
                  <Image style={{   marginLeft: 50}}
                    src={'./uploads/'+mydata.mypost[0].ApplicantPhoto}
                    width={100}
                    height={100}
                    className="mb-3"
                  />
				  <div className="text-left" style={{   marginLeft: 167,marginTop:-106}}>
                    <p><strong>DS ID:</strong> {mydata.mypost[0].TempID}</p>
                    <p><strong>Name:</strong> {mydata.mypost[0].ApplicantName}</p>
                    <p><strong>Address:</strong> {mydata.mypost[0].Location}</p>
                    <p><strong>Mobile:</strong>{mydata.mypost[0].MobileNo}</p>
                  </div>
                </Col>
                {/* <Col xs={8}>
                  <div className="text-left">
                    <p><strong>DS ID:</strong> {idCardData.dsId}</p>
                    <p><strong>Name:</strong> {idCardData.name}</p>
                    <p><strong>Address:</strong> {idCardData.address2}</p>
                    <p><strong>Mobile:</strong> {idCardData.mobile}</p>
                  </div>
                </Col> */}
              </Row>
              <div className="text-center mt-4" style={{marginLeft:270}}>
                <Image
                  src={require('../Images/sign.jpg')}
                  width={150}
                  height={50}
                  className="mb-2"
                />
                {/* <p className="mb-0"><strong>{idCardData.authorizedSignatory}</strong></p> */}
                <p className="text-muted">(Authorized Signatory)</p>
              </div>
             
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
   
                    </div>
                  </div>
                </div>
}
            </div>
          
          </div>
        </div>
      </div>
    
    </div>
  );
}

export default DSIdCard;
