import { useState, useEffect, React, useReducer } from 'react';
import { initialstate, myreducer, fetchbygetmethod, fetchbypostmethod } from '../../Commonfiles/Myreducer';
function Adminpaymentdetails()
{


const [mydata,setmydata]=useReducer(myreducer, initialstate);


 async function loaddata()
  {

	var applicanttype=document.getElementById('applicanttype');
	var ispaid=document.getElementById('ispaid');
	var month=document.getElementById('month');
	var year=document.getElementById('year');

	fetchbygetmethod(setmydata, 'AdminPaymentdetail',{Applicanttype:applicanttype.value,month:month.value,year:year.value,Ispaid:ispaid.value});

 
   
   console.log("mydata",mydata);
  }
  //useEffect(()=>{loaddata();});


   return(
            <div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Payment Details
							 </h4>
						
					</div>
				</div>

                
				
				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Payment Details</h4>
										
                                        <div class="row" style={{marginLeft:-18}}>
                                        	<div class="form-group">
															<div>
																
															<label class="form-control">Applicant Type</label>
															<select class="form-control" id='applicanttype'>
															   <option value="BRANCH">BRANCH</option>
                                                                <option value="DEPOT">DEPOT</option>
																<option value="BRANCH">STORE</option>
                                                                <option value="DEPOT">DIRECT SELLER</option>
                                                               
															</select>		
                                                                </div>
														</div> 

														<div class="form-group">
															<div>
																<label class="form-control">Is Paid</label>
															<select class="form-control" id='ispaid'>
															   <option value="Y">YES</option>
                                                                <option value="N">NO</option>
                                                               
															</select>		
                                                                </div>
														</div> 
                                                       </div>
                                                        {/* <div class="row">
                                        	<div class="form-group">
															<div>
																<label style={{marginLeft:10,color:'#5cb45b'}}> IS PAID=
                                                                 <input type="checkbox" id="Y" name="fav_language" style={{marginLeft:15}} />
                                                                 <label for="html">Yes</label>
                                                                  <input type="checkbox" id="N" name="fav_language" style={{marginLeft:15}} />
                                                                 <label for="html">No</label>	
                                                                 </label>		
                                                                </div>
														</div> 
                                                       </div> */}
                                                       
										<div class="row" style={{marginTop:-31}}>
											<div class="col-sm-6 col-xs-12">
                                            <div class="m-t-20">
													<form class="" action="#">
														<div class="form-group">
															<label >Month</label>
															<select class="form-control" id='month'>
															   <option value="01">January</option>
                                                                <option value="02">February</option>
                                                                <option value="03">March</option>
                                                                <option value="04">April</option>
                                                                <option value="05">May</option>
                                                                <option value="06">June</option>
                                                                <option value="07">July</option>
                                                                <option value="08">August</option>
                                                                <option value="09">September</option>
                                                                <option value="10">October</option>
                                                                <option value="11">November</option>
                                                                <option value="12">December</option>
															</select>
														</div>
														
														
													
														<div class="form-group">
															<div>
																<button type="submit" 
																	class="btn btn-primary waves-effect waves-light" style={{marginLeft:10}} onClick={loaddata}>
																	Submit</button>
																<button type="reset"
																	class="btn btn-default waves-effect m-l-5" style={{marginLeft:10}}>
																	Cancel</button>
															</div>
														</div>
													</form>
												</div>
											</div>
											<div class="col-sm-6 col-xs-12">
												
												<div class="m-t-20">
													<form action="#">
														<div class="form-group">
															<label>Year</label>
															<select class="form-control" id='year'>
															<option value='2016'>2016</option>
																<option value='2020'>2020</option>
																<option value='2021'>2021</option>
																<option value='2022'>2022</option>
																<option value='2023'>2023</option>
																<option value='2024'>2024</option>
															</select>
														</div>
													
													
														
													</form>
												</div>
											</div>
										</div>
                                        <div class="row">
                                        	<div class="form-group">
															<div>
																 <label for="html"style={{marginLeft:20}}>Upload Files</label>
                                                                 <input type="file" id="html" name="fav_language" style={{marginLeft:20}} />
                                                                 			
                                                                </div>
                                                                <div>
                                                                    <button type="submit" 
																	class="btn btn-primary waves-effect waves-light" style={{marginLeft:250,marginTop:-58}}>
																	Upload</button>
                                                                </div>
														</div> 
                                                       
                                                       </div>

                                                        
                                                       <div class="row">
                                                        <div class="form-group">
															
															<div>
																<div>
																 <input type="text" class="form-control" placeholder="Search By ID OR Name......" aria-label="Search"  />
															</div>
															</div>
														</div>
                                                       </div>
									</div>
								</div>
							</div>
						</div>
						

					
					
					
					
					
					
					
					
				</div>
			</div>
			</div>
		</div>)
}

export default Adminpaymentdetails;