import { useState, useEffect, React, useReducer } from 'react';
import { initialstate, myreducer, fetchbygetmethod, fetchbypostmethod } from '../../Commonfiles/Myreducer';
import Spinner from '../../Commonfiles/spinner';
import Nodatafound from '../../Commonfiles/nodatafound';

function Admincreateuser() {
    const [data, setdata] = useReducer(myreducer, initialstate);
    const [assigndata, setassigndata] = useReducer(myreducer, initialstate);
    const [mydata, setmydata] = useReducer(myreducer, initialstate);
	async function Insertdata() {
		var txtuserid = document.getElementById('txtuserid');
		var txtusername = document.getElementById('txtusername');
		var txtuserpassword = document.getElementById('txtuserpassword');
		var txtusermobile = document.getElementById('txtusermobile');
		var txtuserrole = document.getElementById('txtuserrole');
		var reenterpwsd = document.getElementById('reenterpwsd');
	
		var jsonobj = {
			userid: txtuserid.value,
			username: txtusername.value,
			userpassword: txtuserpassword.value,
			usermobile: txtusermobile.value,
			userrole: txtuserrole.value
		};
	
		// Validate mobile number
		if (txtusermobile.value.length !== 10) {
			alert("Mobile number must be exactly 10 digits.");
			txtusermobile.focus();
			return;
		}
	
		// Validate password confirmation
		if (txtuserpassword.value !== reenterpwsd.value) {
			alert('Confirm Password Does Not Match.');
			return;
		}
	
		try {
			// Create the user
			console.log("Inserting user data...");
			const userResponse = await fetchbypostmethod(setmydata, 'Admincreateuserinsertdata', jsonobj);
			console.log("User created:", userResponse);
	
			// Collect checked permissions
			const checkedCheckboxes = document.querySelectorAll('input[type="checkbox"]:checked');
			const assignedForms = Array.from(checkedCheckboxes).map(checkbox => {
				return checkbox.getAttribute('data-formid');
			});
	
			// Assign permissions for each checked form
			for (let formid of assignedForms) {
				console.log(`Assigning permission for form ID ${formid}...`);
				const assignResponse = await fetchbypostmethod(setassigndata, 'Adminassigninsertdata', { formid, userid: txtuserid.value });
				console.log("Permission assigned:", assignResponse);
			}
	
			// Check if the data was set correctly
			console.log("Updated data:", setdata);
	
			alert("User Created and Permissions Assigned Successfully");
	
			// Reload the data
			loaddata(); // Call loaddata to refresh the state
			
			// Optionally, reload the page
			// window.location.reload();
		} catch (error) {
			console.error("Error during registration:", error);
		}
	}
	

	

    async function loaddata() {
        fetchbygetmethod(setdata, 'Admincreateuser', {});
    }

    useEffect(() => { loaddata(); }, []);

    return (
        <div className="content-page">
            <div className="content">
                <div className="">
                    <div className="page-header-title">
                        <h4 className="page-title">User Management</h4>
                    </div>
                </div>

                <div className="page-content-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="panel panel-primary">
                                    <div className="panel-body">
                                        <h4 className="m-t-0 m-b-30">User Registration</h4>
                                        <hr width="100%" />

                                        <div className="row">
                                            <div className="form-group">
                                                <div>
                                                    <button type="submit" className="btn btn-primary waves-effect waves-light">
                                                        User Registration
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-xs-12">
                                                <div className="m-t-20">
                                                    <form action="#">
                                                        <div className="form-group">
                                                            <label>User Name </label>
                                                            <input type="text" className="form-control" id="txtusername" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Mobile No</label>
                                                            <input type="text" className="form-control" id="txtusermobile" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Re-enter Password</label>
                                                            <input type="password" className="form-control" id="reenterpwsd" />
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-xs-12">
                                                <div className="m-t-20">
                                                    <form action="#">
                                                        <div className="form-group">
                                                            <label>User Id</label>
                                                            <input type="text" className="form-control" id="txtuserid" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Password</label>
                                                            <input type="password" className="form-control" id="txtuserpassword" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>User Role Name</label>
                                                            <select className="form-control" id="txtuserrole">
                                                                <option value="User">User</option>
                                                                <option value="Admin">Admin</option>
                                                            </select>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label>Assign Permission</label>
                                                    <table id="datatable-buttons" className="table table-striped table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>Sr No.</th>
                                                                <th>Form Id</th>
                                                                <th>Form Name</th>
                                                                <th>Description</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data.myloading ? (
                                                                <Spinner />
                                                            ) : data.myerror ? (
                                                                <Nodatafound />
                                                            ) : data.mypost.length > 0 ? (
                                                                data.mypost.map((row, index) => (
                                                                    <tr key={row.FormId}>
                                                                        <td><input type="checkbox" data-formid={row.FormId} /></td>
                                                                        <td>{index + 1}</td>
                                                                        <td>{row.FormId}</td>
                                                                        <td>{row.FormName}</td>
                                                                        <td>{row.Description}</td>
                                                                    </tr>
                                                                ))
                                                            ) : null}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <div>
                                                    <button type="button" className="btn btn-primary waves-effect waves-light" onClick={Insertdata}>
                                                        Submit
                                                    </button>
                                                    <button type="reset" className="btn btn-default waves-effect m-l-5">
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Admincreateuser;
