import React,{useState,useEffect, useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod } from '../../Commonfiles/Myreducer';
import Spinner from '../../Commonfiles/spinner';
import Nodatafound from '../../Commonfiles/nodatafound';
import {Gotohome} from '../../Commonfiles/checkfortoken';

function AdminDsmonthlystatement()
{

	var [mydata,setmydata]=useReducer(myreducer, initialstate);
	//console.log("mydata",mydata);

async function loaddata(event)
{
event.preventDefault();
var month=document.getElementById('month').value;
var year=document.getElementById('year').value;
var tempid=document.getElementById('tempid').value;

fetchbygetmethod(setmydata, 'monthlystate', { month:month,year:year,dsid:tempid}); 
//alert('jvggh');
}

useEffect(()=>{if(mydata.myloading==false && mydata.myerror==false)
				 {display();}},[mydata]);

function display(){

var data=document.getElementById("tabledata");
//var data2=document.getElementById("tabledata2");

data.style.display='block';
//data2.style.display='block';


};


return(
	  <div class="content-page">
	  <div class="content">
		  <div class="">
			  <div class="page-header-title">
				  <h4 class="page-title">Monthly Statement </h4>
				  
			  </div>
		  </div>
		  
		  <div class="page-content-wrapper ">
			  <div class="container">
				  <div class="row">
					  <div class="col-sm-12">
						  <div class="panel panel-primary">
							  <div class="panel-body">
								  <h4 class="m-t-0 m-b-30">Monthly Statement</h4>
								  <hr width="100%"/>
								  <div class="row">
								  <div class="col-sm-6 col-xs-12">
									  
									  <div class="m-t-20" >
										  <form class="" action="#">
											  <div class="form-group">
												  <label>DS ID</label>
												  <input type="text" className="form-control" id="tempid"   />
												 
											  </div>
											  
											  
										  
											
										  </form>
									  </div>
								  </div>
								  <div class="col-sm-6 col-xs-12">
									  
									  <div class="m-t-20" >
										  <form class="" action="#">
											  <div class="form-group">
												  <label style={{color:'white'}} >DS ID</label>
												  <input  style={{borderColor:'white'}} className="form-control" id=""   />
												 
											  </div>
											  
											  
										  
											
										  </form>
									  </div>
								  </div>
									 
									  <div class="col-sm-6 col-xs-12">
										  
										  <div class="m-t-20">
											  <form action="#">
												  <div class="form-group">
													  <label>Year</label>
													  <select id='year' class="form-control">
													  <option>--Select--</option>
														  <option>2020</option>
														  <option>2021</option>
														  <option>2022</option>
														  <option>2023</option>
														  <option selected='true' >2024</option>
													  </select>
												  </div>
											  
											  
												  
											  </form>
										  </div>

										 
									  </div>

									  <div class="col-sm-6 col-xs-12">
									  
									  <div class="m-t-20" >
										  <form class="" action="#">
											  <div class="form-group">
												  <label>Month</label>
												  <select id='month' class="form-control">
												  <option>--Select--</option>
													 <option value="01">January</option>
													  <option value="02">February</option>
													  <option value="03">March</option>
													  <option value="04">April</option>
													  <option value="05">May</option>
													  <option value="06">June</option>
													  <option value="07">July</option>
													  <option value="08">August</option>
													  <option value="09">September</option>
													  <option value="10">October</option>
													  <option value="11">November</option>
													  <option value="12">December</option>
												  </select>
											  </div>
											  
											  
										  
											
										  </form>
									  </div>
								  </div>
								  </div>

								  <div class="form-group">
													  <div>
														  <button onClick={(event)=>{loaddata(event);}}
															  class="btn btn-primary waves-effect waves-light" >
															  Submit</button>
														  <button 
															  class="btn btn-default waves-effect m-l-5">
															  Cancel</button>
													  </div>
												  </div>
							  </div>
						  </div>
					  </div>
				  </div>
	  {mydata.initialstage==true?<></>:
		mydata.myloading  ? <Spinner/>
				 :mydata.myerror?<Nodatafound/>
				 :<div class="row" id="tabledata" style={{display:'block'}}>
				 <div class="col-md-12">
				   <div class="panel panel-primary">
					 <div class="panel-body">
					   <h4 class="m-b-30 m-t-0">Results </h4>
					   <table id="datatable-buttons"
						 class="table table-striped table-bordered">
						 <thead>
						   <tr>
								<th>S No.</th>
							 <th>DSNAME</th>
							 <th>DS No</th>
							 <th>Business Value</th>
							 <th>Commission(%)</th>
							 <th>Commission Amt</th>
							 
						   </tr>
						 </thead>
						 
						   
						 
						 
						   
						   {
						   
												  mydata.mypost.length>0 &&
							<tbody>
												<tr>
							 <td>{1}</td>
							 <td>{mydata.mypost[0].DSNAME}</td>
							 <td>{mydata.mypost[0].DSID}</td>
							 <td>{mydata.mypost[0].TotalBusiness}</td>
							 <td>{mydata.mypost[0].TotalBusinessSlab}</td>
							 <td>{mydata.mypost[0].TotalBusinessValue}</td>
							 
						   </tr>
						   <tr>
							 <td>{2}</td>
							 <td>{mydata.mypost[0].LeftNAME}</td>
							 <td>{mydata.mypost[0].LEFTID}</td>
							 <td>{mydata.mypost[0].Leftbusiness}</td>
							 <td>{mydata.mypost[0].LeftBusinessSlab}</td>
							 <td>{mydata.mypost[0].LeftBusinessValue}</td>
							 
						   </tr>
						   <tr>
							 <td>{3}</td>
							 <td>{mydata.mypost[0].RightName}</td>
							 <td>{mydata.mypost[0].RIGHTID}</td>
							 <td>{mydata.mypost[0].Rightbusiness}</td>
							 <td>{mydata.mypost[0].RightBusinessSlab}</td>
							 <td>{mydata.mypost[0].RightBusinessValue}</td>
							 
						   </tr>
							<tr>
							 <td>{4}</td>
							 <td>Selfless</td>
							 <td>{mydata.mypost[0].DSID}</td>
							 <td>{mydata.mypost[0].Selfbusiness}</td>
							 <td>{mydata.mypost[0].SelfBusinessSlab}</td>
							 <td>{mydata.mypost[0].SelfBusinessValue}</td>
							 
						   </tr>
						  
												 
						   </tbody>
												   }
						 
					   </table>
						<table class="table">
	 
	  
	   {
						   
												  mydata.mypost.length>0 &&
		 <tbody>
		   <tr>
			 <td>Ds Commission Amount-</td>
			 <td>{mydata.mypost[0].TotalBusinessValue}</td>
		   </tr>
		   <tr>
			 <td>Less</td>
			 <td>{mydata.mypost[0].TotalValue}</td>
		   </tr>
		   <tr>
			 <td>Diffrence Income Amt.</td>
			 <td>{mydata.mypost[0].ComissionAmt}</td>
		   </tr>
		<tr>
			 <td>Incmome Awareness</td>
			 <td>{mydata.mypost[0].incomeawareness}</td>
		   </tr>
		<tr>
			 <td>Royality Income</td>
			 <td>{mydata.mypost[0].RoyalityIncome}</td>
		   </tr>
		<tr>
			 <td>Technical Income</td>
			 <td>{mydata.mypost[0].TechnicalIncome}</td>
		   </tr>
		<tr>
			 <td>Bonus</td>
			 <td>{mydata.mypost[0].Bonusamt}</td>
		   </tr>
		<tr>
			 <td>Faimly Security</td>
			 <td>{mydata.mypost[0].FaimlySecurity}</td>
		   </tr>
		<tr>
			 <td>Dress Code Bonus</td>
			 <td>{mydata.mypost[0].dcbonus}</td>
		   </tr>
		<tr>
			 <td>Business Growth Bonus</td>
			 <td>{mydata.mypost[0].bgbonus}</td>
		   </tr>
		<tr>
			 <td>Dynamic Team Education Bonus</td>
			 <td>{mydata.mypost[0].dtebonus}</td>
		   </tr>
		<tr>
			 <td>Child Health & Education Bonus</td>
			 <td>{mydata.mypost[0].chebonus}</td>
		   </tr>
	   <tr>
			 <td>Bike Bonus</td>
			 <td>{mydata.mypost[0].bikebonus}</td>
		   </tr>
		   <tr>
			 <td>Tour & Travel Bonus</td>
			 <td>{mydata.mypost[0].ttbonus}</td>
		   </tr>
		   <tr>
			 <td>House Bonus</td>
			 <td>{mydata.mypost[0].housebonus}</td>
		   </tr>
		<tr>
			 <td>Luxury Car Bonus</td>
			 <td>{mydata.mypost[0].lcbonus}</td>
		   </tr>
		<tr>
			 <td>JMVD Brand Ambassador Bonus</td>
			 <td>{mydata.mypost[0].jbabonus}</td>
		   </tr>
		<tr>
			 <td>Saving & Investment Bonus</td>
			 <td>{mydata.mypost[0].sibonus}</td>
		   </tr>
		<tr>
			 <td>Future & Retirement Bonus</td>
			 <td>{mydata.mypost[0].frbonus}</td>
		   </tr>
		<tr>
			 <td>Matching Point Income</td>
			 <td>{mydata.mypost[0].mp}</td>
		   </tr>
	   <tr>
			 <td>Matching Point Income New</td>
			 <td>{mydata.mypost[0].mpnew2024}</td>
		   </tr>
		<tr>
			 <td>Matching Point Awareness</td>
			 <td>{mydata.mypost[0].mpa}</td>
		   </tr>
	   <tr>
			 <td>Matching Point Awareness New</td>
			 <td>{mydata.mypost[0].mpanew2024 }</td>
		   </tr>
		<tr>
			 <td>Direct Income</td>
			 <td>{mydata.mypost[0].di}</td>
		   </tr>
	   <tr>
			 <td>Direct Income New</td>
			 <td>{mydata.mypost[0].dinew2024}</td>
		   </tr>
		<tr>
			 <td>Direct Income Awareness</td>
			 <td>{mydata.mypost[0].dai}</td>
		   </tr>
		<tr>
			 <td>Commission</td>
			 <td>{mydata.mypost[0].fc}</td>
		   </tr>
		<tr>
			 <td>Repurchase Income(10%)</td>
			 <td>{mydata.mypost[0].repur}</td>
		   </tr>
		<tr>
			 <td>Total Commission </td>
			 <td>{mydata.mypost[0].FinalCommision}</td>
		   </tr>
		<tr>
			 <td><strong>TDS Amt ( 5.00 %) </strong></td>
			 <td><strong>{mydata.mypost[0].TDSAmt}</strong></td>
		   </tr>
		<tr>
			 <td><strong>Net Commission </strong></td>
			 <td><strong>{mydata.mypost[0].NetCommission}</strong></td>
		   </tr>
		<tr>
			 <td><strong>Previous Commission </strong></td>
			 <td><strong>{mydata.mypost[0].PreviousCommission}</strong></td>
		   </tr>
		 
		 </tbody>
		}
		{  
		 mydata.mypost.length>0 &&
		 <tfoot>
		   <tr>
			 <td><strong>Net Payable </strong></td>
			 <td><strong>{mydata.mypost[0].NetCommission + mydata.mypost[0].PreviousCommission}</strong></td>
		   </tr>
		 </tfoot>
	   }
	  
	   </table> 
					 </div>
				   </div>
				 </div>
			   </div>
	  }
				  

			  
			  
			  
			  
			  
			  
			  
		  </div>
	  </div>
	  </div>
{/* <Gotohome mydata={mydata}/> */}
  </div>);
}

export default AdminDsmonthlystatement;