import { useState,useEffect ,React} from "react";
import axios from 'axios';

function Admincompanysaletransfer()
{

 var [mydata,setmydata]=useState([]);
    

        async function loaddata()
      {
        var Invoicetype=document.getElementById('Invoicetype').value;
        var fromdate=document.getElementById('txtfromdate').value;
        var todate=document.getElementById('txttodate').value;
        console.log(Invoicetype,+fromdate,+todate);
       var x=await fetch(process.env.REACT_APP_URL+'Admincompanysaletransfer?invoicetype='+Invoicetype+'&Fromdate='+fromdate+'&Todate='+todate);
       
       var y=await x.json();
       var z=JSON.stringify(y.recordset);
       var a=JSON.parse(z);
       setmydata(a);
        console.log("mydata",mydata);
      }
     
      //useEffect(()=>{loaddata();},[]);

function display(){
         loaddata();
   var data=document.getElementById("tabledata");
	data.style.display='block'

  }
   
   
    return(
    <div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Company Sale Transfer Report</h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30"> Sale Transfer Report</h4>
                                {/* <hr width="100%"/> */}
                                <div className="row">
                                
                                
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                <div className="form-group" >
                                                    <label>From Date</label>
                                                    <input id='txtfromdate' type="date" class="form-control" required
																	 />		    
																
                                                </div>
                                                
                                                 <div className="form-group">
                                                    <label>Invoice Type</label>
                                                    <select class="form-control" id="Invoicetype">
                                                               <option value="00">Select</option>
															    <option value="T">Transfer</option>
                                                                <option value="S">Sale</option>
                                                                <option value="TR">Transfer Return</option>
                                                                <option value="SR">Sale Return</option>
                                                                
                                                               

																</select>
                                     
                                    <div className="form-group">
                                                    <div>
                                                        
                                                        <input type="button"
                                                            className="btn btn-primary waves-effect waves-light" value="Submit" style={{marginLeft:700}} onClick={display}  />
                                                             
                                                             <input type="button"
                                                            className="btn btn-default waves-effect waves-light" value="Download" style={{marginLeft:780,marginTop:-58}} />
                                                             
                                                        
                                                    </div>
                                                </div> 
                                    
                                </div>
                                                
                                                
                                            </form>
                                        </div>
                                    </div>
                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                <div className="form-group" >
                                                    <label>To Date</label>
                                                    <input id='txttodate' type="date" class="form-control" required
																	 />		    
																
                                                </div>
                                                
                                                
                                                
                                            </form>
                                        </div>
                                    </div>
                                   

                               



                                <div class="row" style={{display:'none'}} id='tabledata'>
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
                                    <div class="form-group">
															{/* <label>IP OR Login ID</label> */}
															<div>
																<div>
																 <input type="text" class="form-control" placeholder="Search Item......" aria-label="Search"  />
															</div>
															</div>
														</div>
                                                      
										{/* <h4 class="m-b-30 m-t-0">Pannel Comission</h4> */}
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
                                                    <th>Sr No</th>
												    <th>Batch No</th>
												    <th>Item Name</th>
													<th>Quantity</th>
                                                    <th>Rate</th>
													
                                                    
                                                
													
													
													
												</tr>
											</thead>
											<tbody>
												{mydata.length>0 &&
											  mydata.map((row,index)=>{
                                              
                                              return(	
											
                                              <tr>
													
													
                                                    <td>{index+1}</td>
                                                    <td>{row.batchno}</td>
                                                    <td>{row.ItemName}</td>
                                                    <td>{row.qty}</td>
                                                    <td>{row.RATE}</td>
                                                  
                                                   
                                                   
													
									
												</tr>
											
                                             ) 
                                              })
												
                                                }
											</tbody>
										
										</table>
									</div>
								</div>
							</div>
						</div>
                                
                               
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footer"> © 2016 WebAdmin - All Rights
        Reserved. </footer>
        </div>
</div>)
}

export default Admincompanysaletransfer;