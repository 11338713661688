import { useState, useEffect, React, useReducer } from 'react';
import { initialstate, myreducer, fetchbygetmethod, fetchbypostmethod } from '../../Commonfiles/Myreducer';

function Adminsearchawareness()
{

    const [mydata, setmydata] = useReducer(myreducer, initialstate);

    async function loaddata() {

        var dsid=document.getElementById('txtdsid').value;

        fetchbygetmethod(setmydata, 'Adminsearchawareness', {DSID:dsid});
        console.log(setmydata);
    }

    function display(){
        loaddata();
       var data=document.getElementById("tabledata");
        data.style.display='block'
    
      }
    
  
    return(<div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Search Awareness Report</h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">Search Awareness </h4>
                                <hr width="100%"/>
                                <div className="row">
                               
                                
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                <div className="form-group">
                                                    <label>Direct Seller ID</label>
                                                    <input type="text" placeholder="Enter Ds Id" class="form-control" id='txtdsid' />
															
																 

															
                                                </div>
                                                
                                              <div className="form-group">
                                                    <div>
                                                        
                                                        <input type="submit"
                                                            className="btn btn-primary waves-effect waves-light" value='Submit' style={{marginLeft:5}} onClick={()=> {display();}}/>
                                                             
                                                             
                                                    </div>
                                                </div>  
                                                
                                            </form>
                                        </div>
                                    </div>
                                    
                                    
                                </div>

                               



                                <div class="row" style={{display:'none'}} id='tabledata'>
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
                                    
										{/* <h4 class="m-b-30 m-t-0">Pannel Comission</h4> */}
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
												    <th>Sr No.</th>
												    <th>User Id</th>
													<th>Applicant Name</th>
                                                    <th>Awareness Id</th>
                                                    <th>Awareness Name</th>
													<th>Month</th>
                                                    <th>Year</th>
                                                    
                                                
													
													
													
												</tr>
											</thead>
											<tbody>
												{mydata.mypost.length>0 &&
											  mydata.mypost.map((row,index)=>{
                                              
                                              return(	
											
                                              <tr>
													
													
                                                    <td>{index+1}</td>
                                                    <td>{row.TempID}</td>
                                                    <td>{row.ApplicantName}</td>
                                                    <td>{row.AwareID}</td>
                                                    <td>{row.AwareName}</td>
                                                    <td>{row.Month}</td>
                                                    <td>{row.Year}</td>
                                                   
                                                   
													
									
												</tr>
											
                                             ) 
                                              })
												
                                                }
											</tbody>
										
										</table>
									</div>
								</div>
							</div>
						</div>
                                
                               
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footer"> © 2016 WebAdmin - All Rights
        Reserved. </footer>
</div>)
}

export default Adminsearchawareness;