import { useState, useEffect, React, useReducer } from 'react';
import { initialstate, myreducer, fetchbygetmethod, fetchbypostmethod } from '../../Commonfiles/Myreducer';

function AdminBDSStockReport()
{
    const [mydata,setmydata]=useReducer(myreducer, initialstate);
    const totalSumBV = mydata.mypost.reduce((sum, row) => sum + (row.BusinessValue || 0), 0);
    const totalSumstock = mydata.mypost.reduce((sum, row) => sum + (row.stock || 0), 0);

      async function loaddata()
   {
    var selectedtype=document.getElementById('selecttype').value;
    

    fetchbygetmethod(setmydata, 'AdminBDSStockReport',{type:selectedtype});

     console.log("mydata",mydata);
   }
    
  
    function display(){
        loaddata();
  var data=document.getElementById("tabledata");
   data.style.display='block'

 }
    return(<div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Stock Reports</h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">Stock Report</h4>
                                <hr width="100%"/>
                                <div className="row">

                                <div className="col-sm-6 col-xs-12">
                                    
                                    <div className="m-t-20">
                                        <form className="" action="#">

                                <div className="form-group">
                                                    <label>Panel Name</label>
                                                    <select class="form-control" id='selecttype'>
															     <option>Branch</option>
																<option>Depot</option>
																<option>Store</option>
																
																
																</select>
                                                </div>

                                                </form>
                                        </div>
                                        
                                        
                                    </div>

                                    
                              
                                </div>

                               
                                              
                               <div className="form-group">
                                                    <div>

                                                    <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:5}} onClick={() => { display(); loaddata(); }}>
                                                            Submit</button>
                                                        <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:5}}>
                                                            Download</button>
                                                        
                                                    </div>
                                                </div> 


                                <div class="row" style={{display:'none'}} id='tabledata'>
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
                                    <div class="form-group">
															{/* <label>IP OR Login ID</label> */}
															<div>
																<div>
																 <input type="text" class="form-control" placeholder="Search Item......" aria-label="Search"  />
															</div>
															</div>
														</div>
										{/* <h4 class="m-b-30 m-t-0">Pannel Comission</h4> */}
										 <table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
												    <th>Sr No</th>
												    <th>Applicant Id</th>
													<th>Applicant Name</th>
													<th>City Name</th>
													<th>BV</th>
                                                    <th>Stock</th>
                                                   
                                                    
                                                
													
													
													
												</tr>
											</thead>
											<tbody>
												{mydata.mypost.length>0 &&
											  mydata.mypost.map((row,index)=>{


                                              
                                              
                                              return(	
											
                                              <tr>
													
													
                                                    <td>{index+1}</td>
                                                    <td>{row.tempid}</td>
                                                    <td>{row.applicantname}</td>
                                                    <td>{row.cityname}</td>
                                                    <td>{row.BusinessValue}</td>
                                                    <td>{row.stock}</td>
                                                    
                                                   
                                                   
													
									
												</tr>
											
                                             ) 
                                              })
												
                                                }
											</tbody>
										   <tfoot>

                                           <tr>
													
													
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td><strong >Total = {totalSumBV}</strong> </td>
                                                    <td><strong >Total ={totalSumstock}</strong></td>
                                                    
                                                   
                                                   
													
									
												</tr>

                                           </tfoot>
										</table> 
									</div>
								</div>
							</div> 
						</div>
                                
                               
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footer"> © 2016 WebAdmin - All Rights
        Reserved. </footer>
</div>)
}

export default AdminBDSStockReport;