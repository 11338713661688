import React, { useState, useEffect, useReducer, useRef } from "react";
import './invoice.css'
import logo from './logo.png';
import { fetchbygetmethod, initialstate, myreducer } from "../src/Commonfiles/Myreducer";
import { Gotohome } from './Commonfiles/checkfortoken';

function Invoice(props) {
  //alert('i am in invoice');
  const invoiceRef = useRef();
  const [data, setdata] = useReducer(myreducer, initialstate);
  const [invoicedetaildata, setinvoicedetaildata] = useReducer(myreducer, initialstate);


  async function loaddata() {
    // alert("props1",props);
    //console.log("props",props.invoiceno);
    var querydata = {
      invoiceno: props.invoiceno,
      option: props.option == 10 ? 99 : 99,
      financialyear: props.financialyear,
      gst: 'Y'
    }
    fetchbygetmethod(setdata, "invoicebill", querydata);

  }
  async function loadinvoicedetails() {
    //alert("props2",props);
    //console.log("props",props.invoiceno);
    var querydata = {
      invoiceno: props.invoiceno,
      option: props.option,
      financialyear: props.financialyear,

    }
    fetchbygetmethod(setinvoicedetaildata, "getinvoicedetails", querydata);

  }

  useEffect(() => { loaddata(); loadinvoicedetails(); }, [])
  useEffect(() => { console.log('data=', data); console.log('data1=', invoicedetaildata) }, [data]);

  return (
    <div class="content-page">
      <div class="content">


        <div ref={invoiceRef} class="page-content-wrapper ">
          <div class="container">
            <div class="row">
              <div class="col-sm-12">
                <div class="panel panel-primary">
                  <div class="panel-body">


                    <div class="row">

                      <div ref={invoiceRef} id='invoice' className="invoice-container">
                        {
                          invoicedetaildata.mypost != null &&
                          invoicedetaildata.mypost.length > 0 &&
                          <>
                            <div className="header">
                              <img src={logo} alt="Logo" />
                              <div className="header-info">
                                <h5>{invoicedetaildata.mypost[0].fromname}
                                {invoicedetaildata.mypost[0].fromlocation}
                                MOBILE NO:{invoicedetaildata.mypost[0].frommobileno} 
                                State Code:{invoicedetaildata.mypost[0].fromstatecode}</h5>
                              </div>
                            </div>
                            <div className="invoice-title">
                              <strong>*Invoice*</strong>

                            </div>
                            <div className="invoice-details">
                              <div>
                                <div className="left-details" style={{ marginLeft: 10 }}>
                                  <strong>GSTIN: {invoicedetaildata.mypost[0].fromgstin ? invoicedetaildata.mypost[0].fromgstin : 'NA'}</strong><br />
                                  <strong>PAN No: {invoicedetaildata.mypost[0].fromPanNo}</strong><br />
                                  <strong>Tax is payble on reverse Charge(Y/N): N</strong><br />
                                  <strong>Invoice No: {invoicedetaildata.mypost[0].frominvoiceno}</strong><br />
                                  <strong>Invoice Date : {invoicedetaildata.mypost[0].frominvoicedate}</strong>

                                </div>
                              </div>
                              <div  >
                                <div className="right-details" style={{ marginRight: 144 }}>


                                </div>
                              </div>
                            </div>

                            <h4>Details Of Reciever(Bill to)</h4>
                            <div className="invoice-details">
                              <div> <div className="left-details" style={{ marginLeft: 10 }}>

                                <strong>Name: {invoicedetaildata.mypost[0].toname}({invoicedetaildata.mypost[0].totempid})</strong><br />
                                <strong>Address: {invoicedetaildata.mypost[0].tolocation}</strong><br />

                                <strong>State: {invoicedetaildata.mypost[0].tostatename}</strong><br />
                                <strong>State Code: {invoicedetaildata.mypost[0].tostatecode}</strong><br />
                                <strong>PAN No: {invoicedetaildata.mypost[0].topanno}</strong><br />
                                <strong>GSTIN No: {invoicedetaildata.mypost[0].togstin}</strong><br />
                                <strong>Mobile No:{invoicedetaildata.mypost[0].tomobileno}</strong>
                              </div></div>
                              <div><div className="right-details" style={{ marginLeft: 15 }}>

                              </div></div>


                            </div>
                          </>

                        }




                        <div className="invoice-body" style={{ maxHeight: '1000px', overflowY: 'auto' }}>
                          <table className="invoice-table">
                            <thead>

                              <tr>
                                <td rowspan="2" align="center" bgcolor="#EBEBEB" class="text4">
                                  <strong>S. No</strong>
                                </td>
                                <td rowspan="2" align="center" bgcolor="#EBEBEB" class="text4">
                                  <strong>Material Description
                                  </strong>
                                </td>
                                <td rowspan="2" align="right" bgcolor="#EBEBEB" class="text4">
                                  <strong>B.NO.</strong>
                                </td>
                                <td rowspan="2" align="right" bgcolor="#EBEBEB" class="text4">
                                  <strong>HSN Code</strong>
                                </td>
                                <td colspan="3" align="center" bgcolor="#EBEBEB" class="text4" style={{ width: 250 }}>
                                  <strong>Quantity</strong>
                                </td>
                                <td rowspan="2" align="center" bgcolor="#EBEBEB" class="text4" >
                                  <strong> Total Amount</strong>
                                </td>
                                <td rowspan="2" align="center" bgcolor="#EBEBEB" class="text4" >
                                  <strong>  Business Volume</strong>
                                </td>
                                <td rowspan="2" align="center" bgcolor="#EBEBEB" class="text4" >
                                  <strong> Total Business Volume</strong>
                                </td>
                                {/* <%--<td rowspan="2" align="right" bgcolor="#EBEBEB" class="text4">
                         <asp:Label ID="Label1" runat="server" Text="Total Amount"></asp:Label>
                            </td>--%> */}
                              </tr>

                              <tr>
                                <td >
                                  <strong>Qty.</strong>
                                </td>
                                <td >
                                  <strong>U.O.M.</strong>
                                </td>
                                <td >
                                  <strong>Unit Rate.</strong>
                                </td>



                                {/* <%-- <td align="right" bgcolor="#EBEBEB" class="text4">
                            <strong>Tax Rate.</strong>
                        </td>--%> */}
                              </tr>


                            </thead>
                            <tbody>
                              {data.mypost != null && data.mypost.length > 0 &&
                                data.mypost.map((row, index) =>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{row.ItemName}</td>
                                    <td>{row.batchno}</td>
                                    <td>{row.HSNCode}</td>
                                    <td>{row.Quantity}</td>
                                    <td> piece</td>
                                    <td>{row.CostPrice}</td>
                                    <td>{row.Quantity * row.CostPrice}</td>
                                    <td>{row.BV}</td>
                                    <td>{row.Quantity * row.BV}</td>


                                  </tr>
                                )

                              }


<tr>
                                <td colspan="2"></td>
                                <td >
                                  
                                  
                                </td>
                                <td>
                                  
                                </td>
                                <td></td>
                                <td><strong>
                                 
                                </strong></td>
                                <td></td>
                                <td><strong>
                                 
                                </strong></td>
                                <td><strong>Invoice Amount :</strong></td>
                                <td><strong>
                                  {Math.round(data.mypost != null && data.mypost.length > 0 && data.mypost.reduce((sum, row) => sum + (row.Quantity * row.CostPrice), 0).toFixed(2))}
                                </strong></td>

                              </tr>
                              <tr>
                                <td colspan="2"></td>
                                <td >
                                  
                                  
                                </td>
                                <td>
                                  
                                </td>
                                <td></td>
                                <td><strong>
                                 
                                </strong></td>
                                <td></td>
                                <td><strong>
                                 
                                </strong></td>
                                <td><strong>DreamClub Discount :</strong></td>
                                <td><strong>
                               {  data.mypost && data.mypost.length > 0 ? Math.round(data.mypost[0].discountamt) : 0}

                                </strong></td>

                              </tr>
                              <tr>
                                <td colspan="2"></td>
                                <td >
                                  
                                    <strong style={{ fontSize: 10 }} >
                                      Round Off Total :
                                    </strong>
                                </td>
                                <td>
                                  <strong>
                                  (+) {(data.mypost != null && data.mypost.length > 0 && data.mypost.reduce((sum, row) => sum + (row.Quantity * row.CostPrice), 0).toFixed(2)).toString().split(".")[1]}
                                  </strong>
                                </td>
                                <td><strong>Total Weight:</strong></td>
                                <td><strong>
                                  {data.mypost != null && data.mypost.length > 0 && data.mypost.reduce((sum, row) => sum + (row.totalwt), 0).toFixed(2)}
                                </strong></td>
                                <td><strong>Total BV:</strong></td>
                                <td><strong>
                                  {data.mypost != null && data.mypost.length > 0 && data.mypost.reduce((sum, row) => sum + (row.Quantity * row.BV), 0).toFixed(2)}
                                </strong></td>
                                <td><strong>Total Bill Value:</strong></td>
                                <td><strong>
                                {
  data.mypost && data.mypost.length > 0 
    ? Math.round(
        data.mypost.reduce((sum, row) => sum + (row.Quantity * row.CostPrice), 0) - data.mypost[0].discountamt
      )
    : 0
}

                                </strong></td>

                              </tr>
                            </tbody>

                          </table>
                          <div className="invoice-body">
                            <table className="invoice-table-sec" >
                              {/* <thead>
       
            <tr>

<td >
    <center>
    <strong style={{fontSize:20}} >
      
    Twenty Eight Thousand Three
Hundred and Ninety Only
</strong></center></td>

        <td  >
        <center>
    <td>HSN CODE</td> 
    <td>TAX IGST RATE(%)</td> 
    <td>TAXABLE AMOUNT</td>  
    <td>TAX AMT</td>  
    </center>
    </td>
    
    <td >
    <tr>
        <td > 
        Frieght Charges</td>  
        <td>0</td> 
    </tr>
    <tr>
        <td>Loading and Packing Charges</td>  
        <td>0</td> 
    </tr>
    <tr>
        <td>Insurance Charges</td>  
        <td>0</td> 
    </tr>
    <tr>
        <td>Other Charges</td>  
        <td>0</td> 
    </tr>
    <tr>
        <td>nvoice Total</td>  
        <td>0</td> 
    </tr>
   
 
    </td>
    
  </tr>

  <tr>

<td >
    <center>
    <strong style={{fontSize:10}} >
       Total Tax Amt :Three Thousand Two Hundred and Eleven 
</strong></center></td>

        <td  >
        <center>
    <strong style={{fontSize:10}} >
    Amount of Tax Subject to Reverse Charge: 
</strong></center>
    </td>
    <td>
    <center>
    <strong style={{fontSize:10}} >
    Total Amount After Tax:
</strong></center>
   
 
    </td>
    
  </tr>
  <tr>

<td >
    <center>
    <strong style={{fontSize:10}} >
    Total Weight :
</strong></center></td>

        <td  >
        <center>
    <strong style={{fontSize:10}} >
    Round Off Total :
</strong></center>
    </td>
    <td>
    <center>
    <strong style={{fontSize:10}} >
    Total Bill Values :

</strong></center>
   
 
    </td>
    
  </tr>

          
        </thead>*/}
                              <tfoot>
                                <tr>
                                  <td colSpan='12'>
                                    <div className="declaration">
                                      <strong>Declaration:</strong> Certified that the particulars given are true and correct and the amount indicated represents the price actually charged and there is no flow of additional consideration directly or indirectly from the buyer E & O E. <strong>Subject To Lucknow Jurisdiction only</strong>
                                    </div>
                                  </td>
                                </tr>
                              </tfoot>

                            </table>
                          </div>
                          {/* <div className="invoice-total">
          <div className="total-value">
            <span>Total Taxable Amount:</span>
            <span>25178.89</span>
          </div>
          <div className="total-value">
            <span>SGST:</span>
            <span>0</span>
          </div>
          <div className="total-value">
            <span>CGST:</span>
            <span>0</span>
          </div>
          <div className="total-value">
            <span>IGST:</span>
            <span>3211.07</span>
          </div>
          <div className="total-value">
            <span>TOTAL:</span>
            <span>28389.96</span>
          </div>
        </div>  */}
                          <div className="invoice-footer">

                            <div className="received-goods">Received the above goods in good condition & Order

                              <div className="received-goods" style={{ marginTop: 20 }}>
                                Receiver`s Signature and Stamp
                              </div>
                            </div>


                            <div className="signature">
                              <center>
                                <div className="received-goods" >For JMVD Multitrade Pvt. Ltd.</div>
                                <div className="received-goods" style={{ marginTop: 20 }}>Authorised Signatory</div>
                              </center>
                            </div>
                          </div>

                        </div>

                      </div>


                    </div>



                  </div>
                </div>

              </div>


            </div>
          </div>

        </div>


      </div>

      {/* <Gotohome mydata={data} /> */}
    </div>
  );
}

export default Invoice;