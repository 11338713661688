import React, {useState,useEffect, useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
import {Gotohome} from '../Commonfiles/checkfortoken';
export default function Dsdirectawarenessincome()
{
	const [mydata, setmydata] = useReducer(myreducer, initialstate);
    var total=0,total_new=0;
	async function loaddata()
  {
	var month=document.getElementById("ddlmonth").value;
	var year=document.getElementById("ddlyear").value;
 
	
   fetchbygetmethod(setmydata, 'dsdirectincomeawareness', { awarenessid: localStorage.getItem("applicantID"), financialyear: process.env.REACT_APP_FINANCIAL_YEAR,month:month,year:year });//,old:old.value,nyu:nyu.value});//, month: month, year: year })
  }


function display(){
   var data=document.getElementById("tabledata")
	data.style.display='block'

  }


    return(<div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Direct Awareness Income </h4>
						
					</div>
				</div>
				
				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Direct Awareness Income</h4>
										<hr width="100%"/>
										<div class="row">
											<div class="col-sm-6 col-xs-12">
											
												<div class="m-t-20">
													<form class="" action="#">
														<div class="form-group">
															<label>Month</label>
															<select id="ddlmonth" class="form-control">
															    <option value="01">January</option>
                                                                <option value="02">February</option>
                                                                <option value="03">March</option>
                                                                <option value="04">April</option>
                                                                <option value="05">May</option>
                                                                <option value="06">June</option>
                                                                <option value="07">July</option>
                                                                <option value="08">August</option>
                                                                <option value="09">September</option>
                                                                <option value="10">October</option>
                                                                <option value="11">November</option>
                                                                <option value="12">December</option>
															</select>
														</div>
														
														
													
														<div class="form-group">
															<div>
															<input  type="button" onClick={()=>{loaddata();display();}} value="Submit"
																	class="btn btn-primary waves-effect waves-light"/>
																	
																<input type="button" value="Cancel"
																	class="btn btn-default waves-effect m-l-5"/>
															</div>
														</div>
													</form>
												</div>
											</div>
											<div class="col-sm-6 col-xs-12">
												
												<div class="m-t-20">
													<form action="#">
														<div class="form-group">
															<label>Year</label>
															<select id="ddlyear" class="form-control">
															    <option>--Select--</option>
																<option value="2021">2021</option>
																<option value="2022">2022</option>
																<option value="2023">2023</option>
																<option selected='true' value="2024">2024</option>
															</select>
														</div>
													
													
														
													</form>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row" id="tabledata" style={{display:'none'}}>
						<div class="col-md-12">
							<div class="panel panel-primary">
								<div class="panel-body">
									<h4 class="m-b-30 m-t-0">Results </h4>
									<table id="datatable-buttons"
										class="table table-striped table-bordered">
										<thead>
											<tr>
												<th>Sr.No</th>
												<th>DsId</th>
												<th>Name</th>
												<th>MobileNo</th>
												<th>Direct Income Awareness </th>
												
												
											</tr>
										</thead>
										<tbody>
											
										
										
											
											{ mydata.initialstage==true?<></>: mydata.myloading == true ? <Spinner />
											: mydata.myerror == true ? <Nodatafound />
												:
												mydata.mypost.length > 0 &&
												mydata.mypost.map((row,index)=>{

										  return(<tr>
												<td>{index+1}</td>
												<td>{row.TempID}</td>
												<td>{row.ApplicantName}</td>
												<td>{row.MobileNo}</td>
												<td>{row.directawareness}</td>
												
											</tr>) 
										  })
											
											}
										</tbody>
										<tfoot>
										{mydata.mypost.length>0 &&	
										<tr>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td><strong>{mydata.mypost.forEach((row)=> total+=row.directawareness)}Total={total}</strong></td>
												
											</tr>
											}
										</tfoot>
									</table>
								</div>
							</div>
						</div>
						</div>
					</div>
				</div>
			</div>
			{/* <Gotohome mydata={mydata}/> */}
		</div>)
}
