import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Brpersonaldetails from './Branch/bpersonaldetails';
import BrcommunicationDetails from './Branch/bCommunicationDetails';
import Brbankdetail from './Branch/bbankdetails';
import Brpaneldetail from './Branch/bpaneldetails';
import Brpurchaseapprove from './Branch/bpurchaseapprove';
import Brsalevoucher from './Branch/bsalevoucher';
import Brstock from './Branch/bstock';
import Brsale from './Branch/bsale';
import Brconsolidatebusiness from './Branch/bconsolidatebusiness';
import Brkyc from './Branch/bkyc';
import Brpaymentclearance from './Branch/bpaymentclearance';
import Brpoductorderform from './Branch/bproductorderform';
import Brcompanybankdetails from './Branch/bcompanybankdetails';
import BranchAddEditSaleinvoice from './Branch/branchaddeditsalevoucher';
import Productlist from './Branch/productlist';
import Branchdashboard from './Branch/branchdashboard';

export default function Branchroutes() {

    return (
        <Routes>
            <Route path="/" element={<Brpersonaldetails branchname={localStorage.getItem("tempID")} />} />
            <Route path='Brpersonaldetails' element={<Brpersonaldetails/>}/>
            <Route path="communicationdetail" element={<BrcommunicationDetails />} />
            <Route path="bankdetail" element={<Brbankdetail />} />
            <Route path="paneldetail" element={<Brpaneldetail />} />
            <Route path='purchaseapprove' element={<Brpurchaseapprove />} />
            <Route path='salevoucher' element={<Brsalevoucher />} />
            <Route path='stock' element={<Brstock/>} />
            <Route path='sale' element={<Brsale/>} />
            <Route path="consolidatebusiness" element={<Brconsolidatebusiness />} />
            <Route path='yourkyc' element={<Brkyc />} />
            <Route path='paymentclearance' element={<Brpaymentclearance />} />
            <Route path='productorderform' element={<Brpoductorderform/>} />
            <Route path='companybankdetails' element={<Brcompanybankdetails />} />
            <Route path='branchdashboard' element={<Branchdashboard />} />
            <Route path='BranchAddEditSaleinvoice' element={<BranchAddEditSaleinvoice/>} />
            <Route path='productlist' element={<Productlist/>}/>
        </Routes>
    )
}