import React, { useState, useEffect, useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, actiontypes } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
import { Gotohome } from '../Commonfiles/checkfortoken';
export default function Dsbusinessbuilding_2024() {
	var [mydata, setmydata] = useReducer(myreducer, initialstate);


	async function loaddata(event) {
		event.preventDefault();
		var month = document.getElementById('month').value
		var year = document.getElementById('year').value

		fetchbygetmethod(setmydata, 'businessclub_2024', { tempid: localStorage.getItem('tempID'), month: month, year: year })

	}
	//useEffect(() => { loaddata(); }, []);
	return (<div class="content-page">
		<div class="content">
			<div class="">
				<div class="page-header-title">
					<h4 class="page-title">Business Building Club Detail </h4>

				</div>
			</div>

			<div class="page-content-wrapper ">
				<div class="container">
					<div class="row">
						<div class="col-sm-12">
							<div class="panel panel-primary">
								<div class="panel-body">
									<h4 class="m-t-0 m-b-30">Business Building Club Detail</h4>
									<hr width="100%" />
									<div class="row">
										<div class="col-sm-6 col-xs-12">

											<div class="m-t-20">
												<form class="" action="#">
													<div class="form-group">
														<label>Month</label>
														<select id='month' class="form-control">
															<option value="01">January</option>
															<option value="02">February</option>
															<option value="03">March</option>
															<option value="04">April</option>
															<option value="05">May</option>
															<option value="06">June</option>
															<option value="07">July</option>
															<option value="08">August</option>
															<option value="09">September</option>
															<option value="10">October</option>
															<option value="11">November</option>
															<option value="12">December</option>
														</select>
													</div>



													<div class="form-group">
														<div>
															<button onClick={(event) => loaddata(event)}
																class="btn btn-primary waves-effect waves-light">
																Submit</button>
															<button
																class="btn btn-default waves-effect m-l-5">
																Cancel</button>
														</div>
													</div>
												</form>
											</div>
										</div>
										<div class="col-sm-6 col-xs-12">

											<div class="m-t-20">
												<form action="#">
													<div class="form-group">
														<label>Year</label>
														<select id='year' class="form-control">
															<option>2020</option>
															<option>2021</option>
															<option>2022</option>
															<option>2023</option>
															<option selected='true' >2024</option>
														</select>
													</div>



												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="page-content-wrapper ">
						<div class="container">
							<div class="row">
								<div class="col-sm-12">
									<div class="panel panel-primary">
										<div class="panel-body">
											<h4 class="m-t-0 m-b-30" style={{ textAlign: "center" }}>
												Your's Total Matching Point:
												{mydata.initialstage==true?<></>: mydata.myloading == true ? <Spinner />
													: mydata.myerror == true ? <Nodatafound />
														: mydata.mypost.length > 0
															?
															<p>{mydata.mypost[0].leftcount < mydata.mypost[0].rightcount ? mydata.mypost[0].leftcount
																: mydata.mypost[0].leftcount > mydata.mypost[0].rightcount ? mydata.mypost[0].rightcount
																	: 0}</p>
															: <p>0</p>
												}
											</h4>
											<hr width="100%" />
											<div class="row">
												<div class="col-sm-6 col-xs-12">

													<div class="m-t-20">
														<form class="" action="#">
															<div class="form-group">
																<label>Total Left</label>

															

																	
																	<label
																		class="form-control"
																	>{mydata.mypost.length > 0 ? mydata.mypost[0].leftcount:0}</label>
																
															</div>




														</form>
													</div>
												</div>
												<div class="col-sm-6 col-xs-12">

													<div class="m-t-20">
														<form action="#">
															<div class="form-group">
																<label>Total Right</label>
																

																	
																	<label
																		class="form-control"
																	>{mydata.mypost.length > 0 ? mydata.mypost[0].rightcount:0}</label>
																
															</div>


														</form>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>



						</div>
					</div>

				</div>
			</div>
		</div>
		{/* <Gotohome mydata={mydata} /> */}
	</div>)
}