import React, { useState, useEffect, useReducer } from 'react';
import { initialstate, myreducer, fetchbygetmethod, fetchbypostmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
import axios from 'axios';
import { Gotohome } from '../Commonfiles/checkfortoken';
function StorecommunicationDetails() {
	const imagedefault = "/assets/images/users/avatar-1.jpg";
	const [data, setdata] = useReducer(myreducer, initialstate);
	const [myimgupload, setmyimgupload] = useReducer(myreducer, initialstate);
	//photo section

	const [fileaddress, setfileaddress] = useState(null);
	const [fileaddressimage, setfileaddressimage] = useState(null);

	const handleFileChangeforaddress = (event) => {
		const imgfile = event.target.files[0];

		if (imgfile) {


			const reader = new FileReader();
			reader.onloadend = () => {

				setfileaddressimage(reader.result);
				setfileaddress(imgfile);

			};
			reader.readAsDataURL(imgfile);

		}
	};

	const onSubmitaddress = async (e) => {

		e.preventDefault();

		if (!fileaddress) {

			console.error('No file selected.');
			return;
		}
		const imagename = localStorage.getItem('tempID') + '_address';
		// Create a new file with the new name
		const newFileName = `${imagename}.${fileaddress.name.split('.').pop()}`; // e.g., dsid.jpg
		const newFile = new File([fileaddress], newFileName, { type: fileaddress.type });

		const formData = new FormData();

		formData.append('file', newFile);


		try {
			const res = await axios.post(process.env.REACT_APP_URL + 'upload', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});

			const { fileName } = res.data;

			console.log('fileName', fileName);

			fetchbypostmethod(setmyimgupload, 'communicationuploads', { option: 1, fileName: fileName, applicantid: localStorage.getItem('applicantID') });

			alert("Image Uploaded!!");
		} catch (err) {
			console.error('ret', err);
		}
	};

	const [fileIdproof, setfileIdproof] = useState(null);
	const [fileIdproofimage, setfileIdproofimage] = useState(null);
	const handleFileChangeforIdproof = (event) => {
		const imgfile = event.target.files[0];

		if (imgfile) {


			const reader = new FileReader();
			reader.onloadend = () => {

				setfileIdproofimage(reader.result);
				setfileIdproof(imgfile);

			};
			reader.readAsDataURL(imgfile);

		}
	};

	const onSubmitIdproof = async (e) => {

		e.preventDefault();

		if (!fileIdproof) {

			console.error('No file selected.');
			return;
		}
		const imagename = localStorage.getItem('tempID') + '_Idproof';
		// Create a new file with the new name
		const newFileName = `${imagename}.${fileIdproof.name.split('.').pop()}`; // e.g., dsid.jpg
		const newFile = new File([fileIdproof], newFileName, { type: fileIdproof.type });

		const formData = new FormData();

		formData.append('file', newFile);

		try {
			const res = await axios.post(process.env.REACT_APP_URL + 'upload', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});

			const { fileName } = res.data;

			console.log('fileName', fileName);

			fetchbypostmethod(setmyimgupload, 'communicationuploads', { option: 2, fileName: fileName, applicantid: localStorage.getItem('applicantID') });
			alert("Image Uploaded!!");

		} catch (err) {
			alert("err" + err);
			console.error('ret', err);
		}
	};
	async function loaddata() {

		fetchbygetmethod(setdata, 'communicationdetail', { tempid: localStorage.getItem('tempID') });
	}

	useEffect(() => { loaddata() }, [])
	useEffect(() => {
		// if (isFirstRender.current) {
		// 	isFirstRender.current = false;
		//   } else {
		//console.log("mydata",myimgupload);
		if (myimgupload.myerror == true)
			alert("Please Upload Your Image Again!!");
		else if (myimgupload.initialstage == false && myimgupload.myloading == false) {
			alert('File uploaded Successfully');
			loaddata();

		}

		// }

	}, [myimgupload])

	return (
		<div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Communication Details</h4>

					</div>
				</div>

				<div class="page-content-wrapper ">
					<div class="container">

						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Communication Details</h4>
										<hr width="100%" />
										{data.myloading == true ? <Spinner />
											: data.myerror == true ? <Nodatafound />
												:
												(data != null && data.mypost.length > 0) &&
												data.mypost.map((mydata, index) => {
													return (
														<div class="row">

															<div class="col-sm-6 col-xs-12">

																<div class="m-t-20">
																	<form class="" action="#">
																		<div class="form-group">
																			<label>Country</label>
																			<label class="form-control" >
																				{mydata.CountryName}
																			</label>
																		</div>
																		<div class="form-group">
																			<label>City</label>

																			<label class="form-control">
																				{mydata.Cityname}
																			</label>


																		</div>
																		<div class="form-group">
																			<label>House No.</label>
																			<label class="form-control">
																				{mydata.HouseNo}
																			</label>
																		</div>
																		<div class="form-group">
																			<label>Mobile No.</label>
																			<label class="form-control">
																				{mydata.MobileNo}
																			</label>
																		</div>
																		<div class="form-group">
																			<label>Address Proof Image</label>
																			<div>
																				<input type="file" class="form-control" onChange={handleFileChangeforaddress} />
																			</div>
																			<div>
																				<img style={{ height: 300, width: 300 }} src={fileaddress ? fileaddress : Array.isArray(data.mypost) && data.mypost.length > 0 && data.mypost[0].AddressProof ? './uploads/' + data.mypost[0].AddressProof : process.env.PUBLIC_URL + imagedefault} alt=""
																					className="img-circle" />

																			</div>

																		</div>
																		<div class="form-group">
																			<div>

																				<button type="reset" onClick={(e) => onSubmitaddress(e)}
																					class="btn btn-primary waves-effect m-l-5">
																					Submit AddressProof</button>
																			</div>
																		</div>
																		





																	</form>
																</div>
															</div>
															<div class="col-sm-6 col-xs-12">

																<div class="m-t-20">
																	<form action="#">
																		<div class="form-group">
																			<label>State</label>
																			<label class="form-control">
																				{mydata.StateName}
																			</label>
																		</div>
																		<div class="form-group">
																			<label>Area</label>
																			<label class="form-control">
																				{mydata.areaname}
																			</label>
																		</div>
																		<div class="form-group">
																			<label>Email</label>
																			<label class="form-control">
																				{mydata.Email}
																			</label>
																		</div>


																		<div class="form-group">
																			<label>Phone No. Phone no with STD code.</label>
																			<label class="form-control">
																				{mydata.PhoneNo}
																			</label>
																		</div>
																		<div class="form-group">
																			<label>Id Proof Image</label>

																			<div>
																				<input type="file" class="form-control" onChange={handleFileChangeforIdproof}
																				/>
																			</div>
																			<div>
																				<img style={{ height: 300, width: 300 }}
																					src={fileIdproofimage ? fileIdproofimage : Array.isArray(data.mypost) && data.mypost.length > 0 && data.mypost[0].IdProof ? './uploads/' + data.mypost[0].IdProof : process.env.PUBLIC_URL + imagedefault} alt=""
																					className="img-circle" />

																			</div>
																		</div>
																		<div class="form-group">
																			<div>
																				<button type="submit" onClick={(e) => onSubmitIdproof(e)}
																					class="btn btn-primary waves-effect waves-light">
																					Submit Id Proof</button>


																			</div>
																		</div>
																	</form>




																</div>

															</div>


														</div>
													)
												})
										}
									</div>

								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
			<Gotohome mydata={data} />
		</div>
		//  </div>
	)
}

export default StorecommunicationDetails;