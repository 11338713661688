import { useState, useEffect, React, useReducer } from 'react';
import { initialstate, myreducer, fetchbygetmethod, fetchbypostmethod } from '../../Commonfiles/Myreducer';

function AdminDsApprovedKycList()
{
    
      const [mydata,setmydata]=useReducer(myreducer, initialstate);
    
    
      async function loaddata()
   {
   
    fetchbygetmethod(setmydata, 'AdminDsApprovedKycList',{});

     console.log("mydata",mydata);
   }
  
   useEffect(()=>{loaddata();},[]);

    return(<div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Direct Seller Approved List</h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">Approved List</h4>
                                {/* <hr width="100%"/> */}
                                

                               



                                <div class="row">
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
                                    <div class="form-group">
															{/* <label>IP OR Login ID</label> */}
															<div>
																<div>
																 <input type="text" class="form-control" placeholder="Search......" aria-label="Search"  />
															</div>
															</div>
														</div>

                                    <div className="form-group">
                                                    <div>
                                                        
                                                          
                                                         <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:775}}>
                                                            Download</button>       
                                                        
                                                    </div>
                                                </div>                     
										{/* <h4 class="m-b-30 m-t-0">Pannel Comission</h4> */}
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
                                                    <th>Sr No</th>
												    <th>DS ID</th>
												    <th>DS Name</th>
													<th>Mobile No.</th>
                                                    <th>State</th>
                                                    
                                              
													</tr>
											</thead>
											<tbody>
											{mydata.mypost.length > 0 ? (
                                                                mydata.mypost.map((row, index) => (		
											
											
                                              <tr>
													
													
                                                    <td>{index+1}</td>
                                                    <td>{row.TempID}</td>
                                                    <td>{row.ApplicantName}</td>
                                                    <td>{row.MobileNo}</td>
                                                    <td>{row.StateName}</td>
                                                    
                                                  
                                                  
                                                   
                                                   
													
									
												</tr>
											
                                        ))
												
                                    ) : null}
											</tbody>
										
										</table>
									</div>
								</div>
							</div>
						</div>
                                
                               
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footer"> © 2016 WebAdmin - All Rights
        Reserved. </footer>
</div>)
}

export default AdminDsApprovedKycList;