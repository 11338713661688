import React, { useState, useEffect,useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, actiontypes } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';

import { Gotohome } from '../Commonfiles/checkfortoken';
export default function Dsawarenesstransferdetails() {
	var [mydata, setmydata] = useReducer(myreducer, initialstate);
	function getdata(tempid) {
        fetchbygetmethod(setmydata, 'yourgroup', { tempid: tempid })
        console.log("mydata", mydata);
    }
	useEffect(() => { getdata(localStorage.getItem('tempID')); }, []);
	return (
		<div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Awareness Transfer</h4>

					</div>
				</div>

				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Awareness Transfer</h4>
										<hr width="100%" />

										<div class="row">

											<div class="col-sm-6 col-xs-12">

												<div class="m-t-20">
													<form class="" action="#">
														<div class="form-group">
															<label>Current Awareness ID</label>
															<label
																class="form-control"
															></label>
														</div>
														<div class="form-group">
															<label>New Awareness ID</label>

															<label class="form-control"></label>


														</div>
														<div class="form-group">
															<label>Password</label>
															<label class="form-control"></label>
														</div>






													</form>
												</div>
											</div>
											<div class="col-sm-6 col-xs-12">

												<div class="m-t-20">
													<form action="#">
														<div class="form-group">
															<label>Current Awareness Name</label>
															<label class="form-control"></label>
														</div>
														<div class="form-group">
															<label>New Awareness Name</label>
															<label class="form-control"></label>
														</div>

													</form>




												</div>

											</div>



										</div>
										<div class="form-group">
											<div>
												<button type="submit"
													class="btn btn-primary waves-effect waves-light">
													Submit</button>
												<button type="reset"
													class="btn btn-default waves-effect m-l-5">
													Cancel</button>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <Gotohome mydata={mydata}/> */}
		</div>
	)
}