//import { NChar } from 'msnodesqlv8';
import React, { useState, useEffect, useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
import { Gotohome } from '../Commonfiles/checkfortoken';
function Dsyourbusiness() {
	//set dates

	const now = new Date();
	const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 2);
	const firstdate = firstDayOfMonth.toISOString().split('T')[0];

	const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
	const lastdate = lastDayOfMonth.toISOString().split('T')[0];

	//end set dates
	const [mydata, setmydata] = useReducer(myreducer, initialstate);

	async function loaddata() {
		var fromdate = document.getElementById('txtfromdate').value;
		var todate = document.getElementById('txttodate').value;

		//fromdate--01-03-2023
		// var [year,month,day]=fromdate.split('-').map(Number);
		// fromdate=new Date(year,month-1,day);
		//alert(fromdate);

		//console.log(fromdate+todate+localStorage.getItem('applicantID')+localStorage.getItem('tempID'))
		//    var x=await fetch(process.env.REACT_APP_URL+'yourbusiness?Fromdate='+fromdate+'&ToDate='+todate+'&ApplicantID='+localStorage.getItem('applicantID')+'&TempID='+localStorage.getItem('tempID'));
		//    var y=await x.json();
		//    var z=JSON.stringify(y.recordset);
		//    var a=JSON.parse(z);
		//    setmydata(a);
		//     console.log("mydata",mydata);

		fetchbygetmethod(setmydata, 'yourbusiness', { Fromdate: fromdate, ToDate: todate, ApplicantID: localStorage.getItem("applicantID"), TempID: localStorage.getItem('tempID') });
	}
	//useEffect(()=>{loaddata();},[]);

	function display() {
		var data = document.getElementById("tabledata")
		data.style.display = 'block'

	}


	return (
		<div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">YOUR BUSINESS VOLUME</h4>

					</div>
				</div>

				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">BUSINESS VOLUME</h4>
										<hr width="100%" />

										<div class="row">
											<div class="col-sm-6 col-xs-12">

												<div class="m-t-20">
													<form class="" action="#">

														<div class="form-group">
															<label>From Date</label>

															<div>
																<div>
																	<input id='txtfromdate' type="date" class="form-control" value={firstdate} required
																	/>
																</div>
															</div>


														</div>
														{/* <div class="form-group">
															<label>Enter Your downline Direct Seller ID </label>
															
															
												{
											  
                                               mydata.length>0 &&
															 <label  class="form-control" >{mydata[0].TempID} </label>
												} 
														</div> */}


													</form>
												</div>
											</div>
											<div class="col-sm-6 col-xs-12">

												<div class="m-t-20">
													<form action="#">
														{/* <div class="form-group">
															<label></label>
															<div>
																<div>
																<input type="" class="form-control" />
															</div>
															</div>
														</div> */}

														<div class="form-group">
															<label>To Date</label>
															<div>
																<div>
																	<input id='txttodate' value={lastdate} type="date" class="form-control" required
																	/>
																</div>
															</div>
														</div>




													</form>




												</div>

											</div>


										</div>


										<div class="form-group">
											<div>
												<button type="submit"
													class="btn btn-primary waves-effect waves-light" onClick={() => { loaddata();  }}>
													Submit</button>
												<button type="reset"
													class="btn btn-default waves-effect m-l-5">
													Cancel</button>
											</div>
										</div>


									</div>
								</div>
							</div>
						</div>
						{mydata.initialstage==true?<></>: mydata.myloading ? <Spinner/>
						                   :mydata.myerror ?<Nodatafound/>
										    :
											Array.isArray(mydata.mypost) && mydata.mypost.length>0 &&
											<div class="row" id="tabledata" style={{ display: 'block' }}>
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-b-30 m-t-0">DS Business Volume </h4>
										
											<div class="container">

											<div class="row" id="tabledata" style={{ display: 'block' }}>
												<div class="col-md-12">
													<div></div>
													<div class="col-md-4">
														<div class="form-group">
															<label for="box1">Welcome-</label>
															{
																
																<label class="form-control" >{mydata.mypost[0].ApplicantName}({mydata.mypost[0].TempID})</label>
															}
														</div>
													</div>

													<div class="col-md-4">
														<div class="form-group">
															<label for="box1">Total Business Volume</label>
															{
																
																<label class="form-control" >{mydata.mypost[0].TotalBusiness} </label>
															}
														</div>
													</div>

													<div class="col-md-4">
														<div class="form-group">
															<label for="box1">Self Business Volume</label>
															{
																
																<label class="form-control" >{mydata.mypost[0].Selfbusiness} </label>
															}
														</div>
													</div>
												</div>

											</div>

										</div>
										
										
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
													<th>S No.</th>
													<th>DS ID</th>
													<th>DS Name</th>
													<th>Position</th>
													<th>Self Business Volume</th>
													<th>Total Business Volume</th>

												</tr>
											</thead>
											{
												
												<tbody>



													<tr>
														<td>{1}</td>
														<td>{mydata.mypost[1].TempID}</td>
														<td>{mydata.mypost[1].ApplicantName}</td>
														<td>{mydata.mypost[1].Position}</td>
														<td>{mydata.mypost[1].Selfbusiness}</td>
														<td>{mydata.mypost[1].TotalBusiness}</td>
														<td><button class="btn btn-danger">Downline</button></td>

													</tr>
													<tr>
														<td>{2}</td>
														<td>{mydata.mypost[2].TempID}</td>
														<td>{mydata.mypost[2].ApplicantName}</td>
														<td>{mydata.mypost[2].Position}</td>
														<td>{mydata.mypost[2].Selfbusiness}</td>
														<td>{mydata.mypost[2].TotalBusiness}</td>
														<td><button class="btn btn-danger">Downline</button></td>

													</tr>





												</tbody>
											}
										</table>
									</div>
								</div>
							</div>
						</div>
						}
						


					</div>
				</div>
			</div>

			<Gotohome mydata={mydata} />
		</div>
	)
}
export default Dsyourbusiness;