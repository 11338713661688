import React, { useState, useEffect, useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod } from '../../Commonfiles/Myreducer';
import Spinner from '../../Commonfiles/spinner';
import Nodatafound from '../../Commonfiles/nodatafound';
import { Gotohome } from '../../Commonfiles/checkfortoken';

function AdminDSUncompleteProfile()
{
    const [mydata, setmydata] = useReducer(myreducer, initialstate);
	

	async function loaddata() {
		
	  
		fetchbygetmethod(setmydata, 'AdminDSUncompleteProfile', { });
		
		console.log("mydata", mydata);
	  }

    useEffect(()=>{
      
        loaddata();
       
           
    },[])
   // setvalue(1);
    return(<div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Awareness Transfer </h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">Awareness Transfer Details</h4>
                                {/* <hr width="100%"/> */}

                               


                               



                                 <div class="row">
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body" style={{overflow:'auto'}}>
                                   
										{/* <h4 class="m-b-30 m-t-0">View Royality</h4> */}
										<table id="datatable-buttons"
											class="table table-striped table-bordered overflow-auto" >
											<thead>
												<tr>
                                                    <th>Sr No</th>
												    <th>Request By</th>
												    <th>Current Awareness</th>
													<th>New Awareness</th>
                                                    <th>Submit Date</th>
                                                    <th>Transfer Date</th>
                                                    <th>Income From</th>
                                                    <th>Status</th>
                                                    <th>Decline</th>
                                                    <th>Confirm</th>
                                                   
                                            
                                              
													</tr>
											</thead>
											<tbody>
												{mydata.mypost.length>0 &&
											  mydata.mypost.map((row,index)=>{
                                              
                                              return(	
											
                                              <tr>
													
													
                                                    <td>{index+1}</td>
                                                    <td>{row.RequestBy}</td>
                                                    <td>{row.CurrentAwareness}</td>
                                                    <td>{row.NewAwareness}</td>
                                                    <td>{row.SubmitDate}</td>
                                                    <td>{row.Transferdate}</td>
                                                    <td>{row.IncomeFrom}</td>
                                                    <td>{row.Status}</td>
                                                    <td><input type='button' value='Decline' /></td>
                                                    <td><input type='button' value='Confrim' /></td>
                                                   
                                                    
                                                  
                                                  
                                                   
                                                   
													
									
												</tr>
											
                                             ) 
                                              })
												
                                                }
											</tbody>
									
										</table>
									</div>
								</div>
							</div>
						</div> 
                                
                               
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footer"> © 2016 WebAdmin - All Rights
        Reserved. </footer>
</div>)
}

export default AdminDSUncompleteProfile;