import React, { useState, useEffect, useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod } from '../../Commonfiles/Myreducer';
import Spinner from '../../Commonfiles/spinner';
import Nodatafound from '../../Commonfiles/nodatafound';
import { Gotohome } from '../../Commonfiles/checkfortoken';

function AdminDsBusinessVolume()
{  

	
	const [mydata, setmydata] = useReducer(myreducer, initialstate);
	//const [applicantIddata, setApplicantIddata] = useState('');

	const [applicantid, setapplicantid] = useReducer(myreducer, initialstate);

	async function loaddata() {
		const fromdate = document.getElementById('txtfromdate').value;
		const todate = document.getElementById('txttodate')?.value || ''; // Add optional chaining in case todate is missing
		const tempid = document.getElementById('tempid').value;
		const applicantid = document.getElementById('applicantid')?.textContent || ''; // Use textContent instead of value
	  
		alert(fromdate);
		alert(todate);
		alert(tempid);
		alert(applicantid);
	  
		fetchbygetmethod(setmydata, 'Admindsyourbusiness', { Fromdate: fromdate, ToDate: todate, ApplicantID: applicantid, TempID: tempid });
		
		console.log("mydata", mydata);
	  }

	  const [tempid, setTempid] = useState('');
	  //const [applicantId, setApplicantId] = useState('');
	
	  // Function to fetch applicant ID based on tempid
	  function getApplicantId () {
		const tempid = document.getElementById('tempid').value;
		fetchbygetmethod(setapplicantid, 'Admindsbusiinessgetapplicantid', { TempID: tempid });
	  };
	
	  // Set fetched applicant data
	//   const setApplicantIdData = () => {
	// 	// Assuming the response has ApplicantId as data[0].ApplicantId
	// 	setApplicantId(applicantId[0]?.ApplicantId || 'No Applicant ID found');
	//   };
	

	function display() {
		loaddata();
		
		const tableData = document.getElementById("tabledata");
		const tableData1 = document.getElementById("tabledata1");
		
		// Ensure the elements exist before trying to change their style
		if (tableData) {
			tableData.style.display = 'block';
		}
		if (tableData1) {
			tableData1.style.display = 'block';
		}
	}
	
	useEffect(()=>{
        //loaddata();
		//getapplicantid();
       
       
},[]);

    return(
		<div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">DIRECT SELLER BUSINESS VOLUME</h4>
						
					</div>
				</div>
				
				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">DIRECT SELLER BUSINESS VOLUME</h4>
										<hr width="100%" />
										
										<div class="row">
											<div class="col-sm-6 col-xs-12">
											
							 					<div class="m-t-20">
													<form class="" action="#">
													<div className="form-group">
      <label>Enter Your Downline Direct Seller ID</label>
      <input
        type="text"
        className="form-control"
        id="tempid"
        value={tempid}
        onChange={(e) => {
          setTempid(e.target.value);
          getApplicantId();
        }}
      />
      {/* Display ApplicantId if available */}
       <p id="applicantid" style={{display:'none'}}>{applicantid.mypost.length > 0 ? applicantid.mypost[0].ApplicantId : ''}</p>
    </div>
														<div class="form-group">
															<label>From Date</label>
															
																<div>
																	<div>
																	<input id='txtfromdate' type="date" class="form-control"  required
																	/>
															</div>
																</div>
															
															
														</div>
														
														
														
													</form>
												</div>
											</div>
											
											<div class="col-sm-6 col-xs-12"  >
												
												<div class="m-t-20">
													<form action="#">
													{/* <div class="form-group" >
															<label>hii</label>
															<div>
																<div>
																<input type="hi" class="form-control" />
															</div>
															</div>
														</div> */}

														<div class="form-group" style={{marginTop:97}}>
															<label>To Date</label>
															<div>
																<div>
																<input id='txttodate'  type="date" class="form-control" required
																	/>
															</div>
															</div>
														</div>

														
														
														
														</form>
														
														
														
														
														</div>
														
														</div>
														
													
												</div>

								
											<div class="form-group">
															<div>
																<button type="submit"
																	class="btn btn-primary waves-effect waves-light" onClick={display} >
																	Submit</button>
																<button type="reset"
																	class="btn btn-default waves-effect m-l-5">
																	Cancel</button>
															</div>
														</div>
												
										
											</div>
										</div>
									</div>
								</div>
								{mydata.initialstage==true?<></>: mydata.myloading ? <Spinner/>
						                   :mydata.myerror ?<Nodatafound/>
										    :
											Array.isArray(mydata.mypost) && mydata.mypost.length>0 &&
											<div class="row" id="tabledata" style={{ display: 'none' }}>
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-b-30 m-t-0">DS Business Volume </h4>
										
											<div class="container">

											<div class="row" id="tabledata1" style={{ display: 'none' }}>
												<div class="col-md-12">
													<div></div>
													<div class="col-md-4">
														<div class="form-group">
															<label for="box1">Welcome-</label>
															{
																
																<label class="form-control" >{mydata.mypost[0].ApplicantName}({mydata.mypost[0].TempID})</label>
															}
														</div>
													</div>

													<div class="col-md-4">
														<div class="form-group">
															<label for="box1">Total Business Volume</label>
															{
																
																<label class="form-control" >{mydata.mypost[0].TotalBusiness} </label>
															}
														</div>
													</div>

													<div class="col-md-4">
														<div class="form-group">
															<label for="box1">Self Business Volume</label>
															{
																
																<label class="form-control" >{mydata.mypost[0].Selfbusiness} </label>
															}
														</div>
													</div>
												</div>

											</div>

										</div>
										
										
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
													<th>S No.</th>
													<th>DS ID</th>
													<th>DS Name</th>
													<th>Position</th>
													<th>Self Business Volume</th>
													<th>Total Business Volume</th>

												</tr>
											</thead>
											{
												
												<tbody>



													<tr>
														<td>{1}</td>
														<td>{mydata.mypost[1].TempID}</td>
														<td>{mydata.mypost[1].ApplicantName}</td>
														<td>{mydata.mypost[1].Position}</td>
														<td>{mydata.mypost[1].Selfbusiness}</td>
														<td>{mydata.mypost[1].TotalBusiness}</td>
														<td><button class="btn btn-danger">Downline</button></td>

													</tr>
													<tr>
														<td>{2}</td>
														<td>{mydata.mypost[2].TempID}</td>
														<td>{mydata.mypost[2].ApplicantName}</td>
														<td>{mydata.mypost[2].Position}</td>
														<td>{mydata.mypost[2].Selfbusiness}</td>
														<td>{mydata.mypost[2].TotalBusiness}</td>
														<td><button class="btn btn-danger">Downline</button></td>

													</tr>





												</tbody>
											}
										</table>
									</div>
								</div>
							</div>
						</div>
						}
							
							
							</div>
						</div>
					</div>


				</div>
	)
}
export default AdminDsBusinessVolume;