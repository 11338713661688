import React,{ useState, useEffect, useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod, fetchbypostmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
import { Gotohome } from '../Commonfiles/checkfortoken';
import {useNavigate } from 'react-router-dom'
function Branchtopbar()
{
    const navtologin=useNavigate();
    function setlogout()
    {
        localStorage.clear();
        navtologin('/');
        window.location.reload();
    }

    const [value,setvalue]=useState(0);
    const [mydata,setmydata]=useReducer(myreducer, initialstate);
    
   // const imagefromdb="https://jmvdnet.jmvdbusiness.com/DirectSeller/DSImages/";
    const imagefromdb = "./uploads/";
    const imagedefault="/assets/images/users/avatar-1.jpg";
    let z;
    async function loaddata()
    {
        // var x = await fetch(process.env.REACT_APP_URL+"profileinformation?tempid="+localStorage.getItem("tempID"));
        // console.log("id: "+localStorage.getItem("tempID"));
        // console.log("URL: "+x);
        // var y = await x.json();
        // z = await y.recordset;
        // console.log('dataprofiletop',z );
        // setdata(z);
        // console.log('dataprofileinfotop',mydata );
        fetchbygetmethod(setmydata, 'profileinformation', { tempid: localStorage.getItem("tempID") });
    
        
    }
    useEffect(()=>{
      
        loaddata();
        
           
    },[])

    return(<div className="topbar">
    <div className="topbar-left">
        <div className="text-center">
            <a href="index.html" className="logo"><span>Web</span>Admin</a> <a
                href="index.html" className="logo-sm"><span>W</span></a>
        </div>
    </div>
    <div className="navbar navbar-default" role="navigation">
        <div className="container">
            <div className="">
                <div className="pull-left">
                    <button type="button"
                        className="button-menu-mobile open-left waves-effect waves-light">
                        <i className="ion-navicon"></i>
                    </button>
                    <span className="clearfix"></span>
                </div>
                <form className="navbar-form pull-left" role="search">
                    <div className="form-group">
                        <input type="text" className="form-control search-bar"
                            placeholder="Search..."/>
                                
                               
                    </div>
                    <button type="submit" className="btn btn-search">
                        <i className="fa fa-search"></i>
                    </button>
                </form>
                <ul className="nav navbar-nav navbar-right pull-right">
                    <li className="dropdown hidden-xs"><a href="#" data-target="#"
                        className="dropdown-toggle waves-effect waves-light notification-icon-box"
                        data-toggle="dropdown" aria-expanded="true"> <i
                            className="fa fa-bell"></i> <span
                            className="badge badge-xs badge-danger"></span>
                    </a>
                    <ul className="dropdown-menu dropdown-menu-lg">
                            <li className="text-center notifi-title">Notification <span
                                className="badge badge-xs badge-success">3</span></li>
                            <li className="list-group"><a href="javascript:void(0);"
                                className="list-group-item"><div className="media">
                                        <div className="media-heading">Your order is placed</div>
                                        <p className="m-0">
                                            <small>Dummy text of the printing and typesetting
                                                industry.</small>
                                        </p>
                                    </div> </a> <a href="javascript:void(0);" className="list-group-item"><div
                                        className="media">
                                        <div className="media-body clearfix">
                                            <div className="media-heading">New Message received</div>
                                            <p className="m-0">
                                                <small>You have 87 unread messages</small>
                                            </p>
                                        </div>
                                    </div> </a> <a href="javascript:void(0);" className="list-group-item"><div
                                        className="media">
                                        <div className="media-body clearfix">
                                            <div className="media-heading">Your item is shipped.</div>
                                            <p className="m-0">
                                                <small>It is a long established fact that a reader
                                                    will</small>
                                            </p>
                                        </div>
                                    </div> </a> <a href="javascript:void(0);" className="list-group-item">
                                    <small className="text-primary">See all notifications</small>
                            </a></li>
                        </ul></li>
                    <li className="hidden-xs"><a href="#" id="btn-fullscreen"
                        className="waves-effect waves-light notification-icon-box"><i
                            className="mdi mdi-fullscreen"></i></a></li>
                    <li className="dropdown"><a href="#"
                        className="dropdown-toggle profile waves-effect waves-light"
                        data-toggle="dropdown" aria-expanded="true"> <img
                            src={mydata.mypost.length>0 && mydata.mypost[0].ApplicantPhoto ? imagefromdb+mydata.mypost[0].ApplicantPhoto:process.env.PUBLIC_URL+imagedefault} alt="user-img"
                            className="img-circle"/> <span className="profile-username">
                                {mydata.mypost.length>0 && mydata.mypost[0].ApplicantName}<br /> <small>{mydata.mypost.length>0 && mydata.mypost[0].Achiver} </small><strong>{mydata.mypost.length>0 && mydata.mypost[0].TempID}</strong>
                        </span>
                    </a>
                    <ul className="dropdown-menu">
                            <li><a href="javascript:void(0)"> Profile</a></li>
                            <li><a href="javascript:void(0)"><span
                                    className="badge badge-success pull-right">5</span> Settings </a></li>
                            <li><a href="javascript:void(0)"> Lock screen</a></li>
                            <li className="divider"></li>
                            <li onClick={()=>{ setlogout(); }}><a href=""> Logout</a></li>
                        </ul></li>
                </ul>
            </div>
        </div>
    </div>
    <Gotohome mydata={mydata} />
</div>)
}

export default Branchtopbar;