import { useState, useEffect, React, useReducer } from 'react';
import { initialstate, myreducer, fetchbygetmethod, fetchbypostmethod } from '../../Commonfiles/Myreducer';

// import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

function AdminAddEditCompany()
{
    const [mydata,setmydata]=useReducer(myreducer, initialstate);
    const [getarea,setgetarea]=useReducer(myreducer, initialstate);
    const [getcity,setgetcity]=useReducer(myreducer, initialstate);
    const [getstate,setgetstate]=useReducer(myreducer, initialstate);
    const [selectedStateId, setSelectedStateId] = useReducer(myreducer, initialstate);
    const [SelectedCityid, setSelectedCityid] = useReducer(myreducer, initialstate);
  
    async function Getgetstate() {
        
        fetchbygetmethod(setgetstate, 'getstatesname',{});

        console.log("getstate",getstate);
    }

    async function Getcityname(stateid) {
        if (!stateid) {
            console.log("Invalid state ID:", stateid);
            return; // Don't make the request if the stateid is invalid
        }
        fetchbygetmethod(setgetcity, 'getcityname',{stateid:stateid});

        console.log("getcity",setgetcity);
     
      
        }

        async function Getareaname(Cityid) {
            if (!Cityid) {
                console.log("Invalid state ID:", Cityid);
                return; // Don't make the request if the stateid is invalid
            }
            fetchbygetmethod(setgetarea, 'getareaname',{Cityid:Cityid});
    
            console.log("getarea",getarea);
         
          
            }
    
           

            async function Insertdata() {

                var companyname = document.getElementById('txtcompanyname').value;
                var companytype = document.getElementById('txtcompanytype').value;
                var contactno = document.getElementById('txtcontactno').value;
                var email = document.getElementById('txtemil').value;
                var address = document.getElementById('txtaddress').value;
                var tinno = document.getElementById('txttinno').value;
                var cstno = document.getElementById('txtcstno').value;
                var ifsccode = document.getElementById('txtifsccode').value;
                var accountno = document.getElementById('txtaccountno').value;
                var bankname = document.getElementById('txtbankname').value;
                var branchname = document.getElementById('txtbranchname').value;
                var state = document.getElementById('selectstate').value;
                var city = document.getElementById('selectcity').value;
                var area = document.getElementById('selectarea').value;
                var pincode = document.getElementById('txtpincode').value;
                var status = document.getElementById('selectstatus').value;
            
                var jsonobj = { CompanyType:companytype, 
                     CompanyName:companyname,
                     ContactNo:contactno,
                     Email:email,
                     Address:address,
                     StateId:state,
                     CityId:city,
                     AreaId:area,
                     Status:status,
                     tinno:tinno,
                     cstno: cstno,
                     bankname:bankname,
                     accountno:accountno,
                     ifscno:ifsccode,
                     branchname:branchname,
                     pincode:pincode};
                
                try {
                    await fetchbypostmethod(setmydata, 'Admininsertcompany', jsonobj);
                    alert("Company Added Successfully");
                    window.location.reload(); // Reloads the page after successful registration
                } catch (error) {
                    console.error("Error during registration:", error);
                }
            }


    useEffect(()=>{
      
        Getgetstate();
       // Getcityname();
       
           
    },[])
    const handleStateChange = (e) => {
        const stateid = e.target.value;
        setSelectedStateId(stateid); // Set selected state ID
        Getcityname(stateid); // Fetch cities based on selected state
      };

      const handleareaChange = (e) => {
        const Cityid = e.target.value;
        setSelectedCityid(Cityid); // Set selected state ID
        Getareaname(Cityid); // Fetch cities based on selected state
      };
    return(<div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Company</h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">Add /Edit Company</h4>
                                {/* <hr width="100%"/> */}
                                <div className="row">
                                <div className="form-group">
                                                    <div>
                                                        
                                                         
                                                         <button onClick={() => window.history.back()} type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:5}}>
                                                           Company Data</button>       
                                                        
                                                    </div>
                                                </div> 
                                
                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>Company Name</label>
                                                    <input type="text" class='form-control' id='txtcompanyname'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Company Type</label>
                                                   <input type="text" class='form-control' id='txtcompanytype'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Contact number</label>
                                                   <input type="text" class='form-control' id='txtcontactno'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Email</label>
                                                   <input type="email" class='form-control' id='txtemil'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Address</label>
                                                   <input type="text" class='form-control' id='txtaddress'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>TIN No:</label>
                                                   <input type="text" class='form-control' id='txttinno'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>CST No:</label>
                                                   <input type="text" class='form-control' id='txtcstno'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>IFSC Code:</label>
                                                   <input type="text" class='form-control' id='txtifsccode'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Account No:</label>
                                                   <input type="text" class='form-control' id='txtaccountno'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Bank Name:</label>
                                                   <input type="text" class='form-control' id='txtbankname'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Branch Name:</label>
                                                   <input type="text" class='form-control' id='txtbranchname'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                   
                                    
                                   <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>State</label>
                                                    <select class="form-control" onChange={handleStateChange} id='selectstate'>
															    <option>Select</option>

                                                                {getstate.mypost.length > 0 ? (
                                                                getstate.mypost.map((row, index) => (
                                                                    <option key={index} value={row.StateId}>
                                                                       {row.StateName}
                                                                    </option>
                                                                 ))
												
                                                            ) : null}

																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>City</label>
                                                    <select class="form-control" onChange={handleareaChange} id='selectcity'>
                                                    <option>Select</option>

                                                    {getcity.mypost.length > 0 &&
                                  getcity.mypost.map((city, index) => (
                                    <option key={index} value={city.CityId}>
                                      {city.CityName}
                                    </option>
                                  ))}
																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Area</label>
                                                    <select class="form-control" id='selectarea'>
                                                    <option>Select</option>
                                                    {getarea.mypost.length > 0 &&
                                  getarea.mypost.map((area, index) => (
                                    <option key={index} value={area.AreaId}>
                                      {area.AreaName}
                                    </option>
                                  ))}
																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Pincode:</label>
                                                   <input type="text" class='form-control' id='txtpincode'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Is Block</label>
                                                    <select class="form-control" id='selectstatus'>
                                                    <option value='Y'>Unblock</option>
													    <option value='N'>Block</option>
                                                        
																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    
                                 <div className="form-group">
                                                    <div>
                                                        
                                                        <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:832}} onClick={Insertdata}>
                                                            Submit</button>    
                                                        
                                                    </div>
                                                </div> 
                                    
                                     
                                    
                                </div>

                               



                                
                               
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footer"> © 2016 WebAdmin - All Rights
        Reserved. </footer>
</div>)
}



export default AdminAddEditCompany;