
import { useState, useEffect, React , useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod, fetchbypostmethod } from '../../Commonfiles/Myreducer';
import Spinner from '../../Commonfiles/spinner';
import Nodatafound from '../../Commonfiles/nodatafound';
import { Gotohome } from '../../Commonfiles/checkfortoken';

function Admingenerateinvoicecode()
{
var [mydata,setmydata]=useReducer(myreducer, initialstate);
var [mycurrentdata,setmycurrentdata]=useReducer(myreducer, initialstate);
var [getinvoicecode,setgetinvoicecode]=useReducer(myreducer, initialstate);
var [gettempid,setgettempid]=useReducer(myreducer, initialstate);

async function Insertdatatempid() {

	var TEMPID = document.getElementById('txttempid');

	if (!TEMPID.value) {
        alert("Please enter a valid Temp ID.");
        return;
    }

	var jsonobj = { TEMPID: TEMPID.value };
	
	try {
		await fetchbypostmethod(setgettempid, 'Admininsertinvoicecodetempid', jsonobj);
		//alert("Area Registered Successfully");
		//window.location.reload(); // Reloads the page after successful registration
	} catch (error) {
		console.error("Error during registration:", error);
	}
}


async function Insertdata() {
	var BANKDETAIL = document.getElementById('txtbsankdetail');
	var PDETAIL = document.getElementById('txtpaymentamount');
	var PMODE=document.getElementById('selectpaymentmode');
	var PDATE = document.getElementById('getdate');
	var PAMOUNT = document.getElementById('txtpaymentamount');

	if (!BANKDETAIL.value || !PDETAIL.value || !PMODE.value || !PDATE.value || !PAMOUNT.value) {
        alert("Please fill in all the fields.");
        return;
    }

	var jsonobj = { BANKDETAIL: BANKDETAIL.value,
		            PDETAIL: PDETAIL.value,
					PMODE: PMODE.value, 
					PDATE: PDATE.value,
				    PAMOUNT:PAMOUNT.value};
	
	try {
		await fetchbypostmethod(setgetinvoicecode, 'Admininsertinvoicecode', jsonobj);
		
		//window.location.reload(); // Reloads the page after successful registration
	} catch (error) {
		console.error("Error during registration:", error);
	}
}


  async function loaddata()
{
  try {
  
	fetchbygetmethod(setmydata, 'AdminLgetinvoicecode', {});
 
} catch (error) {
  console.error("Error fetching data:", error);
}
}

async function Currentdataloaddata()
{
  try {
  
	fetchbygetmethod(setmycurrentdata, 'Admitgetcurrentinvoicecodedata', {});
 
} catch (error) {
  console.error("Error fetching data:", error);
}
}

function display(){
	loaddata();
var data=document.getElementById("tabledata");
data.style.display='block'

}
//useEffect(()=>{loaddata();},[]);


// async function handleSubmit() {
//     try {
//         // Call both insert functions and wait for them to complete
//         const insertTempIdSuccess = await Insertdatatempid();
//         console.log('Insert TempID Success:', insertTempIdSuccess);

//         const insertDataSuccess = await Insertdata();
//         console.log('Insert Data Success:', insertDataSuccess);

//         // Only call loaddata if both insert operations succeed
//         if (insertTempIdSuccess && insertDataSuccess) {
//             loaddata(); // Ensure this function exists and is properly defined
//             var data = document.getElementById("tabledata");
//             if (data) {
//                 data.style.display = 'block'; // Ensure the element exists
//             } else {
//                 console.error("Table data element not found");
//             }
//         } else {
//             console.error("Insert failed, data will not be loaded.");
//         }
//     } catch (error) {
//         console.error("Error in handleSubmit:", error);
//     }
// }

    return(
        <div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Payment Info Record</h4>
						
					</div>
				</div>
				
				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Fill Followings Details</h4>
										<hr width="100%" />
										{mydata.mypost.length > 0 &&
												mydata.mypost.map((row,index)=> {
                                             return (
										<h4 style={{color:'red'}}>{row.Invoicecode}</h4>
									);
								})}
										<div class="row">
											<div class="col-sm-6 col-xs-12">
											
												<div class="m-t-20">
													<form class="" action="#">
														<div class="form-group">
															<label>ID </label>
															<div>
																<input type="text" class="form-control" required
																	parsley-type="text" id='txttempid'  />
															</div>
																 
														</div>
														<div class="form-group">
															<label>Payment Date</label>
															
															<div>
																<input type="date" class="form-control" required
																	parsley-type="text" id='getdate'  />
															</div>
															
															
														</div>
														<div class="form-group">
															<label>Payment Mode</label>
															 <select class="form-control" id='selectpaymentmode'>
															    <option value='NR'>NIFT/RTGS</option>
                                                                <option value='CH'>CHEQUE</option>
                                                                <option value='C'>CASH</option>
                                                                <option value='CR'>CREDIT</option>
                                                                
																</select>
														</div>
														
														
														
													
														
														
													</form>
												</div>
											</div>
											<div class="col-sm-6 col-xs-12">
												
												<div class="m-t-20">
													<form action="#">
														<div class="form-group">
															<label>Bank Details</label>
															<div>
																<div>
																<input type="text" class="form-control" required
																	parsley-type="text" id='txtbsankdetail'  />
															</div>
															</div>
														</div>
														<div class="form-group">
															<label>Payment Detail</label>
															<div>
																<div>
																<input type="text" class="form-control" required
																	parsley-type="text" id='txtpaymentdetail'  />
															</div>
															</div>
														</div>
														<div class="form-group">
															<label>Payment Amount</label>
															<div>
																<div>
																<input type="text" class="form-control" required
																	parsley-type="text" id='txtpaymentamount' placeholder='0 ' />
															</div>
															</div>
														</div>
														
														</form>
														
														
														
														</div>
														
														</div>
													 <div className="form-group">
                                                    <div>
                                                        <input type="submit"
                                                            className="btn btn-primary waves-effect waves-light" 
															 style={{marginLeft:1000,marginTop:5}}
															 value="Add"
															 onClick={() => {
																Insertdatatempid() ;
																Insertdata();
																display();
																Currentdataloaddata();


															  }}/>
                                                                                                                   
                                                    </div>
                                                </div>	
													
												</div>
											{/* )
												})
										} */}
											</div>
										</div>
									</div>
								</div>
                                 <div class="row" style={{display:'none'}} id='tabledata'>
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
                                    
										{/* <h4 class="m-b-30 m-t-0">Pannel Comission</h4> */}
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
												    <th>Sr No.</th>
												    <th>Bank Details</th>
													<th>Payment Date</th>
													<th>Payment Detail</th>
													<th>Payment Mode</th>
                                                    <th>Against Credit</th>
                                                    <th>Payment Amount</th>
                                                   
													
													
													
												</tr>
											</thead>
											<tbody>
											{mycurrentdata.myloading == true ? <Spinner />
											: mycurrentdata.myerror == true ? <Nodatafound />
												:
												mycurrentdata.mypost.length > 0 &&
												mycurrentdata.mypost.map((row,index)=> {
                                             return (
											
                                              <tr>
													
												
                                                    <td>{index+1}</td>
                                                    <td>{row.BANKDETAIL}</td>
                                                    <td>{row.PDATE}</td>
                                                    <td>{row.BANKDETAIL}</td>
                                                    <td>{row.PMODE}</td>
                                                    <td>{row.AGAINSTCREDIT}</td>
                                                    <td>{row.PAMOUNT}</td>
                                                    
                                                   
													
									
												</tr>
                                                  );
                                                 })}
											</tbody>
										
										</table>
									</div>
								</div>
							</div>
						</div>
							</div>
						</div>
					</div>
				</div>
    )
}

export default Admingenerateinvoicecode;