
import { CSVLink, CSVDownload } from "react-csv";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import React, { useState, useEffect, useReducer } from 'react';
import { initialstate, myreducer, fetchbygetmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';

import { Gotohome } from '../Commonfiles/checkfortoken';


export default function Dsawarenessincomedetail() {
	var [mydata, setmydata] = useReducer(myreducer, initialstate);
	var [mybv, setmybv] = useReducer(myreducer, initialstate);


	async function loaddata() {
		var month = document.getElementById('month').value;
		//alert(+month);
		var year = document.getElementById('year').value;

		fetchbygetmethod(setmydata, 'awarenessincome', { month: month, year: year, MEMBERID: localStorage.getItem('applicantID'), Search: '' })
		//console.log("mydata", mydata);
		//alert(year+'-'+month);

	}

	async function TotalBV() {

		var month = document.getElementById('month').value;
		//alert(+month);
		var year = document.getElementById('year').value;


		fetchbygetmethod(setmybv, 'mytotalandselfbv', { month: month, year: year, MEMBERID: localStorage.getItem('applicantID'), Search: '' });
		//console.log("mybv", mybv);

	}
	useEffect(() => { //loaddata();
		               TotalBV(); }, []);
	//useEffect(()=>{ console.log("mydata", mydata) },[mydata]);

	function display() {
		var data = document.getElementById("tabledata")
		data.style.display = 'block'

	}

	function Downloadpdf() {

		const doc = new jsPDF();
		doc.autoTable({ html: '#datatable-buttons' });
		doc.save('Awareness-list.pdf');

	}


	return (
		<div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Awareness Income Details</h4>

					</div>
				</div>

				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Awareness Income Details</h4>
										<hr width="100%" />

										<div class="row">

											<div class="col-sm-6 col-xs-12">

												<div class="m-t-20">
													<form class="" action="#">
														<div class="form-group">
															<label>Self Business Volume</label>
															{

																mybv.mypost.length > 0 &&
																<label
																	class="form-control"
																>{mybv.mypost[0].SelfBV}</label>
															}
														</div>
														<div class="form-group">
															<label>Month</label>
															<select id='month' class="form-control">
																<option >Select</option>
																<option value="01">January</option>
																<option value="02">February</option>
																<option value="03">March</option>
																<option value="04">April</option>
																<option value="05">May</option>
																<option value="06">June</option>
																<option value="07">July</option>
																<option value="08">August</option>
																<option value="09">September</option>
																<option value="10">October</option>
																<option value="11">November</option>
																<option value="12">December</option>
															</select>

														</div>






													</form>
												</div>
											</div>
											<div class="col-sm-6 col-xs-12">

												<div class="m-t-20">
													<form action="#">
														<div class="form-group">
															<label>Total Business Volume</label>
															{

																mybv.mypost.length > 0 &&
																<label
																	class="form-control"
																>{mybv.mypost[0].TotalBV}</label>
															}
														</div>
														<div class="form-group">
															<label>Year</label>
															<select id='year' class="form-control">
																<option>Select</option>
																<option>2020</option>
																<option>2021</option>
																<option>2022</option>
																<option>2023</option>
																<option selected='true'>2024</option>
																<option>2025</option>

															</select>
														</div>

													</form>




												</div>

											</div>
											<div class="form-group">
												<div>
													<button type="submit" onClick={() => { loaddata();  TotalBV(); }}
														class="btn btn-primary waves-effect waves-light">
														Submit</button>
													{mydata.mypost.length < 0 &&
														<CSVLink data={mydata.mypost} filename='Awareness-Detail.cvs'>
															<button type="reset"
																class="btn btn-default waves-effect m-l-5">
																Download</button> </CSVLink>}
												</div>
											</div>


										</div>
										{mydata.initialstage==true?<></>: mydata.myloading == true ?<Spinner/>
										:mydata.myerror==true?<Nodatafound/>
										:<div id="tabledata" style={{ display: 'block' }} class="row">
											<div class="col-md-12">
												<div class="panel panel-primary">
													<div class="panel-body">

														<table id="datatable-buttons"
															class="table table-striped table-bordered">
															<thead>
																<tr>
																	<th>Sr No.</th>
																	<th>User Id</th>
																	<th>ApplicantName</th>
																	<th>State</th>
																	<th>City</th>
																	<th>Area</th>
																	<th>Mobile</th>
																	<th>Business Value</th>
																</tr>
															</thead>
															<tbody>
																{mydata.mypost.length > 0 &&
																	mydata.mypost.map((row, index) => {

																		return (
																			<tr>
																				<td>{index + 1}</td>
																				<td>{row.TempID}</td>
																				<td>{row.ApplicantName}</td>
																				<td>{row.StateName}</td>
																				<td>{row.CityName}</td>
																				<td>{row.AreaName}</td>
																				<td>{row.MobileNo}</td>
																				<td>{row.BV}</td>
																			</tr>
																		)
																	})

																}
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
                                          }

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Gotohome mydata={mydata} />
		</div>
	)
}