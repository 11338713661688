import React, { useState, useEffect } from 'react';
import './ImageSlider.css';

const ImageSlider = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

      useEffect(() => {
        const interval = setInterval(() => {
          setCurrentIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
          );
        }, 3000); // Change image every 3 seconds

        return () => clearInterval(interval);
      }, [images.length]);

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => {
            const newindex = prevIndex === 0 ? images.length - 1 : prevIndex - 1;
            console.log("prev index", newindex);
            return newindex;
        }

        );
    };

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => {
            const newindex = prevIndex === images.length - 1 ? 0 : prevIndex + 1
           console.log("next index", newindex);
            return newindex;
        }
        );
    };

    return (
        <div className="slider">
            {/* <button className="left-arrow" onClick={prevSlide}>
                &#10094;
            </button> */}
            <div className="slider-wrapper">
                {images.map((image, index) => (
                    <div
                        key={index}
                        className={`slide ${index === currentIndex ? 'active' : ''}`}
                    >
                        {index === currentIndex && (
                            <img src={image} alt={`Slide ${index}`} />
                        )}
                    </div>
                ))}
            </div>
            {/* <button className="right-arrow" onClick={nextSlide}>
                &#10095;
            </button> */}
        </div>
    );
};

export default ImageSlider;
