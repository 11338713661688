import React,{useState,useEffect, useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, actiontypes } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
import {Gotohome} from '../Commonfiles/checkfortoken';
function Dsbusinesstransaction()
{

var [mydata,setmydata]=useReducer(myreducer, initialstate);
var [filtereddata,setfiltereddata]=useReducer(myreducer, initialstate);

	async function loaddata()
  {

   fetchbygetmethod(setmydata, 'businesstransaction', { option: 1});//, month: month, year: year })
    console.log("mydata",mydata);
  }
  async function loadfilterdata()
  {
	var itemid=document.getElementById('ddlitem').value;
	var fromdate=document.getElementById('txtfromdate').value;
	var dateto=document.getElementById('txtdateto').value;
	var ApplicantID=localStorage.getItem('applicantID');//props.ApplicantID;//document.getElementById('ddlitem').value;
//   alert(itemid+'-'+fromdate+'-'+dateto+'-'+ApplicantID);
//    var x=await fetch(process.env.REACT_APP_URL+'businesstransaction?itemid='+itemid+'&fromdate='+fromdate+'&dateto='+dateto+'&ApplicantID='+ApplicantID+'&option=2');
//    var y=await x.json();
//    var z=JSON.stringify(y.recordset);
//    var a=JSON.parse(z);
//    setfiltereddata(a);

   fetchbygetmethod(setfiltereddata, 'businesstransaction', { itemid: itemid,fromdate:fromdate,dateto:dateto,ApplicantID:ApplicantID,option:2});
    console.log("filtereddata",filtereddata);
  }
  useEffect(()=>{loaddata();},[]);

function display(){
   var data=document.getElementById("tabledata")
	data.style.display='block'

  }


  return(
		<div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">DS BUSINESS TRANSACTION</h4>
						
					</div>
				</div>
				
				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Business Transaction</h4>
										<hr width="100%" />
										
										<div class="row">
											<div class="col-sm-6 col-xs-12">
											
												<div class="m-t-20">
													<form class="" action="#">
														<div class="form-group">
															<label>Items</label>
															
															<select id='ddlitem' class="form-control">
															    <option>Select</option>
																{
																mydata.initialstage==true?<></>:
											  mydata.myloading == true ? <Spinner />
											  : mydata.myerror == true ? <Nodatafound />
												  :
                                               mydata.mypost.length>0 && mydata.mypost.map(
												(item,index) =>{
												  return	<option value={item.ItemId}>{item.ItemName}</option>
												}
												
													
												
											   )
																
																}
																
															</select>
														</div>
														<div class="form-group">
															<label>From Date</label>
															
																<div>
																	<div>
																<input id='txtfromdate' type="date" class="form-control" required
																	 />
															</div>
																</div>
															
															
														</div>
														
														
														
													</form>
												</div>
											</div>
											<div class="col-sm-6 col-xs-12">
												
												<div class="m-t-20">
													<form action="#">
													<div class="form-group">
															<label style={{color:'white'}}>To Date</label>
															<div>
																<div>
																<input id='txtdateto' type="text" class="form-control" style={{borderColor:'white'}} required />
															</div>
															</div>
														</div>


														<div class="form-group">
															<label>To Date</label>
															<div>
																<div>
																<input id='txtdateto' type="date" class="form-control" required />
															</div>
															</div>
														</div>

														
														
														
														</form>
														
														
														
														
														</div>
														
														</div>
														
													
												</div>

								
											<div class="form-group">
															<div>
																<button onClick={()=>{loadfilterdata();display();}} type="submit"
																	class="btn btn-primary waves-effect waves-light">
																	Submit</button>
																<button type="reset"
																	class="btn btn-default waves-effect m-l-5">
																	Cancel</button>
															</div>
														</div>
												
										
											</div>
										</div>
									</div>
								</div>
							
							<div class="row" id="tabledata" style={{display:'none'}} >
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-b-30 m-t-0">Item Details </h4>
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
												    <th>S No.</th>
													<th>Item Name</th>
													<th>Pur. Qty.</th>
													<th>Pur. Amt.</th>
													<th>Ret. Qty</th>
													<th>Ret. Amt</th>
													<th>Net. Amt</th>
													<th>Pur. BV</th>
													<th>Ret. BV</th>
													<th>Net. BV</th>
													
												</tr>
											</thead>
											<tbody>
												
											{filtereddata.myloading == true ? <Spinner />
													: filtereddata.myerror == true ? <Nodatafound />
														:
											 filtereddata.mypost.length>0 &&
											  filtereddata.mypost.map((row,index)=>{
                                              
                                              return(
                                              <tr>
													<td>{index+1}</td>
													<td>{row.ItemName}</td>
													<td>{row.SQty}</td>
													<td>{row.SRQty}</td>
													<td>{row.SRate}</td>
													<td>{row.SRRate}</td>
													<td>{row.SBV}</td>
													<td>{row.SRBV}</td>
													<td>{row.NetRate}</td>
													<td>{row.NetBV}</td>
													<td><button  class="btn btn-primary waves-effect waves-light">View Details</button></td>
													
												</tr>
												) 
                                              })
												
                                                }
                                             
											</tbody>
											<tfoot>
											{filtereddata.myloading == true ? <Spinner />
													: filtereddata.myerror == true ? <Nodatafound />
														:
											 filtereddata.mypost.length>0 &&
											  filtereddata.mypost.map((row,index)=>{
                                              
                                              return(
                                                 <tr>
                                                    <td><strong> </strong></td>
                                                  <td><strong>Total</strong></td>
												  <td><strong></strong></td>
												    <td><strong>Pur. Amt.</strong></td>
													<td><strong>Ret. Qty</strong></td>
													<td><strong>Ret. Amt</strong></td>
													<td><strong>Net. Amt</strong></td>
													<td><strong>Pur. BV</strong></td>
													<td><strong>Ret. BV</strong></td>
													<td><strong>Net. BV</strong></td>
                                                      </tr>
													  ) 
                                              })
												
                                                }
		                                                    </tfoot>
										</table>
									</div>
								</div>
							</div>
						</div>
							
							
							</div>
						</div>
					</div>

					<Gotohome mydata={mydata}/>
				</div>
	)


  
}

export default Dsbusinesstransaction;