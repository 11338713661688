import { useState, useEffect, React, useReducer } from 'react';
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod, fetchbypostmethod } from '../../Commonfiles/Myreducer';



function Adminitemdetails()
{
  
  const [mydata,setmydata]=useReducer(myreducer, initialstate);
  const [getsgst,setgetsgst]=useReducer(myreducer, initialstate);
  const [getcgst,setgetcgst]=useReducer(myreducer, initialstate);
  const [getigst,setgetigst]=useReducer(myreducer, initialstate);
  const [getitemname,setgetitemname]=useReducer(myreducer, initialstate);
  const [Insertdata,setInsertdata]=useReducer(myreducer, initialstate);
  const [Updatedata,setUpdatedata]=useReducer(myreducer, initialstate);
  const [Panelcommision,setPanelcommision]=useReducer(myreducer, initialstate);
  const [editingRow, setEditingRow] = useState(null);
  const [inputValues, setInputValues] = useState([]);
    
    
  async function loaddata()
{
//alert('9');
fetchbygetmethod(setmydata, 'Adminitemdetails',{});

 
}

async function Getsgst()
{

fetchbygetmethod(setgetsgst, 'Adminsgst',{});

 console.log("getsgst",getsgst);
}

async function Getcgst()
{

fetchbygetmethod(setgetcgst, 'Admincgst',{});

 console.log("setgetcgst",setgetcgst);
}

async function Getigst()
{

fetchbygetmethod(setgetigst, 'Adminigst',{});

 console.log("setgetigst",setgetigst);
}

async function Getitemname()
{

fetchbygetmethod(setgetitemname, 'Admingetitem',{});

 console.log("setgetitemname",setgetitemname);
}

async function GetPanelcommision()
{
var paneltype=document.getElementById("Selectpanelname").value;

fetchbygetmethod(setPanelcommision, 'Admingetitemsdetailpanelcomm',{type:paneltype});

 console.log("Panelcommision",Panelcommision);
}

async function Insertitemdata() {
    var ItemId = document.getElementById('Selectitemname');
    var Tax1 = document.getElementById('Selectsgst');
   // var Tax1 =Tax1.options[Tax1.selectedIndex].value;
    var Tax2 = document.getElementById('Selectcgst');
   // var Tax2 = Tax2.options[Tax2.selectedIndex].value;
    var Tax3 = document.getElementById('Selectigst');
   // var Tax3 = Tax3.options[Tax3.selectedIndex].value;
    var StandardPrice = document.getElementById('txtstandardprice');
    var SellingPrice = document.getElementById('txtsellingprice');
    var PurchasingPrice = document.getElementById('txtpurchaseprice');
    var MRP = document.getElementById('txtmrp');
    var CostPrice = document.getElementById('txtcostprice');
    var BusinessVolume = document.getElementById('txtbv');
    var Weight = document.getElementById('txtweight');
    var batchno = document.getElementById('txtbatchno');
    var PanelType = document.getElementById('Selectpanelname');
    var PanelCommision = document.getElementById('txtpanelcom');
    var hsncode = document.getElementById('txthsncode');

    var jsonobj = { ItemId: ItemId.value, Tax1: Tax1.value,Tax2: Tax2.value,
                    Tax3: Tax3.value, StandardPrice: StandardPrice.value,SellingPrice: SellingPrice.value,
                    PurchasingPrice: PurchasingPrice.value, MRP: MRP.value,CostPrice: CostPrice.value,
                    BusinessVolume: BusinessVolume.value, Weight: Weight.value,batchno: batchno.value,
                    PanelType: PanelType.value, PanelCommision: PanelCommision.value,hsncode: hsncode.value
     };

     //console.log('jsonobj',jsonobj);
    
    try {
        await fetchbypostmethod(setInsertdata, 'Admininsertitemname', jsonobj);
        alert("Item Added Successfully");
        //window.location.reload(); // Reloads the page after successful registration
    } catch (error) {
        console.error("Error during registration:", error);
    }
}



  // Handle the "Edit" button click
  const handleEditClick = (index, row) => {
   
  
     
    const jsonobj = {
      com: inputValues[0].PanelCommision,
      purchase: inputValues[0].PurchasingPrice,
      selling: inputValues[0].SellingPrice,
      stand: inputValues[0].StandardPrice,
      umrp: inputValues[0].MRP,
      busi: inputValues[0].BusinessVolume,
      sgst: inputValues[0].TaxId,
      cgst: inputValues[0].TaxId+1,
      igst: inputValues[0].TaxId+2,
      itmdetailid: inputValues[0].ItemDetailId,
  };
   console.log('jsonobj',jsonobj);
    fetchbyputmethod(setUpdatedata,'Adminupdateitemdetails',jsonobj);
   
  };

  // Handle the "Update" button click
  const handleUpdateClick = () => {
    // Perform the update logic here
    setEditingRow(null); // Exit editing mode
  };

  // Handle the "Cancel" button click
  const handleCancelClick = () => {
    setInputValues([]); // Exit editing mode
  };

  // Handle input field change
  const handleInputChange = (e, field,currentrow) => 
    {
    console.log(" inputValues.length", inputValues.length);
    setInputValues((myedittable)=>{
         if(myedittable.length>0){
            return (
              myedittable.map((row)=>{
                if(row.ItemDetailId===currentrow.ItemDetailId)
                {
                  return(
                    {...row,[field]:parseFloat(e.target.value)}
                  )
                }
                else{
                  return row;
                }
              })
            )
         }
         else{
          return [{...currentrow,[field]:parseFloat(e.target.value)}]
         }
    })
                       
    
  }

    function setdataforcalculation()
    {
    
      var pp=document.getElementById('txtpurchaseprice');
      var fp=document.getElementById('txtfinalsalerate').value;
     
      document.getElementById('txtsellingprice').value=fp;
      var sp=document.getElementById('txtsellingprice').value;
      //a==10
      var comission=document.getElementById('txtpanelcom').value;
      var ddlgst=document.getElementById('Selectigst');

      if(ddlgst && ddlgst.selectedIndex>0)
{
  var gst=ddlgst.options[ddlgst.selectedIndex].text;
  calculatepurchaseprice(gst,comission,sp,pp);
}
      

     



    }

    function calculatepurchaseprice(gst,comission,sp,pp)
    {
     
  var tempgst=100+parseInt(gst);//105
  var tempcomission=100+parseInt(comission);//108
   
  //step 1
  var a=(100/tempgst)*parseInt(sp);//(100/105)*150=142.85

  //step 2 
  var b=(100/tempcomission)*a;//(100/108)*142.85=132.

  //step 3
   pp.value=(parseInt(sp)-(a-b)).toFixed(2);//150-(142-132)

   console.log('a=',a);
   console.log('b=',b);
   console.log('gst=',gst);

    }

  

useEffect(()=>{loaddata(); Getsgst(); Getcgst(); Getigst(); Getitemname();},[]); 
useEffect(()=>{console.log("inputValues->",inputValues)},[inputValues])
useEffect(()=>{if(Updatedata.initialstage==false&&Updatedata.myloading===false&&Updatedata.myerror===false)setInputValues([]);},[Updatedata])

    return(
    <div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Item Detail</h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">Item Detail</h4>
                                <hr width="100%"/>
                                <div className="row">
                                <div class="form-group">
															{/* <label>IP OR Login ID</label> */}
															<div>
																<div>
																 <input type="text" class="form-control" placeholder="Search Item......" aria-label="Search"  />
															</div>
															</div>
														</div>
                                
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                <div className="form-group">
                                                    <label>Panel Name</label>
                                                    <select class="form-control" id='Selectpanelname' onChange={GetPanelcommision}>
                                                                <option>Select</option>
                                                                <option value='Store'>Store</option>
                                                                <option value='Depot'>Depot</option>
															                                  <option value='Branch'>Branch</option>
                                                                <option value='Admin'>Admin</option>
																 

																</select>
                                                </div>
                                                
                                                
                                                
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                        
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                                    <div className="form-group">
                                                    <label>Item Name</label>
                                                    <select class="form-control" id='Selectitemname'>
															    <option>Select</option>
                                                                {getitemname.mypost.length > 0 ? (
                                                                getitemname.mypost.map((Itemname, index) => (	
															    <option value={Itemname.ItemId}>{Itemname.ItemName}</option>
                                                              
                                                            ))
												
                                                        ) : null}

																</select>
                                                </div>
                                                   
                                                    
                                                    
                                                </form>
                                        </div>
                                    </div>
                                    
                                </div>

                                <div className="row">
                                
                                    <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                <div className="form-group">
                                                        <label>Business Volume</label>
                                                        <input type="number" className="form-control" id='txtbv' defaultValue={0}/>
                                                    </div>
                                                 <div className="form-group">
                                                        <label>Cost Price</label>
                                                        <input type="number" className="form-control" id='txtcostprice' defaultValue={0}/>
                                                    </div>
                                                     <div className="form-group">
                                                        <label>SGST</label>
                            
                                                    <select class="form-control" id='Selectsgst'>
                                                        <option>Select</option>
                                                    {getsgst.mypost.length > 0 ? (
                                                                getsgst.mypost.map((SGST, index) => (	
															    <option value={SGST.TaxId}>{SGST.percentage}</option>
                                                              
                                                            ))
												
                                                        ) : null}

																</select>
                                                    </div>
                                                     <div className="form-group">
                                                        <label>Weight</label>
                                                        <input type="number" className="form-control" id='txtweight' defaultValue={0}/>
                                                    </div>
                                                     <div className="form-group">
                                                        <label>HSN Code</label>
                                                        <input type="text" className="form-control" id='txthsncode' defaultValue={0}/>
                                                    </div>
                                                
                                                
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                        
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                                     <div className="form-group">
                                                        <label>MRP</label>
                                                        <input type="number" className="form-control" id='txtmrp' defaultValue={0}/>
                                                    </div>
                                                 <div className="form-group">
                                                        <label>Selling Price</label>
                                                        <input type="number" className="form-control" id='txtsellingprice' onChange={calculatepurchaseprice} defaultValue={0}/>
                                                    </div>
                                                     <div className="form-group">
                                                        <label>CGST</label>
                                                                                <select class="form-control" id='Selectcgst'>
															    <option>Select</option>
                                                                {getcgst.mypost.length > 0 ? (
                                                                getcgst.mypost.map((CGST, index) => (	
															    <option value={CGST.TaxId}>{CGST.percentage}</option>
                                                              
                                                            ))
												
                                                        ) : null}
																 

																</select>
                                                    </div>
                                                     <div className="form-group">
                                                        <label>Panel Commission</label>
                                                        <input type="number" className="form-control" id='txtpanelcom' 
                                                         defaultValue={Panelcommision.mypost.length > 0 ? Panelcommision.mypost[0].CommisionPercentage : ''}/>
                                                    </div>
                                                     <div className="form-group">
                                                        <label>Final Sale Rate</label>
                                                        <input type="text" className="form-control" id='txtfinalsalerate' defaultValue={0} onChange={calculatepurchaseprice}/>
                                                    </div>
                                                    
                                                    
                                                </form>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                        
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                                    <div className="form-group">
                                                        <label>Standard Price</label>
                                                        <input type="number" className="form-control" id='txtstandardprice' defaultValue={0}/>
                                                    </div>
                                                 <div className="form-group">
                                                        <label>Purchase  Price</label>
                                                        <input type="number" className="form-control" id='txtpurchaseprice' defaultValue={0}/>
                                                    </div>
                                                     <div className="form-group">
                                                        <label>IGST</label>
                            
                                                    <select class="form-control" id='Selectigst'>
															    <option>Select</option>
                                                                {getigst.mypost.length > 0 ? (
                                                                getigst.mypost.map((IGST, index) => (	
															    <option value={IGST.TaxId}>{IGST.percentage}</option>
                                                              
                                                            ))
												
                                                        ) : null}
																 

																</select>
                                                    </div>
                                                     <div className="form-group">
                                                        <label>Batch No.</label>
                                                        <input type="text" className="form-control" id='txtbatchno' defaultValue={0}/>
                                                    </div>
                                                    
                                                   
                                                    
                                                    
                                                </form>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                                    <div>
                                                        <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:220,marginTop:80}} onClick={Insertitemdata}>
                                                            Submit</button>
                                                       
                                                    </div>
                                                </div>

                                </div>

                                <div class="row" >
							<div class="col-xxxl-12">
								<div class="panel panel-primary">
									<div class="panel-body table-container" style={{ overflowX: 'auto' , maxHeight: '1100PX' }}>
                                    <div class="form-group">
															{/* <label>IP OR Login ID</label> */}
															<div>
																<div style={{width:500}}>
																 <input type="text" class="form-control" placeholder="Search Item......" aria-label="Search"  />
															</div>
															</div>
														</div>
										{/* <h4 class="m-b-30 m-t-0">Pannel Comission</h4> */}
                                        <div style={{ maxHeight: '1000px', overflowY: 'auto' ,maxWidth:'1041px'}} >
										<table id="datatable-buttons" 
											class="table table-striped table-bordered" style={{ whiteSpace: 'normal' }}>
											<thead>
												<tr>
                                                <th></th>
												    <th>Sr No.</th>
												    <th>Panel Type</th>
													<th>ID</th>
													<th>Item Name</th>
													<th>Commision</th>
                                                    <th>Purchasing Price</th>
                                                    <th>Selling Price</th>
                                                    <th>Standard Price</th>
                                                    <th>MRP</th>
                                                    <th>BV</th>
                                                    <th>SGST</th>
                                                    <th>CGST</th>
                                                    <th>IGST</th>
                                                
													
													
													
												</tr>
											</thead>
                                            <tbody>
      {mydata.mypost.length > 0
        &&
         mydata.mypost.map((row, index) => (
            <tr key={row.ItemDetailId}>
              <td>
             
  <input type="button" value="Edit" onClick={() => handleEditClick(index, row)} />
  <input type="button" value="Cancel" onClick={() => handleCancelClick()} />

              </td>
              <td>{index + 1}</td>
              <td>{row.PanelType}</td>
              <td id='inputitemdetailid'>{row.ItemDetailId}</td>
              <td>{row.ItemName}</td>
              {/* Editable PanelCommision */}
              <td>
                
                  <input
                    type="text"
                         id='inputIPanelCommision'
                    style={{ width: 50 }}
                    defaultValue={ row.PanelCommision}
                    onChange={(e) => handleInputChange(e, "PanelCommision",row)}
                  />
              
              </td>
              {/* Editable PurchasingPrice */}
              <td>
                
                  <input
                    type="text"
                     id='inputIPurchasingPrice'
                    style={{ width: 50 }}
                    defaultValue={ row.PurchasingPrice}
                    onChange={(e) => handleInputChange(e, "PurchasingPrice",row)}
                  />
              
              </td>
              {/* Editable SellingPrice */}
              <td>
              
                  <input
                    type="text"
                       id='inputISellingPrice'
                    style={{ width: 50 }}
                    defaultValue={ row.SellingPrice}
                    onChange={(e) => handleInputChange(e, "SellingPrice",row)}
                  />
                
              </td>
              {/* Editable StandardPrice */}
              <td>
              
                  <input
                    type="text"
                     id='inputIStandardPrice'
                    style={{ width: 50 }}
                    defaultValue={ row.StandardPrice}
                    onChange={(e) => handleInputChange(e, "StandardPrice",row)}
                  />
               
              </td>
              {/* Continue for other fields */}
              <td>
            
                  <input
                    type="text"
                     id='inputIMRP'
                    style={{ width: 50 }}
                    defaultValue={ row.MRP}
                    onChange={(e) => handleInputChange(e, "MRP",row)}
                  />
             </td>
              <td> 
                  <input
                    type="text"
                    id='inputIBusinessVolume'
                    style={{ width: 50 }}
                    defaultValue={row.BusinessVolume}
                    onChange={(e) => handleInputChange(e, "BusinessVolume",row)}
                  />
              </td>
              <td>
                  <input
                    type="text"
                    id='inputSgst'
                    style={{ width: 50 }}
                    defaultValue={ row.Sgst}
                    onChange={(e) => handleInputChange(e, "Sgst",row)}
                  />
              </td>
              <td>
                  <input
                    type="text"
                    id='inputCgst'
                    style={{ width: 50 }}
                    defaultValue={ row.Cgst}
                    onChange={(e) => handleInputChange(e, "Cgst",row)}
                  />
              </td>
              <td>
                  <input
                    type="text"
                    id='inputIgst'
                    style={{ width: 50 }}
                    defaultValue={row.Igst}
                    onChange={(e) => handleInputChange(e, "Igst",row)}
                  />
               </td>
            </tr>
          ))
        }
    </tbody>
										
										</table>
                                        </div>
									</div>
								</div>
							</div>
						</div>


                              
                               
                                
                            </div>
                            
                        </div>
                    </div>
                   
                                
                </div>
                
            </div>
        </div>
    </div>
    <footer className="footer"> © 2024 JMVD  - All Rights
        Reserved. </footer>
        
</div>)
}

export default Adminitemdetails;