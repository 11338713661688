import React, {useState,useEffect, useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
import {Gotohome} from '../Commonfiles/checkfortoken';
export default function DsfinancialReport()
{
	
	const [mydata, setmydata] = useReducer(myreducer, initialstate);
	var [TotalBV,setTotalBV]=useState([]);
	var [Totalsellingprice,setTotalsellingprice]=useState([]);

   

 async function loaddata(event)
  {
	event.preventDefault();
	var invoicetype=document.getElementById('txtinvoicetype');
   
	fetchbygetmethod(setmydata, 'financialyear', { dsid: localStorage.getItem("tempID"), financialyear:8,invoicetype:invoicetype.value });//,old:old.value,nyu:nyu.value});//, month: month, year: year })
  }
  useEffect(()=>{if(mydata.myloading==false && mydata.myerror==false)
	{display();}},[mydata]);

  function display(){
   var data=document.getElementById("tabledata")
	data.style.display='block'

  }


  useEffect(() => {
	let totalbvdata = 0;
       mydata.mypost.forEach((row) => {
      totalbvdata += row.BusinessValue;
    
    });

    let Totalsellingamt = 0;
       mydata.mypost.forEach((row) => {
      Totalsellingamt += row.SellingPrice;
    
    });
   setTotalBV(Number(totalbvdata.toFixed(2)));
   setTotalsellingprice(Number(Totalsellingamt.toFixed(2)));
    
  }, [mydata]);

     return(
		<div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Financial Year Purchasing Report</h4>
						
					</div>
				</div>
				
				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Purchase/Purchase Return Report</h4>
										<hr width="100%" />
										
										<div class="row">
											<div class="col-sm-6 col-xs-12">
											
												<div class="m-t-20">
													<form class="" action="#">
														<div class="form-group">
															<label>Select Purchase/Purcase Return</label>
															<select class="form-control" id='txtinvoicetype' >
															    <option >--Select--</option>
																<option value='S' >Purchase</option>
																<option value='SR' >Purcase Return</option>
															
															</select>
														</div>
														
														<div class="form-group">
															<div>
																<button onClick={(event)=>{loaddata(event);}} type="submit"
																	class="btn btn-primary waves-effect waves-light">
																	Submit</button>
																
															</div>
														</div>
														
														
														
													</form>
												</div>
											</div>
											
													
												</div>

								
												
										
											</div>
										</div>
									</div>
								</div>
							{ mydata.initialstage==true?<></>: mydata.myloading  ? <Spinner/>
							 :mydata.myerror?<Nodatafound/>
							 :
							<div class="row" id='tabledata' style={{display:'none'}} >
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										{/* <h4 class="m-b-30 m-t-0">Item Details </h4> */}
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
												    <th>S No.</th>
													<th>BusinessValue</th>
													<th>SellingPrice</th>
													<th>Month</th>
													
													
												</tr>
											</thead>
											
											<tbody>
												
											
											
												{ mydata.myloading == true ? <Spinner />
											: mydata.myerror == true ? <Nodatafound />
												:
												mydata.mypost.length > 0 &&
												mydata.mypost.map((row,index)=>{
                                              
                                              return(	
												

                                              <tr>
													<td>{index+1}</td>
													<td>{row.BusinessValue}</td>
													<td>{row.SellingPrice}</td>
													<td>{row.Month}</td>
													
												</tr>
												) 
                                              })
												
                                                }
												
                                             
											</tbody>

											<tfoot>
												<tr>
													<td></td>
													<td><strong>Total = {TotalBV}</strong></td>
													<td><strong>Total = {Totalsellingprice}</strong></td>
													<td></td>
												</tr>
											</tfoot>
											
										</table>
									</div>
								</div>
							</div>
						</div>
							
											}
							</div>
						</div>
					</div>

					{/* <Gotohome mydata={mydata}/> */}
				</div>
	)
}